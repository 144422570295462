// Your web app's Firebase configuration
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';

var firebaseConfig = {
    apiKey: "AIzaSyCsOAfG-hkCT-pyWbQkdDqCHZGy5L0v67U",
    authDomain: "rafiki-d956b.firebaseapp.com",
    databaseURL: "https://rafiki-d956b.firebaseio.com",
    projectId: "rafiki-d956b",
    storageBucket: "rafiki-d956b.appspot.com",
    messagingSenderId: "201918875792",
    appId: "1:201918875792:web:e815c462ac93a9a1a6b015",
    measurementId: "G-M4QXLLT7S6"
};
export const initializeFirebase = () => {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    firebase.analytics();
};

