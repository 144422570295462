import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {callRafiki} from "../redux/actions";
import {canCallRafiki, getRoomCode, getSelf, isUpdatingGameState, shouldCallAutomaticRafiki} from "../redux/selectors";
import {PrimaryStyledButton} from "./StyledComponents";
import styled from "styled-components";

interface MyProps {
}
const CustomButton = styled(PrimaryStyledButton)`
    grid-row: 1;
    -ms-grid-row: 1;
  &:hover {
    background-color: #e06475;
  }
  &:disabled:hover {
    background-color: #d45062;
  }
`;

const CallRafikiButton: React.FC<MyProps> = () => {
    const dispatch = useDispatch();
    const self = useSelector(getSelf);
    const roomCode = useSelector(getRoomCode);

    const disabled = !useSelector(canCallRafiki);
    const autoRafiki = useSelector(shouldCallAutomaticRafiki);
    const updatingGameState = useSelector(isUpdatingGameState);

    useEffect(() => {
        if (autoRafiki) {
            dispatch(callRafiki(roomCode, self));
        }
    }, [autoRafiki, roomCode, self, dispatch]);

    const onClick = () => {
        if (updatingGameState) return;
        dispatch(callRafiki(roomCode, self));
    };

    return <CustomButton onClick={onClick} disabled={disabled} className={'block'}>
        Call RAFIKI!
    </CustomButton>;
};

export default CallRafikiButton;
