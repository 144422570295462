import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ModalID} from "../constants";
import {isSidebarOpen} from "../redux/selectors";
import {Strapline, Title} from "../components/StyledComponents";
import Modal from "./Modal";
import useEvent from "react-use/lib/useEvent";

const ConnectionStatus: React.FC = () => {
    const dispatch = useDispatch();
    const minimise = !useSelector(isSidebarOpen);
    const [online, setOnline] = useState(Boolean(navigator?.onLine));

    useEvent('online', () => {
        setOnline(true);
    }, window, {capture: true});
    useEvent('offline', () => {
        setOnline(false);
    }, window, {capture: true});

    return (!online) ? (
        <Modal minimise={minimise} modalID={ModalID.ConnectionStatus}>
            <Title>Internet disconnected</Title>
            <Strapline>You seem to be disconnected from the internet. Check your network connection.</Strapline>
        </Modal>
    ) : (
        <div />
    );
};

export default ConnectionStatus;
