import React from "react";
import { useSelector } from "react-redux";
import { ModalID } from "../constants";
import { imTimedOut, isSidebarOpen } from "../redux/selectors";
import { Strapline, Title } from "../components/StyledComponents";
import Modal from "./Modal";

const TimedOutModal: React.FC = () => {
  const minimise = !useSelector(isSidebarOpen);
  const timedOut = useSelector(imTimedOut);
  return timedOut ? (
    <Modal minimise={minimise} modalID={ModalID.PauseModal}>
      <Title>Timed Out</Title>
      <Strapline>You timed out.</Strapline>
    </Modal>
  ) : (
    <div />
  );
};

export default TimedOutModal;
