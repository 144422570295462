import {
  ADD_SELECTED_CARD,
  DISABLE_SLAM_TIMEOUT_WARNING,
  DISABLE_TIMEOUT_WARNING,
  ENABLE_SLAM_TIMEOUT_WARNING,
  ENABLE_TIMEOUT_WARNING,
  END_GAME_STATE_UPDATE,
  SET_GAME_STATE,
  SET_MODAL_IS_OPEN,
  SET_MODAL_OFFSETS,
  SET_OWNER,
  SET_PLAYERS, SET_QUICK_PLAY_NAME,
  SET_ROOM_CODE,
  SET_SCORES,
  SET_SELF,
  SET_SNACKBAR_MESSAGE,
  SET_TURN_STATE,
  START_GAME_STATE_UPDATE,
  TOGGLE_DEBUG,
  TOGGLE_LEADER_BOARD,
  TOGGLE_SIDEBAR,
  UPDATE_CIRCLE_COORDINATES,
  WIPE_SELECTED_CARDS,
} from "./actionTypes";
import {ModalID, ROUNDS, SIDEBAR_OPEN, SlamState, TurnState} from "../constants";
import {BotSpeed, State} from "./types";

const initialState: State = {
  gameState: {
    round: -1,
    rounds: ROUNDS,
    finishedRounds: [],
    deck: [],
    discardPile: [],
    drawnCard: "",
    playerRafiki: "",
    // playerSlam: "",
    slam: {
      slamState: SlamState.NoSlam,
      player: "",
    },
    hands: {},
    ready: {},
    turn: -1,
    firstTurn: 0,
    lastTurn: -1,
    loop: -1,
    lastLoop: -1,
    turnState: TurnState.Draw,
    slammedCards: {},
    failedSlamCount: {},
    playerIds: [],
    allPlayerIds: [],
    leftPlayerIds: [],
    playerStatus: {},
    isPlaying: {},
    bots: {},
    botMemory: {},
    attempts: {}, // Every 5 seconds a player can attempt to join a room again for a total of 3 attempts
    paused: false,
    roundSaved: false,
    gameSaved: false,
    initialGameSaved: false,
    leaderBoardUpdated: false,
    botSpeed: BotSpeed.Fast
  },
  isUpdatingGameState: false,
  owner: '',
  self: '',
  players: {},
  modals: {
    [ModalID.ViewTwoModal]: true,
    [ModalID.SlamModal]: true,
    [ModalID.CallRafikiModal]: true,
    [ModalID.RoundOverModal]: false,
    [ModalID.SlammingInfoModal]: false,
    [ModalID.SlammingInfoOpponentModal]: false,
    [ModalID.PauseModal]: false,
    [ModalID.ApproveModal]: true,
    [ModalID.RemovePlayersModal]: false,
    [ModalID.RoomFullModal]: false,
    [ModalID.DeniedModal]: true,
    [ModalID.PassOwnershipModal]: false,
    [ModalID.JoinGameModal]: false,
    [ModalID.ConnectionStatus]: false,
  },
  modalOffsets: {
    [ModalID.RoundOverModal]: {
      x: 0,
      y: 0
    },
  },
  snackbarMessage: "",
  selectedCards: [],
  roomCode: "",
  debug: false,
  sidebarOpen: SIDEBAR_OPEN,
  scores: [],
  leaderBoardOpen: false,
  leaderBoardHeight: 0,
  showTimeoutWarning: false,
  showSlamTimeoutWarning: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_CIRCLE_COORDINATES: {
      return {
        ...state,
        coordinates: action.payload.coordinates,
      };
    }
    case SET_SCORES: {
      const { scores } = action.payload;
      return {
        ...state,
        scores,
      };
    }
    case ENABLE_TIMEOUT_WARNING: {
      return {
        ...state,
        showTimeoutWarning: true,
      };
    }
    case DISABLE_TIMEOUT_WARNING: {
      return {
        ...state,
        showTimeoutWarning: false,
      };
    }
    case ENABLE_SLAM_TIMEOUT_WARNING: {
      return {
        ...state,
        showSlamTimeoutWarning: true,
      };
    }
    case DISABLE_SLAM_TIMEOUT_WARNING: {
      return {
        ...state,
        showSlamTimeoutWarning: false,
      };
    }
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    }
    case TOGGLE_LEADER_BOARD: {
      return {
        ...state,
        leaderBoardOpen: !state.leaderBoardOpen,
      };
    }
    case START_GAME_STATE_UPDATE: {
      const newState = { ...state };
      newState.isUpdatingGameState = true;
      return newState;
    }
    case END_GAME_STATE_UPDATE: {
      const newState = { ...state };
      newState.isUpdatingGameState = false;
      return newState;
    }
    case TOGGLE_DEBUG: {
      const newState = { ...state };
      newState.debug = !state.debug;
      return newState;
    }
    case SET_GAME_STATE: {
      const { gameState } = action.payload;
      return {
        ...state,
        gameState,
      };
    }
    case SET_QUICK_PLAY_NAME: {
      const { name } = action.payload;
      const result = {
        ...state,
      }
      result.players[state.self].name = name
      return result;
    }
    case SET_PLAYERS: {
      const { players } = action.payload;
      return {
        ...state,
        players,
      };
    }
    case SET_OWNER: {
      const { owner } = action.payload;
      return {
        ...state,
        owner,
      };
    }
    case SET_SELF: {
      const { self } = action.payload;
      return {
        ...state,
        self,
      };
    }
    case SET_ROOM_CODE: {
      const { roomCode } = action.payload;
      return {
        ...state,
        roomCode,
      };
    }
    case SET_TURN_STATE: {
      const { turnState } = action.payload;
      return {
        ...state,
        turnState,
      };
    }
    case SET_MODAL_IS_OPEN: {
      const { modalID, isOpen } = action.payload;
      return {
        ...state,
        modals: {
          ...state.modals,
          [modalID]: isOpen,
        },
      };
    }
    case SET_SNACKBAR_MESSAGE: {
      const { snackbarMessage } = action.payload;
      return {
        ...state,
        snackbarMessage,
      };
    }
    case ADD_SELECTED_CARD: {
      const { card } = action.payload;
      return {
        ...state,
        selectedCards: [...state.selectedCards, card],
      };
    }
    case WIPE_SELECTED_CARDS: {
      return {
        ...state,
        selectedCards: [],
      };
    }
    case SET_MODAL_OFFSETS: {
      const { x, y } = action.payload;
      return {
        ...state,
        modalOffsets: {
          [ModalID.RoundOverModal]: {
            x: x + state?.modalOffsets?.[ModalID.RoundOverModal]?.x,
            y: y + state?.modalOffsets?.[ModalID.RoundOverModal]?.y,
          }
        },
      };
    }
    default:
      return state;
  }
}
