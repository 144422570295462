import React, {useEffect, useState} from "react";
import {playBotMove} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {
  gameNotStarted,
  gamePaused,
  getBotSpeed,
  getRoomCode,
  getSlamState,
  getTurnState,
  isOwner,
  isSlamPending,
} from "../redux/selectors";
import useMountedState from "react-use/lib/useMountedState";
import useInterval from "react-use/lib/useInterval";
import {SlamState, TurnState} from "../constants";
import {BotSpeed} from "../redux/types";

function BotPlayer() {
  const dispatch = useDispatch();

  const roomCode = useSelector(getRoomCode);
  const turnState = useSelector(getTurnState);
  const slamState = useSelector(getSlamState);
  const paused = useSelector(gamePaused);
  const imOwner = useSelector(isOwner);
  const notStarted = useSelector(gameNotStarted);

  const slamPending = useSelector(isSlamPending);
  let mounted = useMountedState();
  const botSpeed = useSelector(getBotSpeed);
  let drawDelay: number;
  if (botSpeed === BotSpeed.Normal) {
    drawDelay = 7;
  } else {
    drawDelay = 2;
  }

  const [state, setState] = useState<{
    count: number;
    turnState?: TurnState;
    slamState?: SlamState;
    paused: boolean;
  }>({
    count: 0,
    turnState: undefined,
    slamState: undefined,
    paused: false,
  });

  useInterval(() => {
    setState({
      count: state.count + 1,
      turnState: turnState,
      slamState: slamState,
      paused: paused,
    });
  }, 1000);

  useEffect(() => {
    if (!mounted()) {
      return;
    }
    if (!imOwner) {
      return;
    }
    if (notStarted) return;
    if (turnState !== state.turnState || paused !== state.paused || slamState !== state.slamState) {
      setState({
        count: 0,
        turnState: turnState,
        slamState: slamState,
        paused: paused,
      });
    }

    if (state.paused) {
      return;
    }
    if (slamPending) {
      if (state.count > 3) {
        setState({
          count: 0,
          turnState: turnState,
          slamState: slamState,
          paused: paused,
        });
        dispatch(playBotMove(roomCode));
      }
    }
    if ([TurnState.Draw].includes(state.turnState as TurnState)) {
      if (state.count >= drawDelay) {
        setState({
          count: 0,
          turnState: turnState,
          slamState: slamState,
          paused: paused,
        });
        dispatch(playBotMove(roomCode));
      }
    } else {
      if (state.count >= 2) {
        setState({
          count: 0,
          turnState: turnState,
          slamState: slamState,
          paused: paused,
        });
        dispatch(playBotMove(roomCode));
      }
    }
  }, [state, mounted, paused, dispatch, imOwner, roomCode, slamPending, slamState, turnState]);

  return <div></div>;
}

export default BotPlayer;
