import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSnackbarMessage, isSidebarOpen, isRedSnackbar, isYellowSnackbar } from "../redux/selectors";
import { colorPalette } from "../styleguide";
import {tabletWidth, sidebarOpenOffset, sidebarWidth, snackbarHeight, mobileWidth} from "../layout";
import ButtonContainer from "./ButtonContainer";

interface SnackbarProps {
  red: boolean;
  yellow: boolean;
}

const Container = styled.div<SnackbarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${colorPalette.lightLavender};
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  height: ${snackbarHeight}px;
  background-color: #2c2f3e;
  width: 100vw;
  @media (max-width: ${mobileWidth}px) {
    bottom: 0;
    height: unset;
    padding: 10px;
    position: fixed; // IOS Safari 
    min-height: ${snackbarHeight}px;
  }
  ${({ yellow }) =>
    yellow &&
    `
    background-color: #fecf6f;
    color: #2c2f3e;
  `}
  ${({ red }) =>
    red &&
    `
    color: ${colorPalette.lightLavender};
    background-color: #a93646;
  `}
  z-index: 200;
`;

interface MinimiseProps {
  minimise: boolean;
}
const Message = styled.div<MinimiseProps>`
  width: calc(100vw - ${sidebarWidth}px);
  transition: transform 0.2s ease;
  @media (min-width: ${tabletWidth}px) {
    transform: ${({ minimise }) => minimise && `translateX(${sidebarOpenOffset}px)`};
  }
  @media (max-width: ${tabletWidth}px) {
    width: 100%;
  }
`;

const Snackbar: React.FC = () => {
  let message = useSelector(getSnackbarMessage);
  const minimise = !useSelector(isSidebarOpen);
  const red = useSelector(isRedSnackbar);
  const yellow = useSelector(isYellowSnackbar);
  return (
    <Container red={red} yellow={yellow}>
      <Message minimise={minimise}>{message}</Message>
      <ButtonContainer />
    </Container>
  );
};

export default Snackbar;
