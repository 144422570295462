import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cardSizes, ENABLE_DEBUGGING, TurnState } from "../constants";
import { cardBack, cardRedBack, deck } from "../svgExports";
import {
  gamePaused,
  getCurrentPlayer,
  getDebug,
  getSelectedCards,
  getTurnState,
  isSelected,
  shouldHideCard,
  isCardBlack,
  isCardBright,
} from "../redux/selectors";
import { State } from "../redux/types";
import { CardImg } from "./CardImg";
import useMountedState from "react-use/lib/useMountedState";
import { transparent } from "../assets/cards";
import { initializeAudio, playAudio } from "../utils";

interface MyProps {
  value: string;
  index?: number;
  playerID: string;
  onClick?: () => void;
  size?: "large" | "medium" | "small";
  inverted?: boolean;
  ieColumn?: number;
  ieRow?: number;
}

export const Card: React.FC<MyProps> = ({ value, index, playerID, onClick, size, inverted, ieColumn, ieRow }) => {
  if (typeof index === "undefined") {
    index = -1;
  }
  const card = { value, index, playerID };
  let hidden = useSelector((state: State) => {
    return shouldHideCard(state, playerID, value, index);
  });

  const selected = useSelector((state: State) => isSelected(state, value));
  const debug = useSelector(getDebug);

  if (ENABLE_DEBUGGING) {
    if (debug) {
      hidden = false;
    }
  }
  let CardSVG = hidden ? cardBack : deck[value];
  const selectedCards = useSelector(getSelectedCards);
  const isCardRed = useSelector((state: State) => !isCardBlack(state, selectedCards.length, card));
  if (hidden && isCardRed) {
    CardSVG = cardRedBack;
  }
  const isBright = useSelector((state: State) => isCardBright(state, playerID));

  const paused = useSelector(gamePaused);

  const turnState = useSelector(getTurnState);
  const [waitForSlam, setWaitForSlam] = useState(false);
  let mounted = useMountedState();

  useEffect(() => {
    initializeAudio();
  }, ["once"]);

  useEffect(() => {
    if ([TurnState.LookAtOwn, TurnState.LookAtOpponents, TurnState.SwapWithOpponent].includes(turnState)) {
      setWaitForSlam(true);
    }
    setTimeout(() => {
      mounted() && setWaitForSlam(false);
    }, 1000);
  }, [turnState, mounted]);

  const myOnClick = () => {
    if (waitForSlam) {
      return;
    }

    if (isCardRed && !selected) {
      return;
    }

    if (paused) {
      return;
    }

    if (onClick) {
      onClick();
    }
  };
  const angle = inverted && !hidden ? 180 : 0;
  if (!value) {
    return (
      <CardImg
        height={cardSizes[size || "medium"]}
        ieColumn={ieColumn}
        ieRow={ieRow}
        src={transparent}
        onClick={myOnClick}
      />
    );
  }
  return (
    <CardImg
      bright={isBright}
      src={CardSVG}
      onClick={myOnClick}
      height={cardSizes[size || "medium"]}
      selected={selected}
      angle={angle}
      ieColumn={ieColumn}
      ieRow={ieRow}
    />
  );
};
