import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ModalID } from "../constants";
import { isModalOpen, isSidebarOpen } from "../redux/selectors";
import { FormButton, FormError, FormLabel, Title } from "../components/StyledComponents";
import Modal from "./Modal";
import { useHistory } from "react-router";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import { State } from "../redux/types";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import useMountedState from "react-use/lib/useMountedState";

const LightInput = styled(OtpInput)`
  background: #393c4c;
  width: 20px;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Label = styled(FormLabel)`
  text-align: center;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 1.6;
`;

const JoinGameModal: React.FC = () => {
  const isOpen = useSelector((state: State) => isModalOpen(state, ModalID.JoinGameModal));
  const minimise = !useSelector(isSidebarOpen);
  const [code, setCode] = useState("");
  const history = useHistory();
  const [error, setError] = useState("");
  const mounted = useMountedState();
  return isOpen ? (
    <Modal minimise={minimise} modalID={ModalID.JoinGameModal}>
      <Title>Join Game</Title>
      <InputContainer>
        <Label>
          Enter the room code. <br /> The room code is the last four numbers / digits in the room owners URL.
        </Label>

        <LightInput
          value={code}
          onChange={(value: string) => setCode(value && value.toLowerCase())}
          numInputs={4}
          shouldAutoFocus={true}
          inputStyle={{
            backgroundColor: "#393c4c",
            height: "60px",
            boxShadow: "none",
            border: "none",
            display: "block",
            width: "60px",
            marginBottom: "18px",
            color: "#fecf6f",
            fontFamily: "Work Sans",
            fontSize: "24px",
            fontWeight: 600,
            letterSpacing: "0",
            lineHeight: "16px",
            padding: "15px",
            maxWidth: "100%",
            textTransform: "lowercase",
          }}
          separator={<span>&nbsp;</span>}
        />
      </InputContainer>
      <FormButton
        onClick={async () => {
          const db = firebase.firestore();
          const roomDoc = await db.collection("rooms").doc(code).get();
          if (roomDoc.exists) {
            mounted() && setError('');
            history.push(`/game/${code}`);
          } else {
            mounted() && setError(`Room doesn't exist`);
          }
        }}
      >
        Join
      </FormButton>
      {error && <FormError>{error}</FormError>}
    </Modal>
  ) : (
    <div />
  );
};

export default JoinGameModal;
