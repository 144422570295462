import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import { Bot, BotSpeed } from "./redux/types";
import {PlayerStatus} from "./common";

export const ENABLE_DEBUGGING = process.env.NODE_ENV !== "production";

const DISABLE_TIMEOUTS = false;//ENABLE_DEBUGGING;

export const MOVE_TIMEOUT = DISABLE_TIMEOUTS ? 1000 * 45 : 45; //s
export const MOVE_TIMEOUT_WARNING = DISABLE_TIMEOUTS ? 1000 * 15 : 15; //s
export const SLAM_TIMEOUT = DISABLE_TIMEOUTS ? 1000 * 20 : 20; //s
export const SLAM_TIMEOUT_WARNING = DISABLE_TIMEOUTS ? 1000 * 5 : 5; //s
export const ACTIVITY_TIMEOUT = DISABLE_TIMEOUTS ? 1000 * 5 * 60 : 5 * 60; //s
export const RAFIKI_PENALTY = 10;
export const ROUNDS = 1;
export const SIDEBAR_OPEN = false;

export const COOKIES_ENABLED = "cookies-enabled";
export const SCORE_VIEW_TIMEOUT = 5000;
export const REENABLE_BUTTON_TIMEOUT = 2000;
export const MAX_PLAYERS = 6;

export interface IDeck {
  [key: string]: string;
}

export enum MessageType {
  JoinGame,
  StartGame,
  DrawCard,
}

export interface Card {
  value: string;
  index: number;
  playerID: string;
}

export interface Player extends firebase.firestore.DocumentData {
  name: string;
  id: string;
}

export interface GameState {
  gameId?: string;
  roundId?: string;
  deck: string[];
  discardPile: string[];
  drawnCard: string;
  playerRafiki: string;
  correctSlamDone?: boolean;
  slammedCards: {
    [key: string]: boolean;
  };
  failedSlamCount: {
    [key: string]: number;
  };
  slam: SlamObject;
  round: number;
  rounds: number;
  finishedRounds: string[];
  turn: number; // between 0 to playerIds.length - 1
  loop: number; // turns % number of players
  firstTurn: number;
  lastTurn: number; // index of player id who plays last
  lastLoop: number;
  hands: {
    [key: string]: string[];
  };
  playerStatus: {
    [key: string]: PlayerStatus;
  };
  ready: {
    [key: string]: boolean;
  };
  turnState: TurnState;
  lookingAtIndex?: number;
  lookingAtPlayer?: string;
  previousTurn?: PreviousTurn;
  playerIds: string[]; // players playing the game
  allPlayerIds: string[]; // all human players and bots
  leftPlayerIds: string[]; // players who signed out
  bots: {
    [key: string]: Bot;
  };
  botMemory : {
    [key: string]: {
      [key: string]: Array<string>;
    };
  };
  attempts: {
    [key: string]: number;
  };
  paused: boolean;
  timestamp?: firebase.firestore.Timestamp;
  lastActivity?: {
    [key: string]: firebase.firestore.Timestamp;
  };
  isPlaying: IsPlaying;
  roundSaved: boolean;
  initialGameSaved: boolean;
  gameSaved: boolean;
  leaderBoardUpdated: boolean;
  botSpeed: BotSpeed;
}

export enum TurnState {
  Draw = "Draw",
  Replace = "Replace",
  LookAtOwn = "LookAtOwn",
  LookingAtOwn = "LookingAtOwn",
  LookAtOpponents = "LookAtOpponents",
  LookingAtOpponents = "LookingAtOpponents",
  SwapWithOpponent = "SwapWithOpponent",
  SwapBetweenOpponents = "SwapBetweenOpponents",
  BlindSwap = "BlindSwap",
  Drawn = "Drawn",
  CallRafiki = "CallRafiki",
  NoValidMove = "NoValidMove",
  RoundOver = "RoundOver",
  Timeout = "Timeout",
  RemoveInactive = "RemoveInactive",
  PlayerLeft = "PlayerLeft",
}

export enum MoveType {
  Ready = "Ready",
  Draw = "Draw",
  Slam = "Slam",
  PickSlamCard = "PickSlamCard",
  Discard = "Discard",
  Replace = "Replace",
  StartLookAtOwn = "StartLookAtOwn",
  FinishLookAtOwn = "FinishLookAtOwn",
  StartLookAtOpponents = "StartLookAtOpponents",
  FinishLookAtOpponents = "FinishLookAtOpponents",
  SwapWithOpponent = "SwapWithOpponent",
  FinishIncorrectSlam = "FinishIncorrectSlam",
  FinishCorrectSlamOpponent = "FinishCorrectSlamOpponent",
  CallRafiki = "CallRafiki",
  LeaveGame = "LeaveGame",
  FinishRound = "FinishRound",
}

interface PreviousDraw {
  turnState: TurnState.Draw;
  player: string;
}
interface PreviousReplace {
  turnState: TurnState.Replace;
  player: string;
  playerIndex: number;
}
interface PreviousLookAtOwn {
  turnState: TurnState.LookAtOwn;
  player: string;
  playerIndex: number;
}
interface PreviousLookAtOpponents {
  turnState: TurnState.LookAtOpponents;
  player: string;
  opponent: string;
  opponentIndex: number;
}
interface PreviousSwapWithOpponent {
  turnState: TurnState.SwapWithOpponent;
  player: string;
  playerIndex: number;
  opponent: string;
  opponentIndex: number;
}
interface PreviousCallRafiki {
  turnState: TurnState.CallRafiki;
  player: string;
}
interface PreviousNoValidMove {
  turnState: TurnState.NoValidMove;
  player: string;
}
interface PreviousRoundOver {
  turnState: TurnState.RoundOver;
}
interface PreviousTimeout {
  turnState: TurnState.Timeout;
  player: string;
}
interface PreviousRemoveInactive {
  turnState: TurnState.RemoveInactive;
  playerIds: string[];
}
export interface PreviousPlayerLeft {
  turnState: TurnState.PlayerLeft;
  player: string;
}

// {
//     turnState: TurnState;
//     selectedCards: string[];
//     player?: string;
//     opponent?: string;
//     opponentIndex?: number;
//     selfIndex?: number;
//     playerIds?: string[];
//   }
export type PreviousTurn =
  | PreviousDraw
  | PreviousReplace
  | PreviousLookAtOwn
  | PreviousLookAtOpponents
  | PreviousSwapWithOpponent
  | PreviousCallRafiki
  | PreviousNoValidMove
  | PreviousRoundOver
  | PreviousTimeout
  | PreviousRemoveInactive
  | PreviousPlayerLeft;

interface Ready {
  type: MoveType.Ready;
  self: string;
}

interface Draw {
  type: MoveType.Draw;
  self: string;
}

interface Slam {
  type: MoveType.Slam;
  self: string;
}

interface PickSlamCard {
  type: MoveType.PickSlamCard;
  self: string;
  player: string;
  playerIndex: number;
}

interface Discard {
  type: MoveType.Discard;
  self: string;
}

interface Replace {
  type: MoveType.Replace;
  self: string;
  selfIndex: number;
}

interface LookAtOwn {
  type: MoveType.StartLookAtOwn;
  self: string;
  selfIndex: number;
}

interface LookAtOpponents {
  type: MoveType.StartLookAtOpponents;
  self: string;
  opponent: string;
  opponentIndex: number;
}

interface FinishLookAtOwn {
  type: MoveType.FinishLookAtOwn;
  self: string;
  selfIndex: number;
}

interface FinishLookAtOpponents {
  type: MoveType.FinishLookAtOpponents;
  self: string;
  opponent: string;
  opponentIndex: number;
}

interface SwapWithOpponent {
  type: MoveType.SwapWithOpponent;
  self: string;
  selfIndex: number;
  opponent: string;
  opponentIndex: number;
}

interface FinishIncorrectSlam {
  type: MoveType.FinishIncorrectSlam;
  self: string;
}

interface FinishCorrectSlamOpponent {
  type: MoveType.FinishCorrectSlamOpponent;
  self: string;
  selfIndex: number;
}

interface CallRafiki {
  type: MoveType.CallRafiki;
  self: string;
}

interface LeaveGame {
  type: MoveType.LeaveGame;
  self: string;
}

interface FinishRound {
  type: MoveType.FinishRound;
  self: string;
}
export type Move =
  | Ready
  | Draw
  | Slam
  | PickSlamCard
  | Discard
  | Replace
  | LookAtOwn
  | FinishLookAtOwn
  | LookAtOpponents
  | FinishLookAtOpponents
  | SwapWithOpponent
  | FinishIncorrectSlam
  | FinishCorrectSlamOpponent
  | CallRafiki
  | LeaveGame
  | FinishRound;

export enum SlamState {
  NoSlam = "NoSlam",
  SlamStart = "SlamStart",
  SlamFailureOwn = "SlamFailureOwn",
  SlamFailureOpponent = "SlamFailureOpponent",
  SlamSuccessOpponent = "SlamSuccessOpponent",
  SlamFinishFailureOwn = "SlamFinishFailureOwn",
  SlamFinishFailureOpponent = "SlamFinishFailureOpponent",
  SlamFinishSuccessOwn = "SlamFinishSuccessOwn",
  SlamFinishSuccessOpponent = "SlamFinishSuccessOpponent",
  SlamTimeout = "SlamTimeout",
}

// previousSlam?: {
//   player: string;
// opponent?: string;
// slammedCardIndex?: number;
// discardIndex?: number;
// success?: boolean;
// },

export type SlamTimeout = {
  slamState: SlamState.SlamTimeout;
  player: string;
  slamDiscard?: string;
};

export type NoSlam = {
  slamState: SlamState.NoSlam;
  player: string;
  slamDiscard?: string;
};

export type SlamStart = {
  slamState: SlamState.SlamStart;
  player: string;
  slamDiscard?: string;
};

type SlamFailureOwn = {
  slamState: SlamState.SlamFailureOwn;
  player: string;
  slammedCardIndex: number;
  success: false;
  slamDiscard?: string;
};

type SlamFailureOpponent = {
  slamState: SlamState.SlamFailureOpponent;
  player: string;
  opponent: string;
  slammedCardIndex: number;
  success: false;
  slamDiscard?: string;
};

export type SlamSuccessOpponent = {
  slamState: SlamState.SlamSuccessOpponent;
  player: string;
  opponent: string;
  slammedCardIndex: number;
  success: true;
  slamDiscard?: string;
};

type SlamFinishFailureOwn = {
  slamState: SlamState.SlamFinishFailureOwn;
  player: string;
  slammedCardIndex: number;
  penaltyIndex: number;
  success: false;
  slamDiscard?: string;
};

type SlamFinishFailureOpponent = {
  slamState: SlamState.SlamFinishFailureOpponent;
  player: string;
  opponent: string;
  slammedCardIndex: number;
  penaltyIndex: number;
  success: false;
  slamDiscard?: string;
};

export type SlamFinishSuccessOwn = {
  slamState: SlamState.SlamFinishSuccessOwn;
  player: string;
  slammedCardIndex: number;
  success: true;
  slamDiscard?: string;
};

type SlamFinishSuccessOpponent = {
  slamState: SlamState.SlamFinishSuccessOpponent;
  player: string;
  opponent: string;
  slammedCardIndex: number;
  discardIndex: number;
  receiveIndex: number;
  success: true;
  slamDiscard?: string;
};

export type SlamObject =
  | NoSlam
  | SlamTimeout
  | SlamStart
  | SlamFailureOwn
  | SlamFailureOpponent
  | SlamSuccessOpponent
  | SlamFinishSuccessOwn
  | SlamFinishSuccessOpponent
  | SlamFinishFailureOwn
  | SlamFinishFailureOpponent;

export const StateDescriptions = {
  [TurnState.Draw]: "",
  [TurnState.Replace]: "Replace a card in your hand with this card.",
  [TurnState.LookAtOwn]: "Look at a card in your hand.",
  [TurnState.LookAtOpponents]: "Look at a card in an opponent's hand.",
  [TurnState.SwapWithOpponent]: "Swap one of your own cards with an opponent's card. Without looking at the cards!",
  [TurnState.SwapBetweenOpponents]:
    "Swap the position of two cards in an opponent’s hand (either the same opponent, or between opponents). Without looking at the cards!",
  [TurnState.BlindSwap]:
    "Swap one of your own cards with an opponent's card or swap the position of two cards between opponents. Without looking at the cards!",
  [TurnState.Drawn]: "",
  [TurnState.CallRafiki]: "",
  [TurnState.NoValidMove]: "",
  [TurnState.RoundOver]: "",
  [TurnState.Timeout]: "",
  [TurnState.RemoveInactive]: "",
  [TurnState.PlayerLeft]: "",
  [TurnState.LookingAtOwn]: "",
  [TurnState.LookingAtOpponents]: "",
};

export enum ModalID {
  ViewTwoModal = "ViewTwoModal",
  SlamModal = "SlamModal ",
  CallRafikiModal = "CallRafikiModal",
  RoundOverModal = "RoundOverModal",
  PauseModal = "PauseModal",
  ApproveModal = "ApproveModal",
  SlammingInfoModal = "SlammingInfoModal",
  SlammingInfoOpponentModal = "SlammingInfoOpponentModal",
  RoomFullModal = "RoomFullModal",
  DeniedModal = "DeniedModal",
  PassOwnershipModal = "PassOwnershipModal",
  JoinGameModal = "JoinGameModal",
  RemovePlayersModal = "RemovePlayersModal",
  ConnectionStatus = "ConnectionStatus",
}

export const suits = {
  H: "Hearts",
  D: "Diamonds",
  S: "Spades",
  C: "Clubs",
};

export const faceCards = {
  K: "King",
  Q: "Queen",
  J: "Jack",
  A: "Ace",
};

export const redSuits = ["H", "D"];
export const blackSuits = ["S", "C"];

export const NOCARD = "NOCARD";

export const cardSizes = {
  vsmall: 6,
  small: 8,
  medium: 12,
  large: 24,
};

interface MinimiseProps {
  minimise: boolean;
}

export interface Round {
  gameId: string;
  hands: {
    [key: string]: string[];
  };
  playerRafiki: string;
}

export interface Score {
  id: string;
  roundScore: number;
  score?: number;
  name?: string;
}

export interface IsPlaying {
  [key: string]: boolean;
}

export const ANONYMOUS_USER_NAME = 'Unknown'
