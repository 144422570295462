import {
  GameState,
  IsPlaying,
  Move,
  MoveType,
  NOCARD,
  PreviousTurn,
  RAFIKI_PENALTY,
  SlamObject,
  SlamState,
  TurnState,
} from "./constants";
import {isCorrectSlam, reportError, sum} from "./utils";
import { cardScore } from "./redux/selectors";
import { BotDifficulty } from "./redux/types";
import seedrandom from "seedrandom";
import { deck } from "./svgExports";
import isUndefined from "lodash/isUndefined";

export const UNKNOWN = "UNKNOWN";

const EXPECTED_VALUE =
  // prettier-ignore
  (
      4 * -1 + // Red kings or jokers
      4 * ((10 * (10 + 1)) / 2) + // A to 10
      4 * (10 * 3)  // JQK
  )
  / 54;

// This is to make sure that the bot doesnt always unknown cards from the same position
const randomizeExpectedValue = (e: number) => {
  return e + Math.random() - 0.5;
};

const randomChoice = (arr: any[]) => {
  const i = Math.max(Math.floor(Math.random() * arr.length), arr.length - 1);
  return arr[i];
};

export interface BotGameState {
  topDiscard: string;
  playerRafiki: string;
  playerSlam?: string;
  slamOpponent?: string;
  drawnCard: string;
  correctSlamDone?: boolean;
  round: number;
  rounds: number;
  turn: number; // between 0 to playerIds.length - 1
  loop: number;
  lastTurn: number; // index of player id who plays last
  lastLoop: number; // index of player id who plays last
  turnState: TurnState;
  playerIds: string[]; // approved
  paused: boolean;
  isPlaying?: IsPlaying;
  slammedCards: {
    [key: string]: boolean;
  };
  previousTurn?: PreviousTurn;
  slam: SlamObject;
  lookingAtIndex?: number;
  lookingAtPlayer?: string;
  botMemory: {
    [key: string]: {
      [key: string]: Array<string>;
    };
  };
}

export interface BotMemory {
  [key: string]: string[];
}

const getBotGameState = (gameState: GameState, player: string): BotGameState => {
  return {
    topDiscard: gameState?.discardPile?.[gameState?.discardPile?.length - 1],
    playerRafiki: gameState?.playerRafiki,
    drawnCard: gameState?.drawnCard,
    correctSlamDone: gameState?.correctSlamDone,
    slammedCards: gameState?.slammedCards,
    round: gameState?.round,
    rounds: gameState?.rounds,
    turn: gameState?.turn,
    lastTurn: gameState?.lastTurn,
    lastLoop: gameState?.lastLoop,
    turnState: gameState?.turnState,
    lookingAtIndex: gameState?.lookingAtIndex,
    lookingAtPlayer: gameState?.lookingAtPlayer,
    playerIds: gameState?.playerIds, // approved
    paused: gameState?.paused,
    isPlaying: gameState?.isPlaying,
    slam: gameState?.slam,
    previousTurn: gameState?.previousTurn,
    loop: gameState?.loop,
    botMemory: gameState?.botMemory,
  };
};

const cardValue = (card: string) => {
  return card === UNKNOWN ? randomizeExpectedValue(EXPECTED_VALUE) : cardScore(card);
};

export const getLargestCard = (hand: string[], self: string) => {
  let largestCard = "XJ";
  let index = -1;

  hand.forEach((card, i) => {
    if (card === NOCARD) return;
    if (cardValue(card) > cardValue(largestCard)) {
      largestCard = card;
      index = i;
    }
  });
  if (index >= 0) {
    return {
      index,
      playerID: self,
    };
  }
  return;
};

const smallestOpponentCard = (memory: BotMemory, id: string, playerRafiki: string) => {
  let smallestCard = "KS";
  let opponentId = "";
  let index = -1;

  Object.keys(memory)
    .filter((uid) => uid !== id)
    .forEach((uid) => {
      if (uid === playerRafiki) return;
      memory[uid].forEach((card, i) => {
        if (card === NOCARD) return;
        if (cardValue(card) <= cardValue(smallestCard)) {
          smallestCard = card;
          index = i;
          opponentId = uid;
        }
      });
    });
  if (index >= 0) {
    return {
      index,
      playerID: opponentId,
    };
  }
  return;
};

const getUnseenOpponentCard = (memory: BotMemory, self: string, playerRafiki: string) => {
  let result;
  let backup;
  Object.keys(memory)
    .filter((uid) => uid !== self)
    .forEach((uid) => {
      memory[uid].forEach((card, i) => {
        if (card !== NOCARD && uid !== playerRafiki) {
          backup = {
            index: i,
            playerID: uid,
          };
        }

        if (card === UNKNOWN && uid !== playerRafiki) {
          result = {
            index: i,
            playerID: uid,
          };
        }
      });
    });
  if (!result) {
    return (backup as unknown) as { playerID: string; index: number };
  }
  return result as { playerID: string; index: number };
};

const canSlamCard = (c: string, targetCard: string, botGameState: BotGameState) => {
  return c in deck && isCorrectSlam(c, targetCard) && cardScore(c) >= 0 && !isAlreadySlammed(botGameState, c);
};

const getSameValuedCardIndex = (hand: string[], drawnCard: string, botGameState: BotGameState) => {
  if (!drawnCard) return -1;
  return hand.findIndex((c) => canSlamCard(c, drawnCard, botGameState));
};

const getCardToSlam = (botGameState: BotGameState, hand: string[], topDiscard: string) => {
  if (!topDiscard) return;
  // Dont slam red kings from own hand as their value is -1
  const index = hand.findIndex((c) => canSlamCard(c, topDiscard, botGameState));
  if (index >= 0) {
    return {
      index: index,
      value: hand[index],
    };
  }
};

const getOpponentCardToSlam = (botGameState: BotGameState, memory: BotMemory, topDiscard: string, self: string) => {
  const cards: any = [];
  if (!topDiscard) return;
  Object.keys(memory).forEach((uid) => {
    if (uid === self) return;

    memory[uid].forEach((card, i) => {
      if (isAlreadySlammed(botGameState, card)) {
        return;
      }
      if (botGameState?.playerRafiki === uid) {
        return;
      }
      if (isCorrectSlam(card, topDiscard)) {
        cards.push({
          index: i,
          value: card,
          playerID: uid,
        });
      }
    });
  });
  if (cards.length > 0) {
    return cards[0];
  }
};

const randomOpponentCard = (botGameState: BotGameState, memory: BotMemory, self: string) => {
  const cards: any = [];
  Object.keys(memory).forEach((uid) => {
    if (uid === self) return;
    memory[uid].forEach((card, i) => {
      if (isAlreadySlammed(botGameState, card)) return;
      if (card === NOCARD) return;
      cards.push({
        index: i,
        value: card,
        playerID: uid,
      });
    });
  });
  return randomChoice(cards);
};

const randomOwnCard = (botGameState: BotGameState, memory: BotMemory, self: string) => {
  const cards: any = [];
  Object.keys(memory).forEach((uid) => {
    if (uid !== self) return;
    memory[uid].forEach((card, i) => {
      if (isAlreadySlammed(botGameState, card)) return;
      if (card === NOCARD) return;
      cards.push({
        index: i,
        value: card,
        playerID: uid,
      });
    });
  });
  return randomChoice(cards);
};

const getExpectedValueOfHand = (hand: string[]) => {
  return sum(
    hand.map((card) => {
      if (card === UNKNOWN) {
        return randomizeExpectedValue(EXPECTED_VALUE);
      }
      return cardScore(card);
    })
  );
};

const isEmpty = (hand: string[]) => {
  return hand.every(card => card === NOCARD);
};

const shouldCallRafiki = (memory: BotMemory, self: string, loop: number) => {
  const ownExpectedValue = getExpectedValueOfHand(memory[self]);
  const opponents = Object.keys(memory).filter((x) => x !== self);

  const opponentExpectedValues = opponents.map((uid) => {
    return getExpectedValueOfHand(memory[uid]);
  });

  if (loop < 3) {
    return false;
  }
  if (loop < 5) {
    return opponentExpectedValues.every((x) => x >= ownExpectedValue + RAFIKI_PENALTY);
  } else {
    return ownExpectedValue <= 5 && opponentExpectedValues.every((x) => x > ownExpectedValue);
  }
};

const isAlreadySlammed = (botGameState: BotGameState, card: string) => {
  return card in botGameState?.slammedCards;
};

export class Bot {
  private readonly self: string;
  private readonly difficulty: BotDifficulty;

  constructor(botId: string, difficulty: BotDifficulty) {
    this.self = botId;
    this.difficulty = difficulty;
  }
  getMemory = (botGameState: BotGameState) => {
    const memory = botGameState.botMemory[this.self];
    if (this.difficulty === BotDifficulty.Master) {
      return memory;
    }
    // This is to make sure that the bot random behavior is predictable and depends on the bot id
    // We dont need to store faded memories in the db and can calculate them on the fly
    const rng = seedrandom(this.self);
    const result: BotMemory = {};
    const probabilityOfRemembering = {
      [BotDifficulty.Master]: 1,
      [BotDifficulty.Voyager]: 0.5,
      [BotDifficulty.Rookie]: 0.2,
    };

    for (const key of botGameState?.playerIds) {
      result[key] = [];
      for (let i = 0; i < memory[key].length; i++) {
        const random = rng();
        if (random >= probabilityOfRemembering[this.difficulty] && memory[key][i] !== NOCARD) {
          result[key][i] = UNKNOWN;
        } else {
          result[key][i] = memory[key][i];
        }
      }
    }
    return result;
  };
  memoryWithOnlyActivePlayers = (botGameState: any) => {
    const memory = this.getMemory(botGameState);
    const result: BotMemory = {};
    Object.keys(memory).forEach((playerId) => {
      if (botGameState?.isPlaying?.[playerId]) {
        result[playerId] = memory?.[playerId];
      }
    });
    return result;
  };

  public getMove = (gameState: GameState): Move | undefined => {
    const self = this.self;

    const botGameState = getBotGameState(gameState, self);
    const memory = this.memoryWithOnlyActivePlayers(botGameState);

    botGameState.playerIds.forEach((id) => {
      gameState?.hands?.[id].forEach((card, i) => {
        if (!(id in memory)) {
          memory[id] = [];
        }
        if (!memory[id][i]) {
          memory[id][i] = UNKNOWN;
        }
        if (card === NOCARD) {
          memory[id][i] = NOCARD;
        }
      });
    });

    const drawn = botGameState?.drawnCard;
    const topDiscard = botGameState?.topDiscard;
    const myHand = memory[self];
    const cardToSlam = getCardToSlam(botGameState, myHand, topDiscard);
    const opponentCardToSlam = getOpponentCardToSlam(botGameState, memory, topDiscard, self);
    const randomOwn = randomOwnCard(botGameState, memory, self);
    const randomOpponent = randomOpponentCard(botGameState, memory, self);
    const myRafiki = botGameState.playerRafiki === self;

    const largest = getLargestCard(myHand, self);

    if (
      botGameState?.loop >= botGameState?.lastLoop &&
      botGameState?.turn >= botGameState?.lastTurn &&
      botGameState?.lastTurn >= 0
    ) {
      return;
    }

    if (botGameState?.turnState === TurnState.Drawn) {
      return {
        type: MoveType.Discard,
        self: self,
      };
    }

    // Prefer opponent slam because we get to choose what card to discard
    if (opponentCardToSlam) {
      if (botGameState?.slam?.slamState === SlamState.NoSlam && !botGameState?.drawnCard && !myRafiki) {
        return {
          type: MoveType.Slam,
          self,
        };
      }
      if (botGameState?.slam?.player === self) {
        if (botGameState?.slam?.slamState === SlamState.SlamStart) {
          return {
            type: MoveType.PickSlamCard,
            self,
            playerIndex: opponentCardToSlam.index,
            player: opponentCardToSlam.playerID,
          };
        }
      }
    }

    if (botGameState?.slam?.player === self && largest) {
      if (botGameState?.slam?.slamState === SlamState.SlamSuccessOpponent) {
        return {
          type: MoveType.FinishCorrectSlamOpponent,
          self,
          selfIndex: largest.index,
        };
      }
    }

    if (cardToSlam) {
      if (botGameState?.slam?.slamState === SlamState.NoSlam && !botGameState?.drawnCard && !myRafiki) {
        return {
          type: MoveType.Slam,
          self,
        };
      }
      if (botGameState?.slam?.player === self) {
        if (botGameState?.slam?.slamState === SlamState.SlamStart) {
          return {
            type: MoveType.PickSlamCard,
            self,
            playerIndex: cardToSlam.index,
            player: this.self,
          };
        }
      }
    }

    if (
      (botGameState?.slam?.slamState === SlamState.SlamFailureOwn ||
        botGameState?.slam?.slamState === SlamState.SlamFailureOpponent) &&
      botGameState?.slam?.player === self &&
      randomOwn
    ) {
      return {
        type: MoveType.FinishIncorrectSlam,
        self,
      };
    }

    // Backup slam random card
    if (botGameState?.slam?.slamState === SlamState.SlamStart && botGameState?.slam?.player === self && randomOwn) {
      return {
        type: MoveType.PickSlamCard,
        self,
        playerIndex: randomOwn.index,
        player: this.self,
      };
    }
    if (
      botGameState?.slam?.slamState === SlamState.SlamStart &&
      botGameState?.slam?.player === self &&
      randomOpponent
    ) {
      return {
        type: MoveType.PickSlamCard,
        self,
        playerIndex: randomOpponent.index,
        player: this.self,
      };
    }

    if (self !== botGameState?.playerIds?.[botGameState?.turn]) {
      return; // Not my turn
    }

    if (botGameState?.turnState === TurnState.Draw) {
      if (isEmpty(myHand)) {
        return {
          type: MoveType.CallRafiki,
          self: self,
        };
      }
      if (shouldCallRafiki(memory, self, botGameState.loop) && !botGameState?.playerRafiki && botGameState.loop >= 3) {
        return {
          type: MoveType.CallRafiki,
          self: self,
        };
      }

      return {
        type: MoveType.Draw,
        self: self,
      };
    }

    if (botGameState?.turnState === TurnState.Replace) {
      const index = getSameValuedCardIndex(myHand, drawn, botGameState);
      if (index >= 0) {
        // Replace then slam
        return {
          type: MoveType.Replace,
          self: self,
          selfIndex: index,
        };
      }
      if (largest) {
        // Replace
        return {
          type: MoveType.Replace,
          self: self,
          selfIndex: largest.index,
        };
      }
    }

    if (botGameState?.turnState === TurnState.LookAtOwn) {
      // Look at unseen card
      let index = myHand.indexOf(UNKNOWN);
      if (index === -1) {
        index = 0;
      }
      return {
        type: MoveType.StartLookAtOwn,
        self: self,
        selfIndex: index,
      };
    }

    if (botGameState?.turnState === TurnState.LookingAtOwn && !isUndefined(botGameState?.lookingAtIndex)) {
      return {
        type: MoveType.FinishLookAtOwn,
        self: self,
        selfIndex: botGameState?.lookingAtIndex,
      };
    }

    if (botGameState?.turnState === TurnState.LookAtOpponents) {
      // Look at unseen card
      const card = getUnseenOpponentCard(memory, this.self, botGameState.playerRafiki);
      return {
        type: MoveType.StartLookAtOpponents,
        self: self,
        opponentIndex: card.index,
        opponent: card.playerID,
      };
    }

    if (
      botGameState?.turnState === TurnState.LookingAtOpponents &&
      !isUndefined(botGameState?.lookingAtPlayer) &&
      !isUndefined(botGameState?.lookingAtIndex)
    ) {
      return {
        type: MoveType.FinishLookAtOpponents,
        self: self,
        opponentIndex: botGameState?.lookingAtIndex,
        opponent: botGameState?.lookingAtPlayer,
      };
    }

    if (botGameState?.turnState === TurnState.SwapWithOpponent && largest) {
      // Swap (largest, smallest)
      const smallest = smallestOpponentCard(memory, self, botGameState.playerRafiki);
      if (smallest) {
        return {
          type: MoveType.SwapWithOpponent,
          self: self,
          selfIndex: largest.index,
          opponent: smallest.playerID,
          opponentIndex: smallest.index,
        };
      }
    }

    return;
  };
}

let bot: Bot;

const bots: any = {};

export const getMove = (botId: string, difficulty: BotDifficulty, gameState: GameState) => {
  if (!(botId in bots)) {
    bot = new Bot(botId, difficulty);
    bots[botId] = bot;
  }
  return bots?.[botId]?.getMove(gameState);
};

const validateBotMemories = (gameState: GameState, move: Move) => {
  const botIds = Object.keys(gameState?.bots);
  botIds.forEach((botId: string) => {
    gameState?.playerIds.forEach((player: string) => {
      gameState?.botMemory?.[botId]?.[player].forEach((memcard: string, i: number) => {
        const card = gameState?.hands?.[player]?.[i];
        if (memcard === UNKNOWN && card !== NOCARD) {
          return;
        }
        if (memcard !== card) {
          const e = new Error(`Bot memory mismatch ${memcard} ${card} ${botId} ${i} ${JSON.stringify(move, null, 4)}`);
          reportError(e);
        }
      });
    });
  });
};

export const updateAllMemoriesForBots = (gameState: GameState, move: Move) => {
  const newGameState = { ...gameState };
  const botIds = Object.keys(gameState?.bots);
  if (move.type === MoveType.PickSlamCard) {
    const slammedCardIndex = move.playerIndex;
    const opponent = move.player;
    const self = move.self;
    botIds.forEach((botId: string) => {
      const card = newGameState?.botMemory?.[botId]?.[opponent]?.[slammedCardIndex];
      newGameState.botMemory[botId][opponent][slammedCardIndex] = card;
    });

    if (
      gameState?.slam?.slamState === SlamState.SlamFinishSuccessOwn ||
      gameState?.slam?.slamState === SlamState.SlamSuccessOpponent
    ) {
      botIds.forEach((botId: string) => {
        newGameState.botMemory[botId][opponent][slammedCardIndex] = NOCARD;
      });
    }
  }

  if (
    move.type === MoveType.FinishCorrectSlamOpponent &&
    newGameState?.slam?.slamState === SlamState.SlamFinishSuccessOpponent
  ) {
    const slamPlayer = move.self;
    const slammedCardIndex = move.selfIndex;
    const opponent = newGameState?.slam?.opponent;
    const discardIndex = newGameState?.slam?.discardIndex;
    const receiveIndex = newGameState?.slam?.receiveIndex;

    botIds.forEach((botId: string) => {
      const card = newGameState?.botMemory?.[botId]?.[slamPlayer]?.[discardIndex];

      newGameState.botMemory[botId][opponent][receiveIndex] = card;
      newGameState.botMemory[botId][slamPlayer][discardIndex] = NOCARD;
    });
  }

  if (move.type === MoveType.FinishIncorrectSlam) {
    const slamPlayer = move.self;
    botIds.forEach((botId: string) => {
      const penaltyIndex = newGameState?.botMemory?.[botId]?.[slamPlayer]?.indexOf(NOCARD);
      newGameState.botMemory[botId][slamPlayer][penaltyIndex] = UNKNOWN;
    });
  }

  if (move.type === MoveType.Replace) {
    const player = move?.self;
    const selfIndex = move?.selfIndex;
    const card = gameState?.hands?.[player]?.[selfIndex];
    botIds.forEach((botId: string) => {
      if (player !== botId) {
        newGameState.botMemory[botId][player][selfIndex] = UNKNOWN;
      } else {
        newGameState.botMemory[botId][botId][selfIndex] = card;
      }
    });
  }
  if (move.type === MoveType.FinishLookAtOwn) {
    const player = move?.self;
    const selfIndex = move?.selfIndex;
    const card = gameState?.hands?.[player]?.[selfIndex];
    botIds.forEach((botId: string) => {
      if (player !== botId) {
        return;
      }
      newGameState.botMemory[botId][botId][selfIndex] = card;
    });
  }
  if (move.type === MoveType.FinishLookAtOpponents) {
    const player = move?.self;
    const opponent = move?.opponent;
    const opponentIndex = move?.opponentIndex;
    const card = gameState?.hands?.[opponent]?.[opponentIndex];
    botIds.forEach((botId: string) => {
      if (player !== botId) {
        return;
      }
      newGameState.botMemory[botId][opponent][opponentIndex] = card;
    });
  }
  if (move.type === MoveType.SwapWithOpponent) {
    const player = move?.self;
    const opponent = move?.opponent;
    const opponentIndex = move?.opponentIndex;
    const playerIndex = move?.selfIndex;
    botIds.forEach((botId: string) => {
      const opponentCard = newGameState?.botMemory?.[botId]?.[opponent]?.[opponentIndex];
      const myCard = newGameState?.botMemory?.[botId]?.[player]?.[playerIndex];
      newGameState.botMemory[botId][player][playerIndex] = opponentCard;
      newGameState.botMemory[botId][opponent][opponentIndex] = myCard;
    });
  }

  validateBotMemories(newGameState, move);
  return newGameState;
};
