import React from "react";
import styled from "styled-components";
import { adHeight, mobileWidth } from "../layout";
import {useAd} from "../hooks";

const Image = styled.img`
    max-width: 100%;
    width: 320px;
`;

const Container = styled.div`
  background: transparent;
  width: 100%;
  height: ${adHeight}px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  @media (min-width: ${mobileWidth}px) {
    display: none;
  }
`;

function MobileAdvertisement(props: any) {
  const ad = useAd('Mobile');
  return (
    <Container>
        <a href={ad?.target} target="_blank" rel={"noopener noreferrer"}>
          <Image src={ad?.image} />
        </a>
        <img height="0" width="0" src={ad?.trackingUrl} style={{ position: 'absolute', visibility: 'hidden' }} />
    </Container>
  );
}

export default MobileAdvertisement;
