import React from "react";
import { useSelector} from "react-redux";
import {isSidebarOpen, showRoomFullModal} from "../redux/selectors";
import {FormButton, Link, Strapline, Title} from "../components/StyledComponents";
import Modal from "./Modal";

const RoomFullModal: React.FC = () => {
    const isOpen = useSelector(showRoomFullModal);
    const minimise = !useSelector(isSidebarOpen);
    return isOpen ? (
        <Modal minimise={minimise}>
            <Title>ROOM FULL</Title>
            <Strapline>{'This room already has 6 players.'}<br/>{'You can wait or create a new room.'}</Strapline>
            <Link to={'/create'}>
                <FormButton>
                    Create Room
                </FormButton>
            </Link>
        </Modal>
    ) : (
        <div />
    );
};

export default RoomFullModal;
