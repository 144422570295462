import React from "react";
import CopyUrlButton from "./CopyUrlButton";
import CallRafikiButton from "./CallRafiki";
import SlamButton from "./SlamButton";
import { useSelector } from "react-redux";
import {gameNotStarted, isSidebarOpen} from "../redux/selectors";
import styled from "styled-components";
import {sidebarOpenOffset, sidebarWidth, tabletWidth} from "../layout";
import {MinimiseProps, StyledButton} from "./StyledComponents";

interface ContainerProps extends MinimiseProps {
    showCopy: boolean;
};

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-rows: 1fr 5px 1fr;
  ${({showCopy}) => showCopy && `
      grid-template-rows: 1fr 5px 1fr 5px 1fr;
  `}
  position: absolute;
  right: ${sidebarWidth}px;
  top: 0;
  color: white;
  z-index: 10;
  padding: 20px;
  transition: transform 0.2s ease;
  transform: translateY(-100%) translateX(0%);
  ${({ minimise }) => minimise && `transform: translateY(-100%) translateX(${2 * sidebarOpenOffset}px);`};
  @media (max-width: ${tabletWidth}px) {
    right: 0;
  }
  @media (max-width: ${tabletWidth}px) {
    display: none;
  }
`;
function ButtonContainer(props: any) {
  const hasNotStarted = useSelector(gameNotStarted);
  const minimise = !useSelector(isSidebarOpen);
  return (
    <Container minimise={minimise} showCopy={hasNotStarted}>
      <CallRafikiButton />
      <SlamButton />
      {hasNotStarted && <CopyUrlButton />}
    </Container>
  );
}

export default ButtonContainer;
