import { Player, GameState, ModalID, Card, Score } from "../constants";

declare global {
  interface Window { _errs: any; }
}

export interface PlayerMap {
  [key: string]: Player;
}

export interface State {
  gameState: GameState;
  isUpdatingGameState: boolean;
  owner: string;
  self: string;
  roomCode: string;
  modals: {
    [key in ModalID]: boolean;
  };
  modalOffsets: {
    [ModalID.RoundOverModal]: {
      x: number,
      y: number
    };
  };
  players: PlayerMap;
  snackbarMessage: string;
  selectedCards: Card[];
  debug: boolean;
  sidebarOpen: boolean;
  scores: Score[];
  leaderBoardOpen: boolean;
  leaderBoardHeight: number;
  showTimeoutWarning: boolean;
  showSlamTimeoutWarning: boolean;
  coordinates?: DOMRect;
}

export interface Game {
  roomCode: string;
  rounds: number;
  playerCount: number;
  scores: any;
  winner: string;
}

export interface Chat {
  id: string;
  room: string;
  user: string;
  message: string;
  timestamp: number;
};

export enum BotDifficulty {
  Rookie = 'Rookie',
  Voyager = 'Voyager',
  Master = 'Master',
}

export interface Bot {
  id: string;
  name: string;
  difficulty: BotDifficulty
}

export enum BotSpeed {
  Fast = 'Fast',
  Normal = 'Normal',
}

export interface BotOption {
  id: string;
  name: string;
  difficulty: BotDifficulty;
}

export interface Ad {
  type: 'Mobile' | 'Desktop';
  image: string;
  trackingUrl: string;
  target: string;
}
