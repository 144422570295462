import React, { useCallback, useEffect, useRef, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import { useSelector } from "react-redux";
import { getPlayerMap, getRoomCode, getSelf, havePlayerNamesLoaded } from "../redux/selectors";
import { Chat } from "../redux/types";
import styled from "styled-components";
import sortBy from "lodash/sortBy";
import { circleHeight, tabletWidth, sidebarWidth, snackbarHeight } from "../layout";
import TextareaAutosize from "react-autosize-textarea";
import { useScroll } from "../hooks";
import {initializeAudio, isAnonymous, playAudio} from "../utils";

interface ContainerProps {
  open: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  min-width: 190px;
  max-width: 320px;
  width: calc((100vw - ${circleHeight}vmin + ${snackbarHeight}px - ${sidebarWidth}px) / 2);
  bottom: ${snackbarHeight}px;
  z-index: 100;
  @media (max-width: ${tabletWidth}px) {
    display: none;
  }
  transform: translate(0, -28px) translate(0, 100%);
  transition: 0.2s transform ease;

  ${({ open }) => open && `transform: none;`}
`;

interface ActiveProps {
  active: boolean;
}
const Header = styled.div<ActiveProps>`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  border-bottom: 28px solid #2c2f3e;
  color: #fecf6f;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  text-transform: uppercase;
  height: 0;
  padding: 0 5px;
  ${({ active }) =>
    active &&
    `
  color: #2c2f3e;
  border-bottom: 28px solid #fecf6f;
    
  `}
`;

const Plus = styled.div`
  float: right;
`;

const ChatBody = styled.div<ContainerProps>`
  border: 1px solid #fecf6f;
  background: #2c2f3e;
  opacity: 0;
  transition: 0.2s opacity ease;
  ${({ open }) => open && `opacity: 1;`}
`;

const ChatContent = styled.div`
  padding: 6px 15px 0 15px;
  max-height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 18px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 999rem;
    background-color: #4c4e5c;
  }
`;

interface Mine {
  mine: boolean;
}

const PlayerName = styled.span<Mine>`
  color: #e2e3ea;
  font-weight: 600;
  ${({ mine }) =>
    mine &&
    `
  color: #fff;
  font-weight: 300;
    
    `}
`;

const Bubble = styled.div<Mine>`
  position: relative;
  background-color: #626573;
  margin: 2px 0;
  padding: 10px;
  opacity: 0.8;
  border-radius: 11px;
  font-weight: 600;
  font-size: 0.5625rem;
  letter-spacing: 0;
  line-height: 13px;
  color: #fff;
  overflow-wrap: break-word;
  ${({ mine }) =>
    mine &&
    `
  font-weight: 600;
      color: #e2e3ea;
  background-color: #1d202e;
    `}

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: -11px;
    left: auto;
    border: 14px solid;
    border-color: transparent transparent #626573 transparent;
    z-index: 0;
    transform: scaleX(0.5);
    ${({ mine }) =>
      mine &&
      `
        left: -10px;
        right: auto;
        font-weight: 300;
        color: #e2e3ea;
        border-color: transparent transparent #1d202e transparent;
    `}
  }
`;

const ChatInput = styled(TextareaAutosize)`
  background-color: #202231;
  height: 16px;
  color: #ffffff;
  font-size: 0.5625rem;
  font-family: "Work Sans";
  font-weight: 300;
  letter-spacing: 0;
  width: 100%;
  line-height: 10px;
  padding: 11px 8px;
  border: none;
  resize: none;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: white;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: white;
  }
`;

const ChatInputContainer = styled.div`
  font-family: "Work Sans";
  padding: 10px 15px;
`;

interface Chats {
  [key: string]: Chat;
}

function ChatBox() {
  const db = firebase.firestore();
  const roomCode = useSelector(getRoomCode);
  const players = useSelector(getPlayerMap);
  const [chats, setChats] = useState<Chats>({});
  const [text, setText] = useState("");
  const self = useSelector(getSelf);
  const loaded = useSelector(havePlayerNamesLoaded);
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(false);
  const [listen, setListener] = useState(false);
  const ref = useRef(null);
  const orderedChats = sortBy(
    Object.keys(chats).map((id) => chats[id]),
    "timestamp"
  );
  const [cursor, setCursor] = useState<any>();
  const [oldScrollHeight, setOldScrollHeight] = useState(0);

  useEffect(() => {
    initializeAudio();
  }, ["once"]);

  // @ts-ignore
  const scrollHeight = ref?.current?.scrollHeight;

  useEffect(() => {
    setOldScrollHeight(scrollHeight);
  }, [scrollHeight]);

  const loadOnScroll = useCallback(
    function (snapshot) {
      const newChats = {
        ...chats,
      };
      snapshot.forEach(function (doc: any) {
        setCursor(doc);
        newChats[doc.id] = {
          ...doc.data(),
          id: doc.id,
        } as Chat;
      });
      setChats(newChats);
      if (ref?.current) {
        // @ts-ignore
        ref.current.scrollTop = ref.current.scrollHeight - oldScrollHeight + ref.current.scrollTop;
      }
    },
    [chats, oldScrollHeight]
  );

  useScroll(ref, (percent: number) => {
    if (percent === 0) {
      // load more
      db.collection("chats")
        .where("room", "==", roomCode)
        .orderBy("timestamp", "desc")
        .startAfter(cursor)
        .limit(10)
        .get()
        .then(loadOnScroll);
    }
  });

  const onSnapshot = useCallback(
    function (querySnapshot: any) {
      const scrollToBottom = () => {
        if (ref?.current) {
          // @ts-ignore
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      };
      const newChats = {
        ...chats,
      };
      querySnapshot.forEach(function (doc: any) {
        setCursor(doc);
        newChats[doc.id] = {
          ...doc.data(),
          id: doc.id,
        } as Chat;
      });
      setChats(newChats);
      playAudio("chat");
      if (!open) {
        setActive(true);
      }
      scrollToBottom();
    },
    [chats, open]
  );

  useEffect(() => {
    if (!roomCode) {
      return;
    }
    if (listen) {
      return;
    }

    db.collection("chats").where("room", "==", roomCode).orderBy("timestamp", "desc").limit(10).onSnapshot(onSnapshot);

    setListener(true);
  }, [roomCode, listen, db, onSnapshot]);

  if (!loaded) {
    return null;
  }
  const onSubmit = (e: any) => {
    e.preventDefault();
    db.collection("chats").add({
      user: self,
      message: text,
      room: roomCode,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  if (isAnonymous()) {
      return null
  }
  return (
    <Container open={open}>
      <Header
        active={active || open}
        onClick={(e: React.SyntheticEvent) => {
          e?.stopPropagation();
          setOpen(!open);
          if (!open) {
            setActive(false);
          }
        }}
      >
        Chat
        <Plus>{open ? "-" : "+"}</Plus>
      </Header>
      <ChatBody open={open}>
        <ChatContent ref={ref} id={"chat-content"}>
          {orderedChats.map((chat) => {
            const mine = chat.user === self;
            return (
              <Bubble key={chat.id} mine={mine}>
                <PlayerName mine={mine}>{players?.[chat.user]?.name}</PlayerName>: {chat.message}
              </Bubble>
            );
          })}
        </ChatContent>
        <ChatInputContainer>
          <form onSubmit={onSubmit}>
            <ChatInput
              type={"text"}
              placeholder={"Write message..."}
              value={text}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  if (text) {
                    onSubmit(e);
                    setText("");
                  }
                }
              }}
              onChange={(e: any) => {
                e.preventDefault();
                setText(e.target.value);
              }}
            />
          </form>
        </ChatInputContainer>
      </ChatBody>
    </Container>
  );
}

export default ChatBox;
