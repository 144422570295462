import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCurrentPlayer,
  getDraw,
  getDrawAndDiscard,
  getGameWinners,
  getPreviousPlayer,
  getPreviousTurn,
  getSelf,
  getSlamState,
  getTurnState,
  isGameOver,
  isMyTurn,
  isNewGame,
  isRafikiCalled, isRoundOver,
  showRoundOverModal,
} from "../redux/selectors";
import { SlamState, TurnState } from "../constants";
import { playAudio } from "../utils";

function Sounds() {
  const previousTurn = useSelector(getPreviousTurn);
  const rafikiCalled = useSelector(isRafikiCalled);
  const drawn = useSelector(getDraw);
  const slamState = useSelector(getSlamState);
  const newGame = useSelector(isNewGame);
  const { discard } = useSelector(getDrawAndDiscard);
  const previousTurnState = previousTurn?.turnState;
  const gameWinners: any[] = useSelector(getGameWinners);
  const self = useSelector(getSelf);
  const iWon = gameWinners?.some((p: any) => p?.id === self);
  const turnState = useSelector(getTurnState);
  const gameOver = useSelector(isGameOver);
  const roundOverModalOpen = useSelector(showRoundOverModal);
  const myTurn = useSelector(isMyTurn);
  const previousPlayer = useSelector(getPreviousPlayer);
  const currentPlayer = useSelector(getCurrentPlayer);
  const roundOver = useSelector(isRoundOver);

  useEffect(() => {
    if (gameOver && iWon && roundOverModalOpen) {
      playAudio("win");
    }
  }, [gameOver, iWon, roundOverModalOpen]);

  useEffect(() => {
    if (!newGame) return;
    playAudio("shuffle");
  }, [newGame]);

  useEffect(() => {
    if (!rafikiCalled) return;
    playAudio("calling_rafiki");
  }, [rafikiCalled]);

  useEffect(() => {
    if (currentPlayer?.id === self) return;
    if (!drawn) return;
    playAudio("pickup_card");
  }, [drawn]);

  useEffect(() => {
    if (previousTurnState !== TurnState.SwapWithOpponent) return;
    playAudio("blind_swap");
  }, [previousTurnState]);

  useEffect(() => {
    if (previousPlayer?.id !== self && previousTurnState === TurnState.Replace) {
      playAudio("putdown_card");
    }
  }, [previousTurnState, previousPlayer?.id]);

  useEffect(() => {
    if (
      currentPlayer?.id !== self &&
      [TurnState.SwapWithOpponent, TurnState.LookAtOpponents, TurnState.LookAtOwn].includes(turnState)
    ) {
      playAudio("putdown_card");
    }
  }, [currentPlayer?.id, turnState]);

  useEffect(() => {
    if (previousPlayer?.id === self) return;
    if (previousTurnState === TurnState.LookAtOwn || previousTurnState === TurnState.LookAtOpponents) {
      playAudio("pickup_card");
    }
  }, [previousTurnState, previousPlayer?.id, self]);

  useEffect(() => {
    if (slamState !== SlamState.SlamStart) return;
    playAudio("slamming");
  }, [slamState]);

  useEffect(() => {
    if (slamState === SlamState.SlamFinishSuccessOwn) {
      playAudio("putdown_card");
    }
  }, [slamState]);

  useEffect(() => {
    if (myTurn && !roundOver) {
      // To make sure other sounds have played
      // The turn sound is of a shorter duration
      setTimeout(() => {
        playAudio("turn");
      }, 600);
    }
  }, [myTurn, roundOver]);

  return <div></div>;
}

export default Sounds;
