import { useHistory } from "react-router-dom";
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import {useEffect, useState} from "react";
import {roomCodeFromLocation} from "./utils";
import {Ad} from "./redux/types";
import useMountedState from "react-use/lib/useMountedState";

export const useRedirectToCreateIfLoggedIn = () => {
  const history = useHistory();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      const roomCode = roomCodeFromLocation();
      if (user?.uid && !roomCode) {
        history.push("/create");
      }
    });
  }, [history]);
};

export const useScroll = (el: any, callback: Function) => {

  useEffect(() => {
    const listener = (e: any) => {
      const target = el?.current;
      const percent = target?.scrollTop / (target?.scrollHeight - target?.clientHeight);
      callback(percent);
    };

    if (!el.current) return;

    el?.current?.addEventListener('scroll', listener);

    return () => {
      el?.current?.removeEventListener('scroll', listener);

    }
  }, [el, callback])
};

export const useAd = (type: string) => {
  const [ad, setAd] = useState<Ad>();
  const mounted = useMountedState();
  useEffect(() => {
    if (ad) return;
    const getAd = async () => {
      const db = firebase.firestore();
      const ads = await db.collection("ads").where("active", "==", true).get();
      ads.forEach((temp) => {
        const adData = temp.data() as Ad;
        if (adData.type === type) {
          mounted() && setAd(adData);
        }
      });
    };
    getAd();
  }, [ad]);
  return ad;
};

