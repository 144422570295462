import React from "react";
import styled from "styled-components";
import { desktopAdHeight, desktopAdZIndex, mobileWidth } from "../layout";
import { useAd } from "../hooks";

const Container = styled.div`
  background: #2c2f3d;
  width: 100%;
  height: ${desktopAdHeight}px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${desktopAdZIndex};
`;

const Image = styled.img`
    max-width: 100%;
    width: 400px;
`;

function DesktopAdvertisement(props: any) {
  const ad = useAd("Desktop");
  return (
    <Container>
      <a href={ad?.target} target={'_blank'} rel={"noopener noreferrer"}>
        <Image src={ad?.image} />
      </a>
      <img height="0" width="0" src={ad?.trackingUrl} style={{ position: "absolute", visibility: "hidden" }} />
    </Container>
  );
}

export default DesktopAdvertisement;
