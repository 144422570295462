import React, { useCallback, useEffect, useRef } from "react";
import { StyledModal } from "../components/StyledComponents";
import useClickAway from "react-use/lib/useClickAway";
import { ModalID } from "../constants";
import { setModalOpen } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { getModalOffset } from "../redux/selectors";

interface Props {
  minimise: boolean;
  modalID?: ModalID;
  onClick?: () => void;
  red?: boolean;
  yellow?: boolean;
  clickOutsideToClose?: boolean;
  draggable?: boolean;
  dragPreview?: boolean;
}

const Modal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const closeModal = useCallback(
    (e: Event) => {
      if (!props.modalID) {
        return;
      }
      if (!props.clickOutsideToClose) {
        return;
      }
      e?.preventDefault();
      e?.stopPropagation();
      dispatch(setModalOpen(props.modalID, false));
    },
    [dispatch, props.clickOutsideToClose, props.modalID]
  );

  const containerRef = useRef(null);
  const [{ isDragging }, ref] = useDrag({
    item: { type: props.modalID || "modal" },
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging(),
      };
    },
  });

  const { x, y } = useSelector(getModalOffset);

  // @ts-ignore
  useClickAway(containerRef, closeModal, ["click"]);

  const onEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        closeModal(e);
      }
    },
    [closeModal]
  );

  useEffect(() => {
    window.addEventListener("keydown", onEscape);

    return () => {
      window.removeEventListener("keydown", onEscape);
    };
  }, [onEscape]);

  if (props.draggable && !props.dragPreview && isDragging) {
    return null;
  }
  return (
    <div ref={containerRef}>
      <StyledModal
        red={props.red}
        yellow={props.yellow}
        minimise={props.minimise}
        ref={ref}
        onClick={props.onClick}
        translateX={props.draggable ? x : 0}
        translateY={props.draggable ? y : 0}
      >
        {props.children}
      </StyledModal>
    </div>
  );
};

Modal.defaultProps = {
  clickOutsideToClose: true,
};

export default Modal;
