import React, { useRef, useState } from "react";
import styled from "styled-components";
import ArrowUnsorted from "../svg_components/ArrowUnsorted";
import useClickAway from "react-use/lib/useClickAway";
import {mobileWidth, tabletWidth} from "../layout";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectLabel = styled.span`
  color: #e2e3ea;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
`;

interface DropdownProps {
  selected: string;
  label: string;
  options: any[];
  onChange: Function;
}

const SelectedOption = styled.div`
  display: flex;
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  height: 30px;
  line-height: 30px;
  text-transform: capitalize;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  padding: 0px 0px 0px 10px;
  cursor: pointer;

  &:hover {
    color: #fecf6f;
  }
  @media (max-width: ${tabletWidth}px) {
      width: 70px;
  }
  @media (max-width: ${mobileWidth}px) {
      width: 60px;
  }
`;

const OptionText = styled(SelectedOption)`
  display: block;
  background: #393c4b;
  color: #fff;
  &:hover {
      color: #fecf6f;
      background: #1F2231;
  }
}
`;

const OptionContainer = styled.div`
  display: inline;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0px, 100%);
  z-index: 100;
`;

const Icon = styled(ArrowUnsorted)`
  display: inline-block;
`;

function Dropdown(props: DropdownProps) {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpen(false);
  });

  return (
    <Container ref={ref}>
      <SelectLabel>{props.label}</SelectLabel>{" "}
      <SelectedOption
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.selected} <Icon height={14} width={14} />
      </SelectedOption>
      <OptionContainer>
        {open &&
          props.options.map((option) => {
            return (
              <OptionText
                key={option}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(false);
                  props.onChange(option);
                }}
              >
                {option}
              </OptionText>
            );
          })}
      </OptionContainer>
    </Container>
  );
}

export default Dropdown;
