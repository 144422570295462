import React, { useState } from "react";
import {
  FormLabel,
  LargeLogo,
  Link,
  FormButton,
  Main,
  Subtitle,
  TextField,
  YellowText,
  CTA,
  FormError,
  Container, AuthPageContainer, FormSuccess,
} from "./components/StyledComponents";
import logo from "./assets/rafiki-logo_red.svg";
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import {urlWithRoomCode, verifyCookieSupport} from "./utils";
import { useSelector } from "react-redux";
import { isSidebarOpen } from "./redux/selectors";
import useMountedState from "react-use/lib/useMountedState";
import Page from "./components/Page";
import styled from "styled-components";
import Footer from "./components/Footer";
import {Helmet} from "react-helmet";

function ResetPasswordLink(props: any) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const minimise = !useSelector(isSidebarOpen);

  let mounted = useMountedState();

  return (
    <Page>
      <Main>
        <Helmet>
          <title>Rafiki - Reset Password Link</title>
          <meta name={'description'} content={"Play with friends, win with masters"}/>
        </Helmet>
        <Container minimise={minimise}>
          <AuthPageContainer>
          <LargeLogo src={logo} alt="rakifi-logo" width={488} height={241} />
          <Subtitle>Need to reset your password?</Subtitle>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setLoading(true);
              const requestResetPassword = firebase
                .app()
                .functions("europe-west2")
                .httpsCallable("requestResetPassword");
              verifyCookieSupport()
                .then((value) => {
                  if (!value) {
                    setError("Cookies are disabled");
                  }
                  return requestResetPassword({
                    email,
                  });
                })
                .then(() => {
                  mounted() && setSuccess(true);
                  mounted() && setLoading(false);
                })
                .catch(function (error) {
                  const errorMessage = error.message;
                  if (mounted()) {
                    setError(errorMessage);
                    setLoading(false);
                  }
                });
            }}
          >
            <FormLabel>Enter your email address, and follow the link to set a new passwords</FormLabel>
            <TextField type={"email"} onChange={(e: React.BaseSyntheticEvent) => setEmail(e.target.value)} required />
            <FormError>{error}</FormError>
            <CTA>
              <FormButton type={"submit"} disabled={loading || success}>
                Send password reset link
              </FormButton>
              <div>
                <YellowText>Remembered&nbsp;your&nbsp;password? <Link to={urlWithRoomCode("/")}>Sign&nbsp;in&nbsp;now</Link></YellowText>

              </div>
              {success && <FormSuccess>A password reset link has been sent.</FormSuccess>}
            </CTA>
          </form>
          </AuthPageContainer>
          <Footer/>
        </Container>
      </Main>
    </Page>
  );
}

export default ResetPasswordLink;
