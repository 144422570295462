import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ModalID} from "../constants";
import {getAllOtherHumanPlayers, getRoomCode, isModalOpen, isSidebarOpen} from "../redux/selectors";
import {Strapline, TinyStyledButton, Title} from "../components/StyledComponents";
import Modal from "./Modal";
import styled from "styled-components";
import {setModalOpen, transferRoomOwnership} from "../redux/actions";
import {State} from "../redux/types";

const ActionText = styled.div`
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
  cursor: pointer;
`;

const PlayerContainer = styled.div`
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  margin-bottom: 40px;
`;

const PassOwnershipModal: React.FC = () => {
  const minimise = !useSelector(isSidebarOpen);
  const isOpen = useSelector((state: State) => isModalOpen(state, ModalID.PassOwnershipModal));
  const players = useSelector(getAllOtherHumanPlayers);
  const roomCode = useSelector(getRoomCode);
  const dispatch = useDispatch();
  return isOpen ? (
    <Modal minimise={minimise} modalID={ModalID.PassOwnershipModal}>
      <Title>Pass ownership of the room to another player</Title>
      <Strapline>
        Choose a player to whom who you would like to transfer room ownership. They will have the same privileges you
        have now, and you will demote to an ordinary player of this game. Room ownership can be transferred any time in
        the game by the owner.
      </Strapline>
      <PlayerContainer>
        {players.map((player) => {
          return (
            <TinyStyledButton
              key={player.id}
              onClick={() => {
                dispatch(transferRoomOwnership(roomCode, player.id));
              }}
            >
              {player.name}
            </TinyStyledButton>
          );
        })}
      </PlayerContainer>
      <ActionText
        onClick={() => {
          dispatch(setModalOpen(ModalID.PassOwnershipModal, false));
        }}
      >
        Cancel and return to game
      </ActionText>
    </Modal>
  ) : (
    <div />
  );
};

export default PassOwnershipModal;
