import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Board from "./components/Board";
import { RouteComponentProps } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import {
  areRoundTurnsOver,
  gameNotStarted,
  getCombinedPlayerIds,
  getOwner,
  getPlayers,
  getRoomCode,
  getSlamState,
  getTurnState,
  hasGameLoaded,
  imApproved,
  isNewGame,
  isOwner,
  isRoundOver,
  isSlamPending,
  shouldRecordGameStarted,
  shouldResetDeck,
  shouldSaveRound,
  shouldUpdateLeaderBoard,
} from "./redux/selectors";
import {
  addPlayer,
  finishRound,
  resetDeck,
  setGameState, setModalOffsets,
  setModalOpen,
  setOwner,
  setPlayers,
  setRoomCode, setRoundSaved,
  setScoreBoard,
  setSelf,
  updatePlayers,
} from "./redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ModalID, SlamState, TurnState } from "./constants";
import ViewTwoModal from "./modals/ViewTwoModal";
import Snackbar from "./components/Snackbar";
import SlamModal from "./modals/SlamModal";
import CallRafikiModal from "./modals/CallRafikiModal";
import RoundOverModal from "./modals/RoundOverModal";
import PauseModal from "./modals/PauseModal";
import ApproveModal from "./modals/ApproveModal";
import LoginModal from "./modals/LoginModal";
import Timeout from "./Timeout";
import TimedOutModal from "./modals/TimedOutModal";
import RoomFullModal from "./modals/RoomFullModal";
import Page from "./components/Page";
import { PlayerMap } from "./redux/types";
import DeniedModal from "./modals/DeniedModal";
import ChatBox from "./components/Chat";
import { GameMenu } from "./components/GameMenu";
import PassOwnershipModal from "./modals/PassOwnershipModal";
import MobileAdvertisement from "./components/MobileAdvertisement";
import TopButtonContainer from "./components/TopButtonContainer";
import {Helmet} from "react-helmet";
import RemovePlayersModal from "./modals/RemovePlayersModal";
import ConnectionStatus from "./modals/ConnectionStatus";
import {useDrop} from "react-dnd";
import {CustomDragLayer} from "./CustomDragLayer";

const GameArea = styled.div`
  flex-grow: 2;
  background-color: #393c4b;
  height: 100vh;
`;

type TParams = { roomCode: string };

const GameView: React.FC<RouteComponentProps<TParams>> = (props) => {
  const db = firebase.firestore();
  const [room, setRoom] = useState<any>();

  const dispatch = useDispatch();
  const owner = useSelector(getOwner);
  const approved = useSelector(imApproved);
  const loaded = useSelector(hasGameLoaded);
  const started = !useSelector(gameNotStarted);

  const saveRound = useCallback(({ roomCode }) => {
    firebase.app().functions("europe-west2").httpsCallable("saveRound")({ roomCode })
  }, []);

  const updateLeaderBoard = useCallback(({ roomCode }) => {
    firebase.app().functions("europe-west2").httpsCallable("updateLeaderBoard")
  }, []);

  const recordGameStarted = useCallback(({ roomCode }) => {
    firebase.app().functions("europe-west2").httpsCallable("recordGameStarted")
  }, []);

  const [, drop] = useDrop({
    accept: ModalID.RoundOverModal,
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
        if (delta) {
          dispatch(setModalOffsets(delta.x, delta.y));
        }
      return undefined;
    },
  });

  useEffect(() => {
    if (loaded && !approved && started) {
      window.location.href = "/create";
    }
  }, [approved, started, loaded]);

  let roomCode = useSelector(getRoomCode);
  if (!roomCode || roomCode !== props.match.params.roomCode) {
    roomCode = props.match.params.roomCode;
    dispatch(setRoomCode(roomCode));
  }
  const [gameListener, setGameListener] = useState(false);
  const [roomListener, setRoomListener] = useState(false);

  // This is required since resetting modals in the action only does it for the owner
  const newGame = useSelector(isNewGame);
  useEffect(() => {
    if (newGame) {
      dispatch(setModalOpen(ModalID.ViewTwoModal, true));
      dispatch(setModalOpen(ModalID.SlamModal, true));
      dispatch(setModalOpen(ModalID.RoundOverModal, false));
      dispatch(setModalOpen(ModalID.CallRafikiModal, true));
    }
  }, [newGame, dispatch]);

  const slamState = useSelector(getSlamState);
  useEffect(() => {
    if (slamState === SlamState.NoSlam || slamState === SlamState.SlamStart) {
      dispatch(setModalOpen(ModalID.SlamModal, true));
    }
  }, [slamState, dispatch]);

  useEffect(() => {
    if (roomListener) return;

    db.collection(`rooms`)
      .doc(roomCode)
      .onSnapshot((roomDoc) => {
        setRoom(roomDoc.data() || {});
      });
    setRoomListener(true);
  }, [db, roomCode, setRoom, roomListener]);

  useEffect(() => {
    if (room) {
      dispatch(setGameState(room.gameState));
    }
  }, [room, dispatch]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user?.uid) {
        dispatch(setSelf(user?.uid));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (room) {
      if (!owner || room.owner !== owner) dispatch(setOwner(room.owner));
    }
  }, [room, owner, dispatch]);

  const playerIds = useSelector(getCombinedPlayerIds);
  const playerMap = useSelector(getPlayers);
  const shouldGetPlayers = !playerIds?.every((p) => p in playerMap);
  useEffect(() => {
    if (!playerIds) {
      return;
    }
    if (!shouldGetPlayers) return;

    dispatch(updatePlayers(playerIds))
  }, [playerIds, db, dispatch, shouldGetPlayers]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      const uid = user?.uid || ''
      if (!playerIds?.includes(uid)) {
        dispatch(addPlayer(roomCode, uid));
      }
    });
  }, [playerIds, dispatch, roomCode]);

  const shdSaveRound = useSelector(shouldSaveRound);
  useEffect(() => {
    if (shdSaveRound) {
      saveRound({ roomCode });
    }
  }, [shdSaveRound, dispatch, roomCode, saveRound]);

  const shdUpdateLeaderBoard = useSelector(shouldUpdateLeaderBoard);
  useEffect(() => {
    if (shdUpdateLeaderBoard) {
      updateLeaderBoard({ roomCode });
    }
  }, [shdUpdateLeaderBoard, dispatch, roomCode, updateLeaderBoard]);

  const shdRecordGameStarted = useSelector(shouldRecordGameStarted);
  useEffect(() => {
    if (shdRecordGameStarted) {
      recordGameStarted({ roomCode });
    }
  }, [shdRecordGameStarted, dispatch, roomCode, recordGameStarted]);

  const roundId = room?.gameState?.roundId;
  const gameId = room?.gameState?.gameId;
  const gameSaved = room?.gameState?.gameSaved;
  useEffect(() => {
    const gameId = room?.gameState?.gameId;
    if (!gameId) return;
    if (gameId === gameListener) return;

    db.collection("games")
      .doc(gameId)
      .onSnapshot((game) => {
        const gameData = game.data();
        if (gameData?.scores) {
          dispatch(setScoreBoard(roomCode, gameData?.scores));
        } else {
          dispatch(setScoreBoard(roomCode, []));
        }
      });
    setGameListener(gameId);
  }, [roundId, db, dispatch, gameListener, roomCode, room, gameSaved, gameId]);

  const roundTurnsOver = useSelector(areRoundTurnsOver);
  const roundOver = useSelector(isRoundOver);
  const turnState = useSelector(getTurnState);

  const gameLoaded = useSelector(hasGameLoaded);
  const imOwner = useSelector(isOwner);
  const slamPending = useSelector(isSlamPending);

  useEffect(() => {
    if (imOwner && roundTurnsOver && !roundOver && !slamPending) {
      setTimeout(() => {
        dispatch(finishRound(roomCode, owner));
      }, 5000);
    }
  }, [roundTurnsOver, roundOver, imOwner, slamPending, dispatch, roomCode]);

  const shdResetDeck = useSelector(shouldResetDeck);

  useEffect(() => {
    if (shdResetDeck && imOwner) {
      setTimeout(() => {
        dispatch(resetDeck(roomCode));
      }, 1000);
    }
  }, [shdResetDeck, imOwner]);

  return (
    <Page>
      <GameArea
        ref={drop}
        onClick={(e: SyntheticEvent) => {
          e?.stopPropagation();
          if (turnState === TurnState.RoundOver) {
            dispatch(setModalOpen(ModalID.RoundOverModal, true));
          }
        }}
      >
        <Helmet>
          <title>Rafiki - Room {roomCode}</title>
          <meta name={'description'} content={"Play with friends, win with masters"}/>
        </Helmet>
        <style
          dangerouslySetInnerHTML={{
            __html: ` html {
                overflow-x: hidden;
                overflow-y: hidden; /* for IE */
            }`,
          }}
        />

        <Timeout />
        {gameLoaded && (
          <>
            <GameMenu />
            <TopButtonContainer />
            <Board />
            <Snackbar />
            <ViewTwoModal />
            <SlamModal />
            <CallRafikiModal />
            <RoundOverModal />
            <RemovePlayersModal/>
            <PauseModal />
            <ApproveModal />
            <TimedOutModal />
            <DeniedModal />
            <PassOwnershipModal />
            <ConnectionStatus />
            <ChatBox />
            <CustomDragLayer/>
          </>
        )}
        <RoomFullModal />
        <LoginModal />
        <MobileAdvertisement />
      </GameArea>
    </Page>
  );
};

export default GameView;
