export const sidebarWidth = 400;//px
export const sidebarMinWidth = 150;//px
export const sidebarOpenOffset = (sidebarWidth - sidebarMinWidth) / 2;
export const boardMargin = 20;//px

export const cardAspectRatio = 102.0/143.0;
export const cardGap = .5;//vh

export const circleHeight = 95;//vh

export const snackbarHeight = 38;//px

export const mobileWidth = 576;//px

export const tabletWidth = 992;//px

export const desktopWidth = 1280;//px

export const adHeight = 50;//px
export const desktopAdHeight = 50;//px

export const leaderboardZIndex = 100;
export const desktopAdZIndex = leaderboardZIndex + 100;
