import {GameState} from "../constants";

export const areGameRoundTurnsOver = (gameState: GameState) => {
    return (
        gameState?.loop >= gameState?.lastLoop &&
        gameState?.turn >= gameState?.lastTurn &&
        gameState?.lastTurn >= 0 &&
        gameState?.lastLoop >= 0
    );
};

