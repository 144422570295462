import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Card} from "./Card";
import {
  getDebug,
  getDrawAndDiscard,
  getRoomCode,
  getSelf,
  getTurnState,
  isMyTurn,
  areRoundTurnsOver,
  isSlamInitiated, getPreviousTurn, isUpdatingGameState
} from "../redux/selectors";
import {useDispatch, useSelector} from "react-redux";
import {discardCard, drawCard} from "../redux/actions";
import {colorPalette} from "../styleguide";
import {BoardLabel, Slam} from "./StyledComponents";
import {cardGap} from "../layout";
import {TurnState} from "../constants";
import { deck } from '../svgExports';
import {playAudio} from "../utils";
import {isSlamInProgress} from "../redux/rafiki";
import useMountedState from "react-use/lib/useMountedState";

const Wrapper = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr ${cardGap}vmin 1fr;
  grid-template-columns: 1fr ${cardGap}vmin 1fr;
  color: ${colorPalette.lightLavender};
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
`;

interface PileProps {
  ieColumn: number;
};

const Pile = styled.div<PileProps>`
  text-align: right;
  ${({ieColumn}) => ieColumn && `
  -ms-grid-column: ${ieColumn};
  grid-column: ${ieColumn};
  `};
`;
const RightPile = styled.div<PileProps>`
  ${({ieColumn}) => ieColumn && `
  -ms-grid-column: ${ieColumn};
  grid-column: ${ieColumn};
  `};
`;

const Board: React.FC = () => {
  const { draw, discard } = useSelector(getDrawAndDiscard);
  const dispatch = useDispatch();
  const roomCode = useSelector(getRoomCode);
  const slamInitiated = useSelector(isSlamInitiated);
  const myTurn = useSelector(isMyTurn);
  const turnState = useSelector(getTurnState);
  const self = useSelector(getSelf);
  const roundOver = useSelector(areRoundTurnsOver);
  const debug = useSelector(getDebug);
  const slamInProgress = useSelector(isSlamInProgress);
  const previousTurn = useSelector(getPreviousTurn);
  const [waitAfterSwap, setWaitAfterSwap] = useState(false);
  const previousTurnState = previousTurn?.turnState;
  const updatingGameState = useSelector(isUpdatingGameState);
  let mounted = useMountedState();

  useEffect(() => {
    if (previousTurnState !== TurnState.SwapWithOpponent) {
      return;
    }
    setWaitAfterSwap(true);
    setTimeout(() => {
      mounted() && setWaitAfterSwap(false);
    }, 2000);
  }, [previousTurnState, mounted]);


  const drawOnClick = () => {
    if (updatingGameState) return;
    if (waitAfterSwap) {
      return;
    }

    if (roundOver) return;

    if (myTurn && turnState === TurnState.Draw && !slamInProgress) {
      playAudio("pickup_card");
      dispatch(drawCard(roomCode, self));
    } else {
      playAudio('error');
    }
  };

  const discardOnClick = () => {
    if (updatingGameState) return;
    if (roundOver) return;

    if (myTurn && turnState === TurnState.Drawn && !slamInProgress) {
      playAudio("putdown_card");
      dispatch(discardCard(roomCode, self));
    } else {
      playAudio('error');
    }
  };
  return (
    <Wrapper>
      <Pile ieColumn={1}>
        {slamInitiated && !debug && <Slam>Slam!</Slam>}
        {deck[discard] && <link rel="preload" href={deck[discard]} as="image"/>}
        <Card playerID={"discard"} value={discard} size={"medium"} onClick={discardOnClick}/>
        <BoardLabel>Discard pile</BoardLabel>
      </Pile>
      <RightPile ieColumn={3}>
        {deck[draw] && <link rel="preload" href={deck[draw]} as="image"/>}
        <Card playerID={"draw"} value={draw} onClick={drawOnClick} size={"medium"} />
        <BoardLabel>Deck</BoardLabel>
      </RightPile>
    </Wrapper>
  );
};

export default Board;
