import React from "react";
import styled from "styled-components";
import { desktopWidth, mobileWidth, tabletWidth } from "../layout";

interface ContainerProps {
  yellow: boolean;
  red: boolean;
  bold: boolean;
  inverted?: boolean;
  noMargin?: boolean;
}

const Container = styled.div<ContainerProps>`
  user-select: none;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-left: 25%;
  margin-right: 0;
  ${({ inverted }) =>
    inverted &&
    `
    margin-left: 0;
    margin-right: 25%;
    transform: rotate(180deg);
  `}
  ${({ noMargin }) =>
    noMargin &&
    `
    margin-left: 0;
    margin-right: 0;
  `}
`;

const Loading = styled.span`
  font-style: italic;
`;

const Label = styled.div<ContainerProps>`
  font-size: 14px;
  letter-spacing: -0.61px;
  line-height: 17px;
  display: inline-block;
  font-weight: 300;
  padding: 0.5vh;
  color: #e2e3ea;
  @media (max-width: ${desktopWidth}px) {
    font-size: 14px;
  }
  @media (max-width: ${tabletWidth}px) {
    font-size: 12px;
    line-height: 1;
  }
  @media (max-width: ${mobileWidth}px) {
    font-size: 10px;
  }
  ${({ bold }) =>
    bold &&
    `
  font-weight: bold;
  color: #FDCC68;
  `}
  ${({ yellow }) =>
    yellow &&
    `
  background-color: #FDCC68;
  font-weight: bold;
  color: #A93646;
  `}
  ${({ red }) =>
    red &&
    `
  background-color: #A93646;
  font-weight: normal;
  color: #FDCC68;
  `}
`;

interface HandHeaderProps {
  yellow: boolean;
  red: boolean;
  bold: boolean;
  name: string;
  inverted?: boolean;
  hasLeft?: boolean;
  ready: boolean;
}

function HandHeader(props: HandHeaderProps) {
  return (
    <Container
      yellow={props.yellow}
      red={props.red}
      bold={props.bold}
      inverted={props.inverted}
      noMargin={props.hasLeft}
    >
      <Label yellow={props.yellow} red={props.red} bold={props.bold}>
        {props.hasLeft && `${props.name} left the game`}
        {!props.hasLeft && `${props.name}`} <Loading>{!props.ready && "(Loading)"}</Loading>
      </Label>
    </Container>
  );
}

export default HandHeader;
