import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  finishedLastRound,
  getGameScoreboard,
  getGameWinners,
  getPenaltyLine,
  getRoomCode,
  getRoundOverTitleAndStrapLine,
  getRoundWinners,
  getTotalRounds,
  isOwner,
  isSidebarOpen,
  showRoundOverModal,
} from "../redux/selectors";
import {newGame, startRound} from "../redux/actions";
import {CapitalizedTitle, FormButton, ModalContainer, Strapline,} from "../components/StyledComponents";
import Modal from "./Modal";
import useMountedState from "react-use/lib/useMountedState";
import styled from "styled-components";
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import {ModalID, REENABLE_BUTTON_TIMEOUT, SCORE_VIEW_TIMEOUT} from "../constants";

const Table = styled.table`
  width: 100%;
`;

const Td = styled.td`
  border-bottom: 1px solid #4c4e5c;
`;

const THeadItem = styled(Td)`
  color: #ffffff;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  text-align: right;
`;

const TScore = styled(Td)`
  text-align: right;
`;

const Tr = styled.tr`
  width: 100%;
  padding: 0;
  line-height: 40px;
  font-weight: bold;
  font-size: 0.875rem;
`;

interface IRoundOverModalProps {
  dragPreview?: boolean;
};

const RoundOverModal = (props: IRoundOverModalProps) => {
  const dispatch = useDispatch();
  let isOpen = useSelector(showRoundOverModal);
  const minimise = !useSelector(isSidebarOpen);
  const roomCode = useSelector(getRoomCode);
  const owner = useSelector(isOwner);
  const lastRoundDone = useSelector(finishedLastRound);
  const gameWinners = useSelector(getGameWinners);
  const gameScoreboard = useSelector(getGameScoreboard);
  const rounds = useSelector(getTotalRounds);
  const [disabled, setDisabled] = useState(true);
  let mounted = useMountedState();
  const penaltyLine = useSelector(getPenaltyLine);

  const enableButton = () => {
    mounted() && setDisabled(false);
  };

  const tied = gameWinners && gameWinners?.length > 1;

  let { title, strapLine } = useSelector(getRoundOverTitleAndStrapLine);

  useEffect(() => {
      if (isOpen) {
        setTimeout(enableButton, SCORE_VIEW_TIMEOUT);
      } else {
        setDisabled(true);
      }
  }, [isOpen]);

  useEffect(() => {
      if (!isOpen) return;
      firebase.analytics().logEvent('round_over');
      if (lastRoundDone) {
        firebase.analytics().logEvent('game_over');
      }

  }, [isOpen, lastRoundDone]);

  return isOpen ? (
    <Modal minimise={minimise} yellow={lastRoundDone} clickOutsideToClose={true} modalID={ModalID.RoundOverModal} draggable={true}>
      <CapitalizedTitle>{title}</CapitalizedTitle>
      <Strapline>{strapLine}
        {penaltyLine && <><br/> {penaltyLine}</>}
      </Strapline>
      <ModalContainer>
        <Table>
          <thead>
            <Tr>
              <THeadItem></THeadItem>
              <THeadItem>This round</THeadItem>
              <THeadItem>Total</THeadItem>
            </Tr>
          </thead>
          <tbody>
            {gameScoreboard &&
              gameScoreboard.map((player) => {
                return (
                  <Tr key={player.id}>
                    <Td>{player.name}</Td>
                    <TScore>{player.roundScore}</TScore>
                    <TScore>{player.score}</TScore>
                  </Tr>
                );
              })}
          </tbody>
        </Table>
      </ModalContainer>
      {owner && !lastRoundDone && (
        <FormButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            dispatch(startRound({ roomCode }));
            setTimeout(enableButton, REENABLE_BUTTON_TIMEOUT);
          }}
        >
          Start new round
        </FormButton>
      )}
      {owner && lastRoundDone && tied && (
        <FormButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            dispatch(startRound({ roomCode, winners: gameWinners?.map((w) => w?.id) }));
            setTimeout(enableButton, REENABLE_BUTTON_TIMEOUT);
          }}
        >
          Start round of death
        </FormButton>
      )}
      {owner && lastRoundDone && !tied && (
        <>
          <FormButton
            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              dispatch(newGame(roomCode, rounds));
              setTimeout(enableButton, REENABLE_BUTTON_TIMEOUT);
            }}
          >
            Start new game
          </FormButton>
        </>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default RoundOverModal;
