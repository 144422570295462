import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalID } from "../constants";
import { gamePaused, getRoomCode, getRoomOwner, isOwner, isSidebarOpen, isUpdatingGameState } from "../redux/selectors";
import { FormButton, Strapline, Title } from "../components/StyledComponents";
import Modal from "./Modal";
import { resumeGame } from "../redux/actions";

const PauseModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(gamePaused);
  const imOwner = useSelector(isOwner);
  const owner = useSelector(getRoomOwner);
  const roomCode = useSelector(getRoomCode);
  const minimise = !useSelector(isSidebarOpen);
  const updatingGameState = useSelector(isUpdatingGameState);
  if (!imOwner && !owner) {
    return null;
  }
  return isOpen ? (
    <Modal minimise={minimise} modalID={ModalID.PauseModal}>
      <Title>Game paused</Title>
      <Strapline>{imOwner ? "You have paused the game." : `${owner?.name} has paused the game.`}</Strapline>
      {imOwner && (
        <FormButton
          onClick={() => {
            if (updatingGameState) return;
            dispatch(resumeGame(roomCode));
          }}
        >
          Resume game
        </FormButton>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default PauseModal;
