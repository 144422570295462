import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gamePaused,
  getOwner,
  getRoomCode,
  getSlamPlayerId,
  getTimestamp,
  isMyTurn,
  isOwner,
  areRoundTurnsOver,
  shouldStartSlamTimeout,
  gameNotStarted, isEveryoneReady,
} from "./redux/selectors";
import { ENABLE_DEBUGGING, MOVE_TIMEOUT, MOVE_TIMEOUT_WARNING, SLAM_TIMEOUT, SLAM_TIMEOUT_WARNING } from "./constants";
import {
  enableSlamTimeoutWarning,
  enableTimeoutWarning,
  nextTurnIfTimedOut,
  removeInactivePlayers,
  slamTimeout,
} from "./redux/actions";
import useInterval from "react-use/lib/useInterval";

function Timeout() {
  const imOwner = useSelector(isOwner);
  const timestamp = useSelector(getTimestamp);
  const slamPlayer = useSelector(getSlamPlayerId);

  const dispatch = useDispatch();
  const roomCode = useSelector(getRoomCode);
  const owner = useSelector(getOwner);

  const paused = useSelector(gamePaused);
  const roundOver = useSelector(areRoundTurnsOver);
  const startTurnTimeout = useSelector(isMyTurn);
  const startSlamTimeout = useSelector(shouldStartSlamTimeout);
  const notStarted = useSelector(gameNotStarted);
  const everyoneReady = useSelector(isEveryoneReady);

  const [state, setState] = useState<{
    count: number;
  }>({
    count: 0,
  });

  useInterval(() => {
    setState({
      count: state.count + 1,
    });
  }, 1000);

  const timestampSeconds = timestamp?.seconds;
  const count = state.count;

  useEffect(() => {
    if (!timestampSeconds) return;

    setState({
      count: 0,
    });
  }, [timestampSeconds]);

  useEffect(() => {
    if (!notStarted && !paused && !roundOver && timestampSeconds && everyoneReady) {
      if (count === MOVE_TIMEOUT) {
        dispatch(nextTurnIfTimedOut(roomCode, timestampSeconds));
        setState({
          count: 0,
        });
      }
      if (count === SLAM_TIMEOUT) {
        dispatch(slamTimeout(roomCode, timestampSeconds, slamPlayer));
      }
      if (count === MOVE_TIMEOUT - MOVE_TIMEOUT_WARNING && startTurnTimeout) {
        dispatch(enableTimeoutWarning());
      }
      if (count === SLAM_TIMEOUT - SLAM_TIMEOUT_WARNING && startSlamTimeout) {
        dispatch(enableSlamTimeoutWarning());
      }
    }
  }, [count, notStarted, paused, roundOver, timestampSeconds, dispatch, roomCode, everyoneReady]);

  // Activity timeout
  const moveTimeoutMs = MOVE_TIMEOUT * 1000;
  useInterval(() => {
    if (!imOwner) {
      return;
    }
    if (paused) return;
    if (notStarted) return;
    if (roundOver) return;
    if (!everyoneReady) return;
    // if (!ENABLE_DEBUGGING) {
      dispatch(removeInactivePlayers(roomCode, owner));
    // }
  }, moveTimeoutMs);

  return <div></div>;
}

export default Timeout;
