import React, {useEffect, useState} from "react";
import { useSelector} from "react-redux";
import {getRoomCode} from "../redux/selectors";
import {OutlineButton} from "./StyledComponents";
import copy from 'copy-to-clipboard';
import useMountedState from "react-use/lib/useMountedState";
import styled from "styled-components";
import {REENABLE_BUTTON_TIMEOUT} from "../constants";
import * as firebase from "firebase";
import {isAnonymous} from "../utils";

interface MyProps {
}

const CustomButton = styled(OutlineButton)`
    grid-row: 5;
    -ms-grid-row: 5;
`;

const CopyUrlButton: React.FC<MyProps> = (props) => {
    const [copied, setCopied] = useState(false);
    const roomCode = useSelector(getRoomCode);
    let mounted = useMountedState();

    if (isAnonymous()) {
        return null
    }

    const onClick = () => {
        if (copied) {
            return;
        }
        copy(`${window.location.origin}/game/${roomCode}`);
        setCopied(true);
        setTimeout(() => {
            mounted && setCopied(false)
        }, REENABLE_BUTTON_TIMEOUT);
    };

    return <CustomButton onClick={onClick} className={'block'} disabled={copied}>
        {copied ? 'Copied' : 'Copy invite URL'}
    </CustomButton>;
};

export default CopyUrlButton;
