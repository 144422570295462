import styled from "styled-components";
import {cardSizes} from "../constants";
import {sidebarWidth, snackbarHeight, tabletWidth} from "../layout";

interface ImgProps {
    bright?: boolean;
    height?: number;
    selected?: boolean;
    angle?: number;
    ieColumn?: number;
    ieRow?: number;
    highlight?: boolean;
}

export const CardImg = styled.img<ImgProps>`
  transform: rotate(${({angle}) => angle}deg);
  ${({ height }) => `height: calc((100vmin - ${snackbarHeight}px) * ${height} / 100)`};
  ${({ height }) => `height: calc((min(calc(100vw - ${sidebarWidth}px), 100vh) - ${snackbarHeight}px) * ${height} / 100)`};
  transition: opacity 0.2s ease;
  @media (max-width: ${tabletWidth}px) {
    ${({ height }) => `height: calc((100vmin - ${snackbarHeight}px) * ${height} / 100)`};
  }
  ${({ ieColumn }) =>
    ieColumn &&
    `
    -ms-grid-column: ${ieColumn};
    grid-column: ${ieColumn};
  `};
  ${({ ieRow }) =>
    ieRow &&
    `
    -ms-grid-row: ${ieRow};
    grid-row: ${ieRow};
  `};
  ${({bright}) =>
    !bright &&
    `
    opacity: 0.4;
  `}
  ${({selected}) =>
    selected &&
    `
  `}
  ${({highlight}) => highlight && `
    border: solid 1px #fdcc68;
  `}
  
  &:active {
    opacity: 0.4;
  }
`;

CardImg.defaultProps = {
    selected: false,
    angle: 0,
    height: cardSizes['medium'],
    bright: true
};
