import React, { Suspense, lazy } from "react";
import styled from "styled-components";
import { Sidebar } from "./Sidebar";
import CookiePopup from "./CookiePopup";

const Wrapper = styled.div`
  background-color: #393c4b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

interface PageProps {
  children: React.ReactElement;
}

const SlammingInfoModal = lazy(() => import("../modals/SlammingInfoModal"));
function Page(props: PageProps) {
  return (
    <Wrapper>
      <Suspense fallback={<div />}>
        {props.children}
        <Sidebar />
        <SlammingInfoModal />
        <CookiePopup />
      </Suspense>
    </Wrapper>
  );
}

export default Page;
