import React from 'react';
import Dropdown from "./Dropdown";
import {BotDifficulty} from "../redux/types";

interface Props {
    id: string;
    difficulty: BotDifficulty;
    onChange: Function
};

function SelectBot(props: Props) {

    const options = [
        BotDifficulty.Rookie,
        BotDifficulty.Voyager,
        BotDifficulty.Master,
    ];

    return (
        <Dropdown label={'Difficulty'} options={options} selected={props.difficulty} onChange={(option: BotDifficulty) => {
            props.onChange(option);
        }}/>
    );
}

export default SelectBot;
