import React, {Fragment, SyntheticEvent, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ANONYMOUS_USER_NAME, ModalID, REENABLE_BUTTON_TIMEOUT} from "../constants";
import {
  canStartGame,
  getAllOtherHumanPlayers,
  getBots,
  getHumanPlayerCount,
  getOwnPlayer,
  getPlayerStatus,
  getRoomCode,
  isOwner,
  isSidebarOpen,
  showApproveModal,
} from "../redux/selectors";
import {
  FormButton, LightTextField,
  ListItem,
  ListText,
  ModalContainer,
  TinyStyledButton,
  Title,
  UnorderedList,
} from "../components/StyledComponents";
import Modal from "./Modal";
import {
  addBot,
  approvePlayer,
  denyPlayer,
  newGame,
  removeBot,
  renameBot,
  setBotDifficulty,
  setQuickPlayName
} from "../redux/actions";
import styled from "styled-components";
import SelectRounds from "./SelectRounds";
// @ts-ignore
import XCircle from "react-feather/dist/icons/x-circle";
import SelectBot from "../components/SelectBot";
import {BotDifficulty, BotOption} from "../redux/types";
import BotName from "../components/BotName";
import range from "lodash/range";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import useMountedState from "react-use/lib/useMountedState";
import {generateBotId, isAnonymous} from "../utils";
import {desktopWidth, mobileWidth, tabletWidth} from "../layout";
import {PlayerStatus} from "../common";

const Message = styled.div`
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
  margin-top: 5px;
`;

const Text = styled.div`
  color: #e2e3ea;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  @media (max-width: ${desktopWidth}px) {
    font-size: 15px;
  }
  @media (max-width: ${tabletWidth}px) {
    font-size: 14px;
  }
  @media (max-width: ${mobileWidth}px) {
    font-size: 12px;
  }
`;
const Subtitle = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  margin-top: 30px;
  border-bottom: 1px solid #4c4e5c;
  line-height: 43px;
`;

const OptionText = styled.span`
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 28px;
`;

const RemoveBot = styled(XCircle)`
  vertical-align: middle;
  stroke: #fecf6f;
  height: 17.5px;
  width: 17.5px;
`;

export const AddAnother = styled.button`
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: right;
  display: inline-block;
  padding: 0 5px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ListTextWithoutPadding = styled(ListText)`
  padding: 0;
`;

const ApproveModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(showApproveModal);
  const imOwner = useSelector(isOwner);
  const roomCode = useSelector(getRoomCode);
  const playerStatus = useSelector(getPlayerStatus);
  const players = useSelector(getAllOtherHumanPlayers);
  const humanPlayerCount = useSelector(getHumanPlayerCount);
  const self = useSelector(getOwnPlayer)

  const minimise = !useSelector(isSidebarOpen);
  const canStart = useSelector(canStartGame);
  const [hidden, setHidden] = useState(false);
  const [rounds, setRounds] = useState(1);
  const bots = useSelector(getBots);
  const mounted = useMountedState();

  const count = humanPlayerCount + bots.length;
  return isOpen ? (
    <Modal yellow={true} minimise={minimise} modalID={ModalID.ApproveModal} clickOutsideToClose={false}>
      <Title>Game Lobby</Title>
      <ModalContainer>
        <UnorderedList>
          {isAnonymous() &&
            <Fragment>
              <Subtitle style={{borderBottom: 'none'}}>Enter your name
              </Subtitle>
              <LightTextField
                type={"text"}
                onChange={(e: React.BaseSyntheticEvent) => dispatch(setQuickPlayName(e.target.value))}
                value={self?.name === ANONYMOUS_USER_NAME ? '' : self?.name}
              />
           </Fragment>}
          <ListItem>
            <Text>Number of rounds</Text>
            <SelectRounds rounds={rounds} setRounds={setRounds} />
          </ListItem>
        </UnorderedList>
        {!isAnonymous() &&
        <Fragment>
          <Subtitle>Approve human players</Subtitle>
          <UnorderedList>
            {players.map((player) => {
              if (!player?.name) return null;
              return (
                  <ListItem key={player?.id}>
                    <OptionText>{player?.name}</OptionText>
                    {playerStatus?.[player?.id] === PlayerStatus.Pending && (
                        <span>
                    <ListText
                        onClick={() => {
                          dispatch(denyPlayer(roomCode, player?.id));
                        }}
                    >
                      Deny
                    </ListText>
                    <TinyStyledButton
                        disabled={count >= 6}
                        onClick={() => {
                          dispatch(approvePlayer(roomCode, player?.id));
                        }}
                    >
                      Approve
                    </TinyStyledButton>
                  </span>
                    )}
                    {playerStatus?.[player?.id] === PlayerStatus.Approved && (
                        <ListTextWithoutPadding>Approved!</ListTextWithoutPadding>
                    )}
                    {playerStatus?.[player?.id] === PlayerStatus.Denied && (
                        <ListTextWithoutPadding>Denied!</ListTextWithoutPadding>
                    )}
                  </ListItem>
              );
            })}
          </UnorderedList>
        </Fragment>
        }
        <Subtitle>
          Add computer players{" "}
          {count < 6 && (
            <AddAnother
              onClick={(e: SyntheticEvent) => {
                if (count >= 6) return;
                const existingNames = bots.map((b) => b.name);
                const names = range(bots.length + 1).map((i) => `Computer ${i + 1}`);
                const name = names.find((n) => !existingNames.includes(n)) || "Computer";
                dispatch(addBot(roomCode, {
                  id: generateBotId(),
                  name: name,
                  difficulty: BotDifficulty.Voyager,
                }));
              }}
            >
              {bots.length === 0 ? "Add" : "Add another"}
            </AddAnother>
          )}
        </Subtitle>
        <UnorderedList>
          {bots.map((bot: any, i: number) => {
            return (
              <ListItem key={bot.name}>
                <span>
                  <RemoveBot
                    onClick={(e: SyntheticEvent) => {
                      dispatch(removeBot(roomCode, bot.id));
                    }}
                  />
                  &nbsp;
                  <BotName
                    bot={bot}
                    onChange={(b: BotOption) => {
                      dispatch(renameBot(roomCode, b.id, b.name));
                    }}
                  />
                </span>
                <SelectBot
                  id={bot.id}
                  difficulty={bot.difficulty}
                  onChange={(option: BotDifficulty) => {
                    dispatch(setBotDifficulty(roomCode, bot.id, option));
                  }}
                />
              </ListItem>
            );
          })}
        </UnorderedList>
      </ModalContainer>
      {imOwner && (
        <FormButton
          disabled={hidden || !canStart || count < 2}
          onClick={() => {
            setHidden(true);
            dispatch(newGame(roomCode, rounds));
            setTimeout(() => {
              mounted() && setHidden(false);
            }, REENABLE_BUTTON_TIMEOUT);
          }}
        >
          Start game
        </FormButton>
      )}
      <Message>Approve everyone to begin game</Message>
    </Modal>
  ) : (
    <div />
  );
};

export default ApproveModal;
