import React from "react";
import styled from "styled-components";
import {getPlayerCount, getShouldShowRadar} from "../redux/selectors";
import { useSelector } from "react-redux";
import {State} from "../redux/types";

// https://stackoverflow.com/questions/21205652/how-to-draw-a-circle-sector-in-css
const Wrapper = styled.div<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  background-color: #424552;
  opacity: ${(props) => props.shouldShowRadar ? 1 : 0};
`;

const Inner = styled.div<any>`
  position: absolute;
  height: inherit;
  width: inherit;
  background-image: ${(props) =>
    `linear-gradient(${
      90 + props.angle
    }deg, transparent 50%, #4c4e5c 50%), linear-gradient(90deg, #4c4e5c 50%, transparent 50%)`};
  transform: ${(props) => `rotate(180deg) rotate(${-props.angle / 2}deg) rotate(${props.rotate}deg);`};
  transition: opacity 1s ease;
`;

interface IRadarProps {
  radarIndex: number
  playerID: string
}

function Radar(props: IRadarProps) {
  const count = useSelector(getPlayerCount);
  const shouldShowRadar = useSelector((state: State) => getShouldShowRadar(state, props.playerID))
  const rotate = (props.radarIndex * 360) / count;
  return (
    <Wrapper shouldShowRadar={shouldShowRadar}>
      <Inner angle={65} rotate={rotate}/>
    </Wrapper>
  );
}

export default Radar;
