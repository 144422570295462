import React, {useState} from "react";
import styled from "styled-components";

import logo from "../../assets/rafiki-logo_red.svg";
import { Sections } from "./Sections";
import { HorizontalDivider } from "./Dividers";

import { colorPalette } from "../../styleguide";
import {sidebarMinWidth, sidebarWidth, tabletWidth} from "../../layout";
import { toggleSidebar } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { isLeaderBoardOpen, isSidebarOpen } from "../../redux/selectors";
import GlobalLeaderBoard from "../GlobalLeaderBoard";
import tab from "../../assets/tab.svg";
import useMeasure from "react-use/lib/useMeasure";
import DesktopAdvertisement from "../DesktopAdvertisement";

interface MinimiseProps {
  minimise: boolean;
}

const Wrapper = styled.div<MinimiseProps>`
  background: ${colorPalette.darkGrey};
  color: ${colorPalette.lightLavender};
  right: 0;
  height: 100%;
  position: fixed;
  z-index: 400;
  top: 0;
  border-left: 3px solid ${colorPalette.yellow};
  box-sizing: border-box;
  width: ${sidebarWidth}px;
  max-width: 100vw;
  transition: transform 0.2s ease-in-out;
  transform: ${({ minimise }) => minimise && `translateX(${sidebarWidth - sidebarMinWidth}px)`};
  @media (max-width: ${tabletWidth}px) {
      transform: ${({ minimise }) => minimise && `translateX(${sidebarWidth - 3}px)`};
  }
  @media (max-width: 400px) {
      transform: ${({ minimise }) => minimise && `translateX(calc(100vw - 3px))`};
  }

  &:hover .show-minimise {
    opacity: 1;
  }
`;

interface ScrollableProps {
  leaderBoardOpen: boolean;
  leaderBoardHeight: number;
}
const Scrollable = styled.div<ScrollableProps>`
  overflow-y: scroll;
  width: 100%;
  padding: 1.9rem 1.9rem 6.2rem 1.9rem;
  height: 100%;
  ${({ leaderBoardOpen, leaderBoardHeight }) =>
    leaderBoardOpen && `padding-bottom: calc(3.2rem + ${leaderBoardHeight}px)`};
`;

const Img = styled.img`
  height: 7rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  color: #e2e3ea;
  font-family: "Work Sans";
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.59px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const Minimise = styled.div<MinimiseProps>`
  background-image: url("${tab}");
  color: #2c2f3e;
  font-weight: bold;
  transform: translateX(-50%) translateY(-50%) rotate(90deg) translateY(-50%);
  ${({ minimise }) => minimise && "transform: translateX(-50%) translateY(-50%) rotate(270deg) translateY(-50%)"};
  position: absolute;
  left: 0;
  transform-origin: 50% 50%;
  top: 50%;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  width: 155px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  font-size: 0.8125rem;
  @media (max-width: ${tabletWidth}px) {
      opacity: 1;
  }
`;

const Contents = styled.div<MinimiseProps>`
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  opacity: ${({ minimise }) => minimise && `0`};
`;

const MinimisedLogo = styled.div<MinimiseProps>`
  position: absolute;
  left: ${sidebarMinWidth / 2}px;
  height: ${sidebarMinWidth}px;
  top: 50%;
  transform: rotate(90deg) translateY(-50%) translateX(-50%);
  transition: opacity 0.2s ease-in-out;
  transform-origin: 0 0;
  opacity: 0;
  opacity: ${({ minimise }) => minimise && `1`};
  display: flex;
  align-items: center;
  pointer-events: none;
  user-select: none;
`;

const LeaderBoard = styled(Contents)`
  position: relative;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  opacity: ${({ minimise }) => minimise && `0`};
`;

const DesktopAdContainer = styled(Contents)`
`;

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch();

  const minimise = !useSelector(isSidebarOpen);
  const leaderBoardOpen = useSelector(isLeaderBoardOpen);
  const [activeSectionId, openSection] = useState<number>();

  const [ref, { height }] = useMeasure();

  return (
    <Wrapper minimise={minimise}>
      <Minimise
        minimise={minimise}
        className={"show-minimise"}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        {minimise ? "Open rules" : "Minimise rules"}
      </Minimise>
      <Scrollable leaderBoardOpen={leaderBoardOpen} leaderBoardHeight={height}>
        <Contents minimise={minimise}>
          <ImageContainer>
            <Img src={logo} alt="Rafiki Logo" />
          </ImageContainer>
          <Description>Play with friends, win with masters.</Description>
          <HorizontalDivider />
          <Sections activeSectionId={activeSectionId} openSection={openSection}/>
        </Contents>
        <MinimisedLogo minimise={minimise}>
          <Img src={logo} alt="rakifi-logo" />
        </MinimisedLogo>
      </Scrollable>
      <LeaderBoard minimise={minimise}>
        <GlobalLeaderBoard ref={ref} showShareText={false}/>
      </LeaderBoard>
        <DesktopAdContainer minimise={minimise}>
            <DesktopAdvertisement/>
        </DesktopAdContainer>
    </Wrapper>
  );
};
