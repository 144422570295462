import React from "react";
import { ButtonGroup, TinyOutlineButton } from "../components/StyledComponents";
import styled from "styled-components";
import {mobileWidth} from "../layout";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
interface SelectRoundsProps {
  rounds: number;
  setRounds: Function;
}

const SelectButton = styled(TinyOutlineButton)`
  border-radius: 0;
  background-color: #191B26;
  min-height: 25px;
  min-width: 28px;
  padding: 0;
  border: none;
  ${({active}) => active && `
      background-color: #fecf6f;
      color: #2c2f3e;
      z-index: 10;
      position: relative;
  `}
  @media (max-width: ${mobileWidth}px) {
    min-height: 25px;
    min-width: 28px;
    padding: 0;
  }
`;

function SelectRounds(props: SelectRoundsProps) {
  return (
    <Wrapper>
      <ButtonGroup>
        <SelectButton onClick={() => props.setRounds(1)} active={props.rounds === 1}>
          1
        </SelectButton>
        <SelectButton onClick={() => props.setRounds(3)} active={props.rounds === 3}>
          3
        </SelectButton>
        <SelectButton onClick={() => props.setRounds(5)} active={props.rounds === 5}>
          5
        </SelectButton>
      </ButtonGroup>
    </Wrapper>
  );
}

export default SelectRounds;
