// This isn't a redux reducer
import {
  GameState,
  Move,
  MoveType,
  NOCARD,
  NoSlam,
  PreviousPlayerLeft,
  SlamFinishSuccessOwn,
  SlamStart,
  SlamState,
  SlamSuccessOpponent,
  SlamTimeout,
  TurnState,
} from "../constants";
import {getCardAction, InvalidMoveError, isCorrectSlam, reportError} from "../utils";
import {canPlayerMove} from "./actions";
import isNumber from "lodash/isNumber";
import isInteger from "lodash/isInteger";
import uniq from "lodash/uniq";
import reverse from "lodash/reverse";
import {cardScore, PlayerStatus} from "../common";
import {UNKNOWN} from "../bot";
import {areGameRoundTurnsOver} from "./gameSelectors";
import {deck} from "../svgExports";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";

const getPlayerCount = (gameState: GameState) => {
  return gameState?.playerIds.length;
};

export const slamTimeoutState = (gameState: GameState) => {
  return {
    ...resetSlam(gameState),
    slam: {
      slamState: SlamState.SlamTimeout,
      player: gameState?.slam?.player,
    } as SlamTimeout,
  };
};

export const clearSlam = (gameState: GameState) => {
  return {
    ...gameState,
    slam: {
      slamState: SlamState.NoSlam,
      player: "",
      slamDiscard: "",
    } as NoSlam,
  };
};

export const resetSlam = (gameState: GameState) => {
  const newFailedSlamCount: { [key: string]: number } = {};
  Object.keys(gameState?.failedSlamCount).forEach((playerId) => {
    newFailedSlamCount[playerId] = 0;
  });
  return {
    ...clearSlam(gameState),
    failedSlamCount: newFailedSlamCount,
  };
};

export const moveDiscardsToDeck = (gameState: GameState) => {
  if (gameState?.deck.length > 0) {
    return gameState;
  }
  const lastDiscardIndex = gameState?.discardPile?.length - 1;
  const lastDiscard = gameState?.discardPile?.[lastDiscardIndex];
  return {
    ...gameState,
    discardPile: [lastDiscard],
    deck: reverse(gameState?.discardPile.slice(0, lastDiscardIndex)),
  };
};

export const incrementTurn = (gameState: GameState): GameState => {
  const playerCount = getPlayerCount(gameState);
  let nextTurn = gameState?.turn + 1;
  for (let i = 0; i < playerCount; i++) {
    const player = gameState?.playerIds?.[nextTurn % playerCount];
    const isPlaying = gameState?.isPlaying?.[player];

    if (!isPlaying) {
      nextTurn = nextTurn + 1;
    } else {
      break;
    }
  }
  const loop = nextTurn >= playerCount ? gameState?.loop + 1 : gameState?.loop;
  gameState = {
    ...gameState,
    turn: nextTurn % playerCount,
    loop: loop,
    turnState: TurnState.Draw,
    drawnCard: "",
  };
  delete gameState?.lookingAtPlayer;
  delete gameState?.lookingAtIndex;
  return gameState;
};

const draw = (gameState: GameState, self: string): GameState => {
  const emptyHand = gameState?.hands[self].every((card) => card === NOCARD);
  if (emptyHand) {
    return incrementTurn(gameState);
  }
  if (gameState?.turnState !== TurnState.Draw) {
    throw new InvalidMoveError("Cannot draw");
  }
  if (gameState?.deck?.length === 0) {
    throw new InvalidMoveError("Cannot draw if deck is empty");
  }

  const drawn = gameState?.deck?.[gameState?.deck?.length - 1];
  if (getCardAction(drawn) === TurnState.Replace) {
    return {
      ...gameState,
      turnState: TurnState.Replace,
      deck: gameState?.deck?.slice(0, gameState?.deck?.length - 1),
      drawnCard: drawn,
    };
  }
  return {
    ...gameState,
    turnState: TurnState.Drawn,
    deck: gameState?.deck?.slice(0, gameState?.deck?.length - 1),
    drawnCard: drawn,
    slam: {
      slamState: SlamState.NoSlam,
      player: "",
    },
  };
};

export const discardCard = (gameState: GameState, self: string) => {
  const player = gameState?.playerIds[gameState.turn];
  if (gameState?.turnState !== TurnState.Drawn) {
      throw new InvalidMoveError("Can only discard if TurnState is Drawn");
  }
  const drawn = gameState?.drawnCard as string;
  const action = getCardAction(drawn);
  const newState = {
    ...gameState,
    correctSlamDone: false,
    discardPile: [...gameState?.discardPile, drawn],
    turnState: action,
  };
  if (canPlayerMove(newState)) {
    return resetSlam(newState);
  } else {
    newState.previousTurn = {
      turnState: TurnState.NoValidMove,
      player: player,
    };
    return incrementTurn(resetSlam(newState));
  }
};

const replaceWithCardInHand = (gameState: GameState, self: string, index: number): GameState => {
  if (gameState?.turnState !== TurnState.Replace) {
    throw new InvalidMoveError("Cannot replace");
  }
  const hand = [...gameState?.hands[self]];
  if (!hand[index]) {
    throw new InvalidMoveError("Cannot find card to replace");
  }
  if (cardScore(hand[index]) < 0) {
    console.error('Someone replaced a negative score card.');
    console.log('self: ', self);
    console.log('index: ', index);
  }
  const discard = hand[index];
  hand[index] = gameState?.drawnCard;
  return resetSlam(
    incrementTurn({
      ...gameState,
      hands: {
        ...gameState?.hands,
        [self]: hand,
      },
      correctSlamDone: false,
      drawnCard: "",
      discardPile: [...gameState?.discardPile, discard],
      previousTurn: {
        turnState: TurnState.Replace,
        playerIndex: index,
        // selectedCards: [hand[index]],
        player: self,
      },
    })
  );
};

const initiateSlam = (gameState: GameState, self: string): GameState => {
  if (gameState?.drawnCard && gameState?.turnState === TurnState.Replace) {
    throw new InvalidMoveError("Cannot slam when a card is drawn");
  }
  if (gameState?.turnState === TurnState.Drawn) {
    throw new InvalidMoveError("Cannot slam when a turnState is drawn");
  }
  if (gameState?.turnState === TurnState.LookingAtOwn) {
    throw new InvalidMoveError("Cannot slam when any player is looking at own cards");
  }
  if (gameState?.turnState === TurnState.LookingAtOpponents) {
    throw new InvalidMoveError("Cannot slam when any player is looking at opponents cards");
  }
  if (gameState?.failedSlamCount?.[self] >= 1) {
    throw new InvalidMoveError("Cannot slam after one failed slams");
  }
  if (gameState?.correctSlamDone) {
    throw new InvalidMoveError("Cannot slam after correct slam done");
  }
  if (gameState?.deck?.length === 0) {
    throw new InvalidMoveError("Cannot slam if deck is empty");
  }
  if (gameState?.playerRafiki === self) {
    throw new InvalidMoveError("Rafiki caller cannot slam");
  }
  const newState = {
    ...gameState,
    playerSlam: self,
    slam: {
      slamState: SlamState.SlamStart,
      player: self,
      slamDiscard: "",
    } as SlamStart,
  };
  return newState;
};

const slam = (gameState: GameState, self: string, player: string, playerIndex: number): GameState => {
  const value = gameState?.hands?.[player]?.[playerIndex];
  if (self !== gameState?.slam?.player) {
    throw new InvalidMoveError("Only slam player can pick card to slam");
  }
  if (gameState.slam.slamState !== SlamState.SlamStart) {
    throw new InvalidMoveError("Wrong slam state");
  }
  if (value in gameState?.slammedCards) {
    throw new InvalidMoveError("Card was already slammed");
  }
  if (player === gameState?.playerRafiki) {
    throw new InvalidMoveError("Cannot slam Rafiki players card");
  }

  const playerID = player;
  const last = gameState?.discardPile?.length || 0;
  const topDiscard = gameState?.discardPile?.[last - 1];
  const own = self === playerID;

  if (isCorrectSlam(value, topDiscard)) {
    let newDiscards = [...gameState?.discardPile];
    const playerHand = [...gameState?.hands?.[playerID]];

    let slammedCards: { [p: string]: boolean };
    newDiscards.push(value);
    const index = playerHand.indexOf(value);
    playerHand[index] = NOCARD;
    let slam;
    if (own) {
      slam = {
        slamState: SlamState.SlamFinishSuccessOwn,
        player: self,
        slammedCardIndex: playerIndex,
        success: true,
        slamDiscard: topDiscard,
      } as SlamFinishSuccessOwn;
    } else {
      slam = {
        slamState: SlamState.SlamSuccessOpponent,
        player: self,
        opponent: player,
        slammedCardIndex: playerIndex,
        success: true,
        slamDiscard: topDiscard,
      } as SlamSuccessOpponent;
    }
    slammedCards = {
      ...gameState?.slammedCards,
      [value]: true,
    };
    return {
      ...gameState,
      slammedCards: slammedCards,
      hands: {
        ...gameState?.hands,
        [playerID]: playerHand,
      },
      discardPile: newDiscards,
      correctSlamDone: true,
      slam: slam,
    };
  } else {
    const nextSlamState = own ? SlamState.SlamFailureOwn : SlamState.SlamFailureOpponent;
    return {
      ...gameState,
      slam: {
        ...gameState?.slam,
        slamState: nextSlamState,
        player: self,
        opponent: player,
        slammedCardIndex: playerIndex,
        success: false,
        slamDiscard: topDiscard,
      },
    };
  }
};

export const finishIncorrectSlam = (gameState: GameState, self: string): GameState => {
  if (
    gameState?.slam?.slamState !== SlamState.SlamFailureOwn &&
    gameState?.slam?.slamState !== SlamState.SlamFailureOpponent
  ) {
    throw new InvalidMoveError("Cannot finish incorrect slam");
  }
  const last = gameState?.discardPile?.length || 0;
  const topDiscard = gameState?.discardPile?.[last - 1];
  const topCard = gameState.deck?.[gameState?.deck?.length - 1] as string;
  const hand = [...gameState.hands[self]];
  const index = gameState.hands[self].indexOf(NOCARD);
  let newDeck = gameState?.deck;
  if (index >= 0) {
    hand[index] = topCard;
    newDeck = gameState?.deck?.slice(0, gameState?.deck?.length - 1);
  } else {
    console.error("Incorrect slam penalty failed");
  }

  if (gameState?.slam?.slamState === SlamState.SlamFailureOwn) {
    return moveDiscardsToDeck({
      ...gameState,
      deck: newDeck,
      hands: {
        ...gameState?.hands,
        [self]: hand,
      },
      failedSlamCount: {
        ...gameState?.failedSlamCount,
        [self]: gameState?.failedSlamCount?.[self] + 1,
      },
      slam: {
        ...gameState?.slam,
        slamState: SlamState.SlamFinishFailureOwn,
        player: self,
        slammedCardIndex: gameState?.slam?.slammedCardIndex,
        penaltyIndex: index,
        success: false,
        slamDiscard: topDiscard,
      },
    });
  } else {
    return moveDiscardsToDeck({
      ...gameState,
      deck: newDeck,
      hands: {
        ...gameState?.hands,
        [self]: hand,
      },
      failedSlamCount: {
        ...gameState?.failedSlamCount,
        [self]: gameState?.failedSlamCount?.[self] + 1,
      },
      slam: {
        ...gameState?.slam,
        slamState: SlamState.SlamFinishFailureOpponent,
        player: self,
        opponent: gameState?.slam?.opponent,
        slammedCardIndex: gameState?.slam?.slammedCardIndex,
        penaltyIndex: index,
        success: false,
        slamDiscard: topDiscard,
      },
    });
  }
};

export const finishCorrectSlamOpponent = (gameState: GameState, self: string, selfIndex: number): GameState => {
  if (gameState?.slam?.slamState !== SlamState.SlamSuccessOpponent) {
    throw new InvalidMoveError("Cannot finish correct slam opponent");
  }
  const value = gameState?.hands?.[self]?.[selfIndex];

  const cardIndex = gameState?.hands[self].indexOf(value);
  const playerHand = [...gameState?.hands[self]];
  playerHand[cardIndex] = NOCARD;

  const opponent = gameState?.slam?.opponent;
  if (!opponent) {
    return gameState;
  }

  const opponentHand = [...gameState?.hands[opponent]];

  let opponentIndex;
  if (gameState?.hands[opponent][gameState?.slam?.slammedCardIndex] === NOCARD) {
    opponentIndex = gameState?.slam?.slammedCardIndex;
  } else {
    opponentIndex = gameState?.hands[opponent].indexOf(NOCARD);
  }
  opponentHand[opponentIndex] = value;

  return {
    ...gameState,
    hands: {
      ...gameState?.hands,
      [opponent]: opponentHand,
      [self]: playerHand,
    },
    slam: {
      ...gameState?.slam,
      slamState: SlamState.SlamFinishSuccessOpponent,
      discardIndex: selfIndex,
      receiveIndex: opponentIndex,
    },
  };
};

export const swapCards = (
  gameState: GameState,
  self: string,
  selfIndex: number,
  opponent: string,
  opponentIndex: number
) => {
  const player = gameState?.playerIds[gameState.turn];
  if (gameState?.turnState !== TurnState.SwapWithOpponent) {
    throw new InvalidMoveError("Cannot swap cards");
  }
  if (self === opponent) {
    throw new InvalidMoveError("Player and opponent cannot be same");
  }
  if (opponent === gameState?.playerRafiki) {
    throw new InvalidMoveError("Cannot swap with Rafiki caller");
  }
  if (!isNumber(selfIndex) || !isNumber(opponentIndex)) throw new InvalidMoveError("Tried to swap with non player");
  const newGameState = gameState;
  const value1 = gameState?.hands?.[self]?.[selfIndex];
  const value2 = gameState?.hands?.[opponent]?.[opponentIndex];
  newGameState.hands[self][selfIndex] = value2;
  newGameState.hands[opponent][opponentIndex] = value1;
  return incrementTurn(clearSlam({
    ...newGameState,
    previousTurn: {
      turnState: TurnState.SwapWithOpponent,
      // selectedCards: [value1, value2],
      opponent: opponent,
      opponentIndex: opponentIndex,
      playerIndex: selfIndex,
      player,
    },
  }));
};

export const startPeekingOwn = (gameState: GameState, self: string, index: number): GameState => {
  if (gameState?.turnState !== TurnState.LookAtOwn) {
    throw new InvalidMoveError("Cannot start looking at own card");
  }
  return {
    ...gameState,
    turnState: TurnState.LookingAtOwn,
    lookingAtIndex: index
  };
};

export const finishPeekingOwn = (gameState: GameState, self: string, index: number): GameState => {
  if (gameState?.turnState !== TurnState.LookingAtOwn) {
    throw new InvalidMoveError("Cannot finish looking at own card");
  }
  if (gameState?.lookingAtIndex !== index) {
    throw new InvalidMoveError("Incorrect card to stop looking at");
  }
  const player = gameState?.playerIds[gameState.turn];
  return incrementTurn(clearSlam({
    ...gameState,
    previousTurn: {
      turnState: TurnState.LookAtOwn,
      playerIndex: index,
      player,
    },
  }));
};

export const startPeekingOpponent = (
    gameState: GameState,
    self: string,
    playerId: string,
    index: number
): GameState => {
  if (gameState?.turnState !== TurnState.LookAtOpponents) {
    throw new InvalidMoveError("Cannot look at opponents card");
  }
  if (gameState?.playerRafiki === playerId) {
    throw new InvalidMoveError("Cannot look at Rafiki callers card");
  }
  return {
    ...gameState,
    turnState: TurnState.LookingAtOpponents,
    lookingAtIndex: index,
    lookingAtPlayer: playerId
  };
};

export const finishPeekingOpponent = (
  gameState: GameState,
  self: string,
  playerId: string,
  index: number
): GameState => {
  if (gameState?.turnState !== TurnState.LookingAtOpponents) {
    throw new InvalidMoveError("Cannot look at opponents card");
  }
  if (gameState?.playerRafiki === playerId) {
    throw new InvalidMoveError("Cannot look at Rafiki callers card");
  }
  if (gameState?.lookingAtIndex !== index) {
    throw new InvalidMoveError("Incorrect card index to stop looking at");
  }
  if (gameState?.lookingAtPlayer !== playerId) {
    throw new InvalidMoveError("Incorrect card to stop looking at");
  }
  const player = gameState?.playerIds[gameState.turn];
  return incrementTurn(clearSlam({
    ...gameState,
    previousTurn: {
      turnState: TurnState.LookAtOpponents,
      opponent: playerId,
      player,
      opponentIndex: index,
    },
  }));
};

export const callRafiki = (gameState: GameState, self: string) => {
  if (gameState?.playerRafiki) {
    throw new InvalidMoveError("Rafiki is already called");
  }
  if (gameState?.turnState !== TurnState.Draw) {
    throw new InvalidMoveError("Cannot call Rafiki");
  }
  const hand = [...gameState.hands[self]];
  const isEmpty = hand.every(card => card === NOCARD);

  if (gameState?.loop < 3 && !isEmpty) {
    throw new InvalidMoveError("Cannot call Rafiki before 3 turns");
  }

  return incrementTurn({
    ...gameState,
    playerRafiki: self,
    lastTurn: gameState?.turn,
    lastLoop: gameState?.loop + 1,
    previousTurn: {
      turnState: TurnState.CallRafiki,
      player: gameState?.playerIds[gameState.turn],
    },
  });
};

export const ready = (gameState: GameState, self: string) => {
  const lastActivity: any = {};
  gameState?.playerIds.forEach((player) => {
    lastActivity[player] = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  });
  return {
    ...gameState,
    lastActivity: lastActivity,
    ready: {
      ...gameState.ready,
      [self]: true,
    },
  };
};

// 0, 1, 2, 3
export const leaveGame = (gameState: GameState, self: string) => {
  let turn = gameState?.turn;
  let lastTurn = gameState?.lastTurn;
  if (gameState?.playerIds[gameState?.turn] === self && gameState?.turn === gameState?.playerIds?.length - 1) {
    turn = 0;
  }
  if (lastTurn === gameState?.playerIds?.length - 1) {
    lastTurn = lastTurn - 1;
  }
  let slam = gameState?.slam;
  if (gameState?.slam?.player === self) {
    slam = {
      slamState: SlamState.NoSlam,
      player: "",
    };
  }
  const ready = {...gameState.ready};
  delete ready[self];
  const isPlaying = {...gameState.isPlaying};
  delete isPlaying[self];
  const newGameState = {
    ...gameState,
    turn: turn,
    lastTurn: lastTurn,
    allPlayerIds: gameState?.allPlayerIds.filter((x) => x !== self),
    leftPlayerIds: [...gameState?.leftPlayerIds, self],
    playerIds: gameState?.playerIds.filter((x) => x !== self),
    slam: slam,
    ready: ready,
    isPlaying: isPlaying,
    previousTurn: {
      turnState: TurnState.PlayerLeft,
      player: self,
    } as PreviousPlayerLeft,
  };
  return newGameState;
};

export const isSlamInProgress = (gameState: GameState) => {
  return [
    SlamState.SlamStart,
    SlamState.SlamFailureOwn,
    SlamState.SlamFailureOpponent,
    SlamState.SlamSuccessOpponent,
  ].includes(gameState?.slam?.slamState);
};

export const advanceTurnAfterTimeout = (gameState: GameState) => {
  let hands = gameState?.hands;
  let newDeck = gameState?.deck;
  if (gameState?.playerRafiki) {
    const playerId = gameState?.playerIds?.[gameState?.turn];
    const hand = gameState?.hands?.[playerId];
    const index = hand.indexOf(NOCARD);
    const penaltyCard = gameState?.deck?.[gameState?.deck?.length - 1];
    const botMemory = gameState?.botMemory;
    if (penaltyCard && index >= 0) {
      hand[index] = penaltyCard;
      hands = {
        ...gameState?.hands,
        [playerId]: hand,
      };
      newDeck = gameState?.deck?.slice(0, gameState?.deck?.length - 1);

      const botIds = Object.keys(gameState?.bots);
      botIds.forEach((botId: string) => {
        botMemory[botId][playerId][index] = UNKNOWN;
      });
    }
  }
  let newDiscardPile = gameState?.discardPile;
  if (gameState?.drawnCard) {
    // Add it to the beginning so that the slam card doesnt change.
    newDiscardPile = [gameState.drawnCard, ...gameState.discardPile];
  }
  return incrementTurn({
    ...gameState,
    deck: newDeck,
    hands: hands,
    discardPile: newDiscardPile,
    drawnCard: '',
    previousTurn: {
      turnState: TurnState.Timeout,
      player: gameState?.playerIds?.[gameState.turn] || "",
    },
  });
};

const finishRound = (gameState: GameState, self: string) => {
  if (isSlamInProgress(gameState)) {
    return gameState;
  }
  if (!areGameRoundTurnsOver(gameState)) {
    return gameState;
  }
  if (gameState?.turnState === TurnState.RoundOver) {
    return gameState;
  }
  if (!gameState.roundId) {
    console.error('Game does not have a round id');
    return gameState;
  }
  return {
    ...gameState,
    finishedRounds: [gameState?.roundId, ...gameState.finishedRounds],
    turnState: TurnState.RoundOver,
  };
};

const validateGameState = (gameState: GameState, move: Move) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  let allCards = [...gameState?.deck , ...gameState?.discardPile];
  Object.keys(gameState?.hands).forEach(player => {
    allCards = allCards.concat(gameState?.hands?.[player].filter((card) => card !== NOCARD));
  });
  if (gameState?.drawnCard && [TurnState.Drawn, TurnState.Replace].includes(gameState?.turnState)) {
    allCards.push(gameState?.drawnCard);
  }
  if (allCards.length !== 54) {
    const e = new Error(`Cards do not total up to 54 ${JSON.stringify(move, null, 4)}`);
    reportError(e);
  }
  if (Set) {
    if (new Set(allCards).size !== allCards.length) {
      const e = new Error(`Cards have duplicates ${JSON.stringify(move, null, 4)}`);
      reportError(e);
    }
  }
  const allCardsInDeck = allCards.some((card) => card in deck);
  if (!allCardsInDeck) {
    const e = new Error(`Non standard cards detected ${JSON.stringify(move, null, 4)}`);
    reportError(e);
  }
  if (Object.keys(gameState.isPlaying).length === 0) {
    const e = new Error(`There are no players playing ${JSON.stringify(move, null, 4)}`);
    reportError(e);
  }

  const bots = Object.keys(gameState.bots);
  bots.forEach((botId: string) => {
    if (!gameState?.isPlaying?.[botId]) {
      const e = new Error(`Bot ${botId} got timed out`);
      reportError(e);
    }
    if (gameState?.playerStatus?.[botId] !== PlayerStatus.Approved) {
      const e = new Error(`Bot ${botId} is not approved`);
      reportError(e);
    }
    if (gameState?.leftPlayerIds?.includes(botId)) {
      const e = new Error(`Bot ${botId} left the game`);
      reportError(e);
    }
  });

  if (!isInteger(gameState?.turn)) {
    const e = new Error(`Turn is not an integer`);
    reportError(e);
  }
  if (gameState?.turn >= gameState?.playerIds?.length) {
    const e = new Error(`Turn is greater than number of players`);
    reportError(e);
  }
};

export const gameReducerHelper = (gameState: GameState, move: Move) => {
  const slamInProgress = isSlamInProgress(gameState);

  if (gameState.turnState === TurnState.RoundOver) {
    return gameState;
  }

  if (gameState.paused) {
    return gameState;
  }

  if (move.type === MoveType.Ready) {
    return ready(gameState, move.self);
  }

  if (move.type === MoveType.FinishRound) {
    return finishRound(gameState, move.self);
  }

  const everyoneReady = gameState.playerIds.every((player) => gameState.ready[player]);
  if (!everyoneReady) {
    return gameState;
  }

  const player = gameState?.playerIds[gameState.turn];
  if (move.type === MoveType.LeaveGame) {
    // if (slamInProgress && move.self === gameState?.slam?.player) return  gameState;
    return leaveGame(gameState, move.self);
  }
  if (player === move.self && !slamInProgress) {
    if (move.type === MoveType.Draw) {
      return draw(gameState, move.self);
    }
    if (move.type === MoveType.Discard) {
      return discardCard(gameState, move.self);
    }
    if (move.type === MoveType.Replace) {
      return replaceWithCardInHand(gameState, move.self, move.selfIndex);
    }
    if (move.type === MoveType.SwapWithOpponent) {
      return swapCards(gameState, move.self, move.selfIndex, move.opponent, move.opponentIndex);
    }
    if (move.type === MoveType.StartLookAtOwn) {
      return startPeekingOwn(gameState, move.self, move.selfIndex);
    }
    if (move.type === MoveType.FinishLookAtOwn) {
      return finishPeekingOwn(gameState, move.self, move.selfIndex);
    }
    if (move.type === MoveType.StartLookAtOpponents) {
      return startPeekingOpponent(gameState, move.self, move.opponent, move.opponentIndex);
    }
    if (move.type === MoveType.FinishLookAtOpponents) {
      return finishPeekingOpponent(gameState, move.self, move.opponent, move.opponentIndex);
    }
    if (move.type === MoveType.CallRafiki) {
      return callRafiki(gameState, move.self);
    }
  }
  if (move.type === MoveType.Slam && !slamInProgress) {
    return initiateSlam(gameState, move.self);
  }
  if (gameState?.slam?.player === move.self) {
    if (move.type === MoveType.PickSlamCard) {
      return slam(gameState, move.self, move.player, move.playerIndex);
    }
    if (move.type === MoveType.FinishIncorrectSlam) {
      return finishIncorrectSlam(gameState, move.self);
    }
    if (move.type === MoveType.FinishCorrectSlamOpponent) {
      return finishCorrectSlamOpponent(gameState, move.self, move.selfIndex);
    }
  }
  if (player !== move.self) {
    throw new InvalidMoveError(`Attempt to move out of turn ${JSON.stringify(move, null, 4)}`);
  }
  if (slamInProgress) {
    throw new InvalidMoveError(`Attempt to move when slam in progress ${JSON.stringify(move, null, 4)}`);
  }
  throw new InvalidMoveError(`Invalid move in Rafiki reducer ${JSON.stringify(move, null, 4)}`);
};

export const gameReducer = (gameState: GameState, move: Move) => {
  const newGameState = gameReducerHelper(gameState, move);
  // Prevent bugs where cards are duplicated in the discard pile
  newGameState.discardPile = uniq(newGameState.discardPile);
  validateGameState(newGameState, move);
  return newGameState;
};
