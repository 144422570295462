// This file is duplicated in frontend and backend till a way to share ts code is found
// Any changes made here should be copied to both places

import sortBy from 'lodash/sortBy';
const RAFIKI_PENALTY = 10;
export const NOCARD = "NOCARD";

export enum PlayerStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Denied = 'Denied',
    Limbo = 'Limbo',
}

export const sum = (arr: Array<number>) => {
    return arr.reduce((x, acc) => {
        return x + acc;
    }, 0);
};

export interface Game {
    roomCode: string;
    rounds: number;
    playerCount: number;
    scores: any;
    winners: string[];
}

export const cardScore = (card: string): number => {
    const number = card.slice(0, -1);
    if (card === NOCARD) {
        return 0;
    } else if (card === "KH" || card === "KD" || card === "XJ" || card === "YJ") {
        return -1;
    } else if (number === "A") {
        return 1;
    } else if ("JQK".includes(number)) {
        return 10;
    } else {
        return Number(number);
    }
};

export const handScore = (hand: Array<string>): number => {
    if (!hand) {
        return 0;
    }
    const values = hand.map(cardScore);
    return sum(values);
};
export const getScores = (round: any) => {
    const playerIds = Object.keys(round?.hands);
    let scoreboard = sortBy(
        playerIds.map((playerId) => {
            return {
                id: playerId,
                roundScore: handScore(round?.hands[playerId]),
            };
        }),
        "roundScore"
    );
    const minScore = scoreboard?.[0].roundScore;
    const tied = minScore === scoreboard?.[1]?.roundScore;
    scoreboard = scoreboard.map((player: any) => {
        if (player?.id === round.playerRafiki && (player.roundScore !== minScore || tied)) {
            return {
                ...player,
                roundScore: player.roundScore + RAFIKI_PENALTY,
            };
        }
        return player;
    });
    return scoreboard;
};

export const sumRoundScores = (roundScores: any[]): any[] => {
    const scoreMap: any = {};
    roundScores.forEach((scores: any) => {
        for (const player of scores) {
            if (!(player.id in scoreMap)) {
                scoreMap[player.id] = {
                    id: player.id,
                    score: 0,
                };
            }
            scoreMap[player.id].score += player.roundScore;
        }
    });

    const result = Object.keys(scoreMap).map((playerId) => {
        return {
            ...scoreMap[playerId],
        };
    });
    return result;
};

export const getTotalPlayerCount = (gameState: any) => {
    return gameState?.playerIds.length;
};

export const getPlayerIdsForScoring = (gameState: any) => {
    return [...gameState?.allPlayerIds?.filter((playerId: string) => {
        return gameState?.playerStatus?.[playerId] === PlayerStatus.Approved && !(playerId in gameState?.bots);
    }), gameState?.leftPlayerIds]
};

export const squareAbsValue = (x: number) => {
    if (x < 0) {
        return -1 * Math.pow(x, 2);
    }
    return Math.pow(x, 2);
};

// https://docs.google.com/spreadsheets/d/1CrqNJQ6DKlrLwDDIHVUHpCtGB0rZBzwbd19_j_umdsY/edit?usp=sharing
export const getLeaderBoardEntry = (gameDocs: Game[], playerId: string, playerName: string) => {
    const plays = gameDocs.length;
    const countWeights: { [key: number]: number } = {
        2: 1,
        3: Math.sqrt(1.1),
        4: Math.sqrt(1.2),
        5: Math.sqrt(1.3),
        6: Math.sqrt(1.4),
    };
    const roundWeights: { [key: number]: number } = {
        1: Math.sqrt(0.25),
        3: Math.sqrt(0.5),
        5: 1,
        10: Math.sqrt(1.25),
    };
    const numerator = squareAbsValue(
        sum(
            gameDocs.map((game) => {
                let result = 0;
                if (game?.winners?.length === 1 && game?.winners?.includes(playerId)) {
                    result = 1;
                } else {
                    result = -0.05;
                }
                if (game.playerCount in countWeights && game.rounds in roundWeights) {
                    result = result * countWeights[game.playerCount] * roundWeights[game.rounds];
                }
                return result;
            })
        )
    );
    const diff = plays === 0 ? 0 : numerator / Math.pow(plays, 1.1);
    const scale = 90;
    const score = 1500 + Math.round(scale * diff);

    const wins = gameDocs.filter((game: Game) => game?.winners?.includes(playerId) && game?.winners?.length === 1).length;
    return {
        wins,
        plays,
        score,
        name: playerName,
    };
};

