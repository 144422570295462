import * as cards from "./assets/cards";
import {IDeck} from "./constants";

export const cardBack = cards.back;
export const cardRedBack = cards.redBack;

export const deck: IDeck = {
    AS: cards.spadesA,
    "2S": cards.spades2,
    "3S": cards.spades3,
    "4S": cards.spades4,
    "5S": cards.spades5,
    "6S": cards.spades6,
    "7S": cards.spades7,
    "8S": cards.spades8,
    "9S": cards.spades9,
    "10S": cards.spades10,
    JS: cards.spadesJ,
    QS: cards.spadesQ,
    KS: cards.spadesK,
    AH: cards.heartsA,
    "2H": cards.hearts2,
    "3H": cards.hearts3,
    "4H": cards.hearts4,
    "5H": cards.hearts5,
    "6H": cards.hearts6,
    "7H": cards.hearts7,
    "8H": cards.hearts8,
    "9H": cards.hearts9,
    "10H": cards.hearts10,
    JH: cards.heartsJ,
    QH: cards.heartsQ,
    KH: cards.heartsK,
    AD: cards.diamondsA,
    "2D": cards.diamonds2,
    "3D": cards.diamonds3,
    "4D": cards.diamonds4,
    "5D": cards.diamonds5,
    "6D": cards.diamonds6,
    "7D": cards.diamonds7,
    "8D": cards.diamonds8,
    "9D": cards.diamonds9,
    "10D": cards.diamonds10,
    JD: cards.diamondsJ,
    QD: cards.diamondsQ,
    KD: cards.diamondsK,
    AC: cards.clubsA,
    "2C": cards.clubs2,
    "3C": cards.clubs3,
    "4C": cards.clubs4,
    "5C": cards.clubs5,
    "6C": cards.clubs6,
    "7C": cards.clubs7,
    "8C": cards.clubs8,
    "9C": cards.clubs9,
    "10C": cards.clubs10,
    JC: cards.clubsJ,
    QC: cards.clubsQ,
    KC: cards.clubsK,
    XJ: cards.joker,
    YJ: cards.redJoker,
};

