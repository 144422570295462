import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import GameView from "./GameView";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import CreateGame from "./CreateGame";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";

// Initialize Firestore
import { initializeFirebase } from "./components/Firestore";
import ResetPasswordLink from "./ResetPasswordLink";
import ChangePassword from "./ChangePassword";
import FullPageLoading from "./components/FullPageLoading";
import { initializeAudio, initializeErrorReporting } from "./utils";
initializeFirebase();
initializeAudio();
initializeErrorReporting();

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.NONE)
  .then(() => {});

const Landing = lazy(() => import(/* webpackChunkName: "Landing" */ "./Landing"));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ "./Login"));
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ "./Signup"));
const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "PrivacyPolicy" */ "./components/PrivacyPolicy"));
const CookiePolicy = lazy(() => import(/* webpackChunkName: "CookiePolicy" */ "./components/CookiePolicy"));
const ShortPrivacyPolicy = lazy(
  () => import(/* webpackChunkName: "ShortPrivacyPolicy" */ "./components/ShortPrivacyPolicy")
);
const Attribution = lazy(() => import(/* webpackChunkName: "Attribution" */ "./components/Attribution"));
const PolicyList = lazy(() => import(/* webpackChunkName: "PolicyList" */ "./components/PolicyList"));
const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ "./Admin"));
const EditAd = lazy(() => import(/* webpackChunkName: "EditAd" */ "./EditAd"));
const ListAds = lazy(() => import(/* webpackChunkName: "ListAds" */ "./ListAds"));
const ChangeUsername = lazy(() => import(/* webpackChunkName: "ChangeUsername" */ "./ChangeUsername"));

const routing = (
  <React.StrictMode>
    <DndProvider
      backend={TouchBackend}
      options={{
        enableMouseEvents: true,
      }}
    >
      <Provider store={store}>
        <Router>
          <Suspense fallback={<FullPageLoading />}>
            <div>
              {/* Add these routes to firebase.json so that direct navigation works*/}
              <Route exact path="/" component={Landing} />
              <Route path="/login" component={Login} />
              <Route path="/game/:roomCode" component={GameView} />
              <Route path="/create" component={CreateGame} />
              <Route path="/signup" component={Signup} />
              <Route path="/admin/list-ads" component={ListAds} />
              <Route path="/admin/create-ad" component={EditAd} />
              <Route path="/admin" component={Admin} />
              <Route path="/forgot-password" component={ResetPasswordLink} />
              <Route path="/change-username" component={ChangeUsername} />
              <Route path="/change-password/:uid/:token" component={ChangePassword} />
              <Route path="/cookie-policy" component={CookiePolicy} />
              <Route path="/privacy-notice" component={ShortPrivacyPolicy} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/attribution" component={Attribution} />
              <Route path="/policies" component={PolicyList} />
            </div>
          </Suspense>
        </Router>
      </Provider>
    </DndProvider>
  </React.StrictMode>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
