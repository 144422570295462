import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {mobileWidth, sidebarOpenOffset, sidebarWidth, tabletWidth} from "../layout";
import { useSelector } from "react-redux";
import { isSidebarOpen } from "../redux/selectors";
import { Link, OutlineButton } from "./StyledComponents";
import {COOKIES_ENABLED} from "../constants";
import {isInsideIframe, verifyCookieSupport} from "../utils";
import cookies from 'browser-cookies';
import useMountedState from "react-use/lib/useMountedState";
import * as firebase from "firebase/app";
import "firebase/auth";

interface MinimiseProps {
  minimise: boolean;
}

interface ContainerProps {
  visible: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  bottom: 0;
  text-align: center;
  background: #fecf6f;
  width: 100%;
  z-index: 200;
  opacity: 1;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transform: translateY(100%);
  ${({ visible }) => visible && `
    opacity: 1;
    transform: translateY(0);
  `}
  @media (max-width: ${tabletWidth}px) {
    text-align: center;
  }
`;

const Wrapper = styled.div<MinimiseProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2c2f3d;
  padding: 15px;
  width: calc(100vw - ${sidebarWidth}px);
  transition: transform 0.2s ease;
  transform: ${({ minimise }) => minimise && `translateX(${sidebarOpenOffset}px)`};
  @media (max-width: ${tabletWidth}px) {
    width: 100%;
    flex-direction: column;
    transform: translateX(0);
  }
  @media (max-width: ${mobileWidth}px) {
    padding: 10px;
  }
`;

const DarkLink = styled(Link)`
  color: #2c2f3d;
`;

const Button = styled(OutlineButton)`
  background: #2c2f3d;
  &:hover {
    background: #2c2f3d;
    opacity: 0.9;
  }
`;

const Text = styled.div`
  margin-right: 15px;
  font-size: 0.9rem;
  @media (max-width: ${tabletWidth}px) {
    margin-bottom: 15px;
  }
  @media (max-width: ${mobileWidth}px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
`;

function CookiePopup() {
  const minimise = !useSelector(isSidebarOpen);
  const [accepted, setAccepted] = useState(true);
  const [visible, setVisible] = useState(false);
  const isMounted = useMountedState();
  useEffect(() => {
    verifyCookieSupport().then((value) => {
      if (value) {
        isMounted() && setAccepted(true);
      } else {
        isMounted() && setAccepted(false);
        setTimeout(() => {
          isMounted() && setVisible(true);
        }, 1000);
      }
    });
  }, []);
  if (accepted) {
    return null;
  }
  if (isInsideIframe()) {
    return null;
  }
  return (
    <Container visible={visible}>
      <Wrapper minimise={minimise}>
        <Text>
          We use cookies to offer you a better browsing experience. Cookies enhance site navigation, analyse site usage,
          and assist in our marketing efforts. By clicking “Accept cookies,” you agree to the storing of cookies on your
          device and you can change these settings any time. For more information please visit our{" "}
          <DarkLink to={"/privacy-notice"}>Privacy Policy</DarkLink> and{" "}
          <DarkLink to={"/cookie-policy"}>Cookie Policy</DarkLink>.
        </Text>
        <div>
          <Button
            onClick={async () => {
              cookies.set(COOKIES_ENABLED, 'true', {expires: 365 * 10 });
              await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
              setVisible(false);
              setTimeout(() => {
                isMounted() && setAccepted(true);
              }, 1000);
            }}
          >
            Accept Cookies
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
}

export default CookiePopup;
