import React, { useRef, useState } from "react";
import styled from "styled-components";
import useClickAway from "react-use/lib/useClickAway";
import { mobileWidth } from "../layout";

const OptionText = styled.span`
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 28px;
`;

const LightText = styled.span`
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  @media (max-width: ${mobileWidth}px) {
    display: none;
  }
`;

const Input = styled.input`
  background-color: #202231;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 10px;
  padding: 6px 8px;
  border: none;
`;

interface Props {
  bot: {
    name: string;
  };
  onChange: Function;
}
function BotName(props: Props) {
  const bot = props.bot;
  const ref = useRef(null);
  const [text, setText] = useState(bot.name);

  const [editing, setEditing] = useState(false);
  const stopEditing = () => {
    if (editing) {
      props.onChange({
        ...bot,
        name: text,
      });
    }
    setEditing(false);
  };
  const startEditing = () => {
    setEditing(true);
  };
  useClickAway(ref, stopEditing);

  return (
    <span ref={ref}>
      {editing ? (
        <Input
          type={"text"}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          onFocus={(e) => {
            e.target.select();
          }}
        />
      ) : (
        <OptionText onClick={startEditing}>{text}</OptionText>
      )}
      &nbsp;
      {!editing && <LightText onClick={startEditing}>Click to rename</LightText>}
    </span>
  );
}

export default BotName;
