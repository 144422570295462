import React, { useState } from "react";
import {
  FormLabel,
  LargeLogo,
  FormButton,
  Main,
  Subtitle,
  TextField,
  CTA,
  FormError,
  Container, AuthPageContainer,
} from "./components/StyledComponents";
import logo from "./assets/rafiki-logo_red.svg";
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import { useSelector } from "react-redux";
import { isSidebarOpen } from "./redux/selectors";
import useMountedState from "react-use/lib/useMountedState";
import Page from "./components/Page";
import Footer from "./components/Footer";
import {useHistory} from "react-router";
import {Helmet} from "react-helmet";
import {verifyCookieSupport} from "./utils";

function ChangePassword(props: any) {
  const token = props.match.params.token;
  const uid = decodeURIComponent(props.match.params.uid);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const minimise = !useSelector(isSidebarOpen);

  let mounted = useMountedState();

  return (
    <Page>
      <Main>
        <Helmet>
          <title>Rafiki - Change Password</title>
          <meta name={'description'} content={"Play with friends, win with masters"}/>
        </Helmet>
        <Container minimise={minimise}>
          <AuthPageContainer>
            <LargeLogo src={logo} alt="rakifi-logo" width={488} height={241} />
            <Subtitle>Set a new password</Subtitle>
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                if (password !== password2) {
                  setError("Passwords don't match");
                  return;
                }
                setLoading(true);
                const resetPassword = firebase.app().functions("europe-west2").httpsCallable("resetPassword");
                verifyCookieSupport()
                  .then((value) => {
                    if (!value) {
                      setError("Cookies are disabled");
                    }
                    return resetPassword({
                      uid,
                      password,
                      token,
                    });
                  })
                  .then((result: any) => {
                    return firebase.auth().signInWithCustomToken(result?.data?.token);
                  })
                  .then(() => {
                    mounted() && setLoading(false);
                    history.push("/create");
                  })
                  .catch(function (error) {
                    const errorMessage = error.message;
                    if (mounted()) {
                      setError(errorMessage);
                      setLoading(false);
                    }
                  });
              }}
            >
              <FormLabel>New password</FormLabel>
              <TextField
                type={"password"}
                onChange={(e: React.BaseSyntheticEvent) => setPassword(e.target.value)}
                required
              />
              <FormLabel>New password again</FormLabel>
              <TextField
                type={"password"}
                onChange={(e: React.BaseSyntheticEvent) => setPassword2(e.target.value)}
                required
              />
              <FormError>{error}</FormError>
              <CTA>
                <FormButton type={"submit"} disabled={loading}>
                  Save new password
                </FormButton>
              </CTA>
            </form>
          </AuthPageContainer>
          <Footer />
        </Container>
      </Main>
    </Page>
  );
}

export default ChangePassword;
