import { BotSpeed, PlayerMap, State } from "./types";
import {
  Card,
  ModalID,
  NOCARD,
  Player,
  RAFIKI_PENALTY,
  Score,
  SlamState,
  StateDescriptions,
  TurnState,
} from "../constants";
import {combineWithAnd, getCardAction, getPrettyCardDesc, sum} from "../utils";
import sortBy from "lodash/sortBy";
import isUndefined from "lodash/isUndefined";
import { areGameRoundTurnsOver } from "./gameSelectors";
import { PlayerStatus } from "../common";

export const getHandForPlayer = (state: State, playerID: string) => {
  return state.gameState?.hands ? state.gameState?.hands[playerID] : [];
};

export const getGameState = (state: State) => state.gameState;
export const getRoomCode = (state: State) => state.roomCode;
export const getTurnState = (state: State) => state?.gameState?.turnState;
export const getSlamState = (state: State) => state?.gameState?.slam?.slamState;
export const getOwner = (state: State) => state.owner;
export const getSelf = (state: State) => state.self;
export const getSelectedCards = (state: State) => state.selectedCards;

export const getPlayerMap = (state: State) => state.players;

export const getPlayers = (state: State) => {
  const result: PlayerMap = {};
  const combinedPlayerIds = [...state.gameState?.allPlayerIds, ...state.gameState?.leftPlayerIds];
  combinedPlayerIds.forEach((pid) => {
    if (pid in state?.gameState?.bots && state?.gameState?.bots?.[pid]) {
      result[pid] = state?.gameState?.bots[pid] as Player;
    } else {
      if (state?.players?.[pid]?.name) {
        result[pid] = state.players[pid];
      }
    }
  });
  return result;
};

function snackbarSwapWithOpponent(previousPlayer: Player, self: string, opponent?: Player) {
  if (previousPlayer?.id === self) {
    return `You swapped one of your cards with one of ${opponent?.name}'s cards.`;
  } else if (opponent?.id === self) {
    return `${previousPlayer?.name} swapped one of their cards with yours.`;
  } else {
    return `${previousPlayer?.name} swapped one of their cards with one of ${opponent?.name}'s cards.`;
  }
}

export const snackbarSlamMessage = (state: State) => {
  const slamState = getSlamState(state);
  const mySlam = isMySlam(state);
  const slamPlayer = getSlamPlayer(state);
  if (slamState === SlamState.SlamStart) {
    if (mySlam) {
      return "You chose to SLAM! Choose your SLAM CARD now!";
    } else {
      return `${slamPlayer?.name} chose to SLAM!`;
    }
  }

  if (slamState === SlamState.SlamSuccessOpponent) {
    if (mySlam) {
      // TODO opponent name
      return "Your Slam was correct! Pick a card to discard to transfer to your opponent.";
    } else {
      return `${slamPlayer?.name}'s Slam was correct!`;
    }
  }
  if (slamState === SlamState.SlamTimeout) {
    if (mySlam) {
      return "You took too long to Slam.";
    } else {
      return `${slamPlayer?.name} took too long to Slam.`;
    }
  }
};

export const snackbarSlamFinishMessage = (state: State) => {
  const slamState = getSlamState(state);
  const mySlam = isMySlam(state);
  const slamPlayer = getSlamPlayer(state);

  if (
    slamState === SlamState.SlamFailureOwn ||
    slamState === SlamState.SlamFinishFailureOwn ||
    slamState === SlamState.SlamFailureOpponent ||
    slamState === SlamState.SlamFinishFailureOpponent
  ) {
    if (mySlam) {
      return `You have slammed incorrectly!`;
    } else {
      return `${slamPlayer?.name}'s Slam was incorrect!`;
    }
  }

  if (slamState === SlamState.SlamFinishSuccessOwn || slamState === SlamState.SlamFinishSuccessOpponent) {
    if (mySlam) {
      return `You slammed correctly.`;
    } else {
      return `${slamPlayer?.name} slammed correctly.`;
    }
  }
};

export const snackbarPreviousTurn = (state: State) => {
  const previousPlayer = getPreviousPlayer(state);
  const previousTurn = state?.gameState?.previousTurn;
  const players = getPlayers(state);

  if (previousTurn?.turnState === TurnState.Timeout) {
    if (state?.gameState?.playerRafiki) {
      if (previousPlayer?.id === state?.self) {
        return `You timed out and received a penalty card.`;
      } else {
        return `${previousPlayer?.name} timed out and received a penalty card.`;
      }
    }
    if (previousPlayer?.id === state?.self) {
      return `You timed out.`;
    } else {
      return `${previousPlayer?.name} timed out.`;
    }
  }

  if (previousTurn?.turnState === TurnState.PlayerLeft) {
    if (previousPlayer?.name) {
      return `${previousPlayer?.name} left the game.`;
    }
    return `A player left the game.`;
  }

  if (previousTurn?.turnState === TurnState.RemoveInactive) {
    const playerIds = previousTurn?.playerIds || [];
    let playerNames = playerIds?.map((playerId: string) => {
      const player = players?.[playerId];
      return player?.name || "";
    });

    if (previousTurn?.playerIds?.includes(state?.self)) {
      return `You were removed due to inactivity.`;
    } else if (playerNames.length === 1) {
      return `${combineWithAnd(playerNames)} was removed due to inactivity.`;
    } else if (playerNames.length > 1) {
      return `${combineWithAnd(playerNames)} were removed due to inactivity.`;
    }
  }

  if (previousTurn?.turnState === TurnState.NoValidMove) {
    if (previousPlayer?.id === state?.self) {
      return `You had no playable move.`;
    } else {
      return `${previousPlayer?.name} had no playable move.`;
    }
  }
  if (previousTurn?.turnState === TurnState.Replace) {
    if (previousPlayer?.id === state?.self) {
      return `You replaced one of your cards.`;
    } else {
      return `${previousPlayer?.name} replaced one of their cards.`;
    }
  }
  if (previousTurn?.turnState === TurnState.LookAtOwn) {
    if (previousTurn?.player === state?.self) {
      const cards = getHandForPlayer(state, state?.self);
      const name = getPrettyCardDesc(cards?.[previousTurn?.playerIndex]);
      if (!name) return;
      return `You looked at one of your own cards.`;
    } else {
      return `${previousPlayer?.name} looked at one of their own cards.`;
    }
  }
  if (previousTurn?.turnState === TurnState.LookAtOpponents) {
    const opponent = players?.[previousTurn.opponent];
    if (previousTurn.opponent === state?.self) {
      return `${previousPlayer?.name} looked at one of your cards.`;
    } else if (previousPlayer?.id === state?.self) {
      return `You looked at one of ${opponent?.name}'s cards.`;
    } else {
      return `${previousPlayer?.name} looked at one of ${opponent?.name}'s cards.`;
    }
  }

  if (previousTurn?.turnState === TurnState.SwapWithOpponent) {
    const opponent = players?.[previousTurn.opponent];
    return snackbarSwapWithOpponent(previousPlayer, state?.self, opponent);
  }
};

export const snackbarBeforeTurnMessage = (state: State) => {
  const currentPlayer = getCurrentPlayer(state);
  const turnState = getTurnState(state);

  if (areRoundTurnsOver(state)) {
    return "";
  }
  if (!isEveryoneReady(state)) {
    return "Waiting for everyone to be ready.";
  }
  if (isMyTurn(state)) {
    if (turnState === TurnState.Draw) {
      return "It is your turn, pick up a card from the deck.";
    }
  } else if (currentPlayer?.name) {
    return `It's ${currentPlayer.name}'s turn.`;
  } else {
    return "";
  }
};

export const snackbarTurnMessage = (state: State) => {
  const turnState = getTurnState(state);
  const players = getPlayers(state);

  if (isMyTurn(state)) {
    if (turnState === TurnState.Drawn) {
      const draw = state?.gameState?.drawnCard;
      if ([TurnState.LookAtOwn, TurnState.LookAtOpponents, TurnState.SwapWithOpponent].includes(getCardAction(draw))) {
        return `You have drawn the ${getPrettyCardDesc(draw)}. Put it in the discard pile.`;
      }
      return `You have drawn the ${getPrettyCardDesc(draw)}.`;
    }
    if (turnState === TurnState.LookingAtOwn && !isUndefined(state?.gameState?.lookingAtIndex)) {
      const cards = getHandForPlayer(state, state?.self);
      const name = getPrettyCardDesc(cards?.[state?.gameState?.lookingAtIndex]);
      if (!name) return;
      return `You have the ${name}.`;
    }
    if (
      turnState === TurnState.LookingAtOpponents &&
      !isUndefined(state?.gameState?.lookingAtIndex) &&
      !isUndefined(state?.gameState?.lookingAtPlayer)
    ) {
      const opponent = players?.[state?.gameState?.lookingAtPlayer];

      const cards = getHandForPlayer(state, opponent?.id);
      const name = getPrettyCardDesc(cards?.[state?.gameState?.lookingAtIndex]);
      return `${opponent?.name} has the ${name}.`;
    }
    return StateDescriptions[state?.gameState?.turnState];
  } else {
    return "";
  }
};

export const getSnackbarMessage = (state: State) => {
  const previousPlayer = getPreviousPlayer(state);
  const myTurn = isMyTurn(state);
  const turnState = getTurnState(state);
  const playerRafiki = getRafikiPlayer(state);
  const previousTurn = state?.gameState?.previousTurn;
  const currentPlayer = getCurrentPlayer(state);
  const mySlam = isMySlam(state);

  if (myTurn && state?.showTimeoutWarning) {
    return "The clock is ticking! Time to make your move.";
  }

  if (mySlam && state?.showSlamTimeoutWarning) {
    return "The clock is ticking! Time to make your move.";
  }

  if (gameNotStarted(state)) {
    if (isOwner(state)) {
      return "Start a new game.";
    }
    const owner = getRoomOwner(state);
    return owner?.name ? `Waiting for ${owner.name} to start a new game.` : "";
  }

  const gameOver = isGameOver(state);
  if (gameOver && state?.gameState?.roundSaved) {
    const winners = getGameWinners(state);
    if (winners?.length === 1) {
      if (winners?.[0]?.id === state?.self) {
        return "Congratulations! You won the game!";
      } else {
        return `${winners?.[0]?.name} won the game!`;
      }
    } else if (winners?.length && winners?.length > 1) {
      const iWon = winners.some((p) => p?.id === state?.self);
      const otherWinners = winners.filter((p) => p?.id !== state?.self);
      if (iWon) {
        return `Congratulations! You are tied with ${combineWithAnd(otherWinners.map((p) => p?.name))}.`;
      } else {
        return `${combineWithAnd(winners.map((p) => p?.name))} have tied!`;
      }
    }
  }

  if (turnState === TurnState.RoundOver && state?.gameState?.roundSaved) {
    const winners = getRoundWinners(state);
    if (winners?.length === 1) {
      if (winners?.[0]?.id === state?.self) {
        return "Congratulations! You have won this round!";
      } else {
        return `${winners?.[0]?.name} won the round!`;
      }
    } else if (winners?.length && winners?.length > 1) {
      const iWon = winners.some((p) => p?.id === state?.self);
      const otherWinners = winners.filter((p) => p?.id !== state?.self);
      if (iWon) {
        return `Congratulations! You are tied with ${combineWithAnd(otherWinners.map((p) => p?.name))}.`;
      } else {
        return `${combineWithAnd(winners.map((p) => p?.name))} have tied!`;
      }
    }
  }

  if (previousTurn?.turnState === TurnState.PlayerLeft) {
    if (previousTurn.player === state?.owner) {
      return `Game has ended. The room owner left the game.`;
    }
  }

  if (isRoundOver(state)) {
    return "Cards are revealed.";
  }

  if (showViewTwoModal(state)) {
    if (amIReady(state)) {
      return "Waiting for other players.";
    }
    return "Remember your bottom two cards and click when you are ready to start the game.";
  }

  if (previousTurn?.turnState === TurnState.CallRafiki && turnState === TurnState.Draw) {
    if (previousPlayer?.id === state?.self) {
      return `You called RAFIKI!`;
    } else if (myTurn) {
      return `It is your last turn. RAFIKI has been called by ${playerRafiki}.`;
    } else {
      return `It is ${currentPlayer?.name}'s last turn. RAFIKI has been called by ${playerRafiki}.`;
    }
  }

  const slamMessage = snackbarSlamMessage(state);
  const slamFinishMessage = snackbarSlamFinishMessage(state);
  const previousTurnSentence = snackbarPreviousTurn(state);
  const beforeTurnSentence = snackbarBeforeTurnMessage(state);
  const turnSentence = snackbarTurnMessage(state);

  if (slamFinishMessage && beforeTurnSentence) {
    return `${slamFinishMessage} ${beforeTurnSentence}`;
  }
  if (slamMessage) {
    return slamMessage;
  }
  if (previousTurnSentence && beforeTurnSentence) {
    return `${previousTurnSentence} ${beforeTurnSentence}`;
  }
  if (beforeTurnSentence) {
    return `${beforeTurnSentence}`;
  }
  if (turnSentence) {
    return turnSentence;
  }
  return `${turnSentence}`;
};

// Cards are turned red to highlight them
export const isCardBlack = (state: State, selectedCardsLength: number, card: Card) => {
  const playerID = card.playerID;
  const currentPlayer = getCurrentPlayer(state);
  if (isRoundOver(state)) {
    return true;
  }

  if (state.gameState.turn === -1) return true;

  if (playerID === "drawn") {
    return true;
  }

  if (playerID === "modal" || playerID === "discard") return true;

  const turnState = state?.gameState?.turnState;
  const slamState = state?.gameState?.slam?.slamState;
  const mySlam = isMySlam(state);
  const previousTurn = state?.gameState?.previousTurn;

  if (playerID === "draw") return true;

  if (slamState === SlamState.SlamStart) {
    if (mySlam && card.value in getAlreadySlammed(state)) {
      return false;
    }
    return true;
  }

  if (state?.gameState?.slam?.slamState === SlamState.SlamFailureOwn) {
    return !(card.index === state?.gameState?.slam?.slammedCardIndex && playerID === state?.gameState?.slam?.player);
  }
  if (
      state?.gameState?.slam?.slamState === SlamState.SlamFinishFailureOpponent && card.index === state?.gameState?.slam?.penaltyIndex
  ) {
    return false;
  }
  if (
      state?.gameState?.slam?.slamState === SlamState.SlamFinishFailureOwn && card.index === state?.gameState?.slam?.penaltyIndex
  ) {
    return false;
  }
  if (
    state?.gameState?.slam?.slamState === SlamState.SlamFailureOpponent ||
    state?.gameState?.slam?.slamState === SlamState.SlamSuccessOpponent
  ) {
    return !(card.index === state?.gameState?.slam?.slammedCardIndex && playerID === state?.gameState?.slam?.opponent);
  }

  if (turnState === TurnState.Draw) {
    if (state?.gameState?.slam?.slamState === SlamState.SlamFinishFailureOwn) {
      return !(card.index === state?.gameState?.slam?.slammedCardIndex && playerID === state?.gameState?.slam?.player);
    }
    if (
      state?.gameState?.slam?.slamState === SlamState.SlamFinishSuccessOpponent ||
      state?.gameState?.slam?.slamState === SlamState.SlamFinishFailureOpponent
    ) {
      return !(
        card.index === state?.gameState?.slam?.slammedCardIndex && playerID === state?.gameState?.slam?.opponent
      );
    }
    if (state?.gameState?.slam?.slamState === SlamState.SlamFinishSuccessOwn) {
      return true;
    }

    if (previousTurn?.turnState === TurnState.Replace) {
      return previousTurn?.playerIndex !== card.index || previousTurn?.player !== card.playerID;
    }
    if (previousTurn?.turnState === TurnState.LookAtOpponents) {
      return previousTurn?.opponentIndex !== card.index || previousTurn?.opponent !== card.playerID;
    }
    if (previousTurn?.turnState === TurnState.LookAtOwn) {
      return previousTurn?.playerIndex !== card.index || previousTurn?.player !== card.playerID;
    }
    if (previousTurn?.turnState === TurnState.SwapWithOpponent) {
      return (
        (previousTurn?.playerIndex !== card.index || previousTurn?.player !== card.playerID) &&
        (previousTurn?.opponentIndex !== card.index || previousTurn?.opponent !== card.playerID)
      );
    }
    return true;
  }
  if (currentPlayer?.id === state?.self) {
    const selectedCardPlayer = getPlayerFromCard(state, state?.selectedCards?.[0]?.value);
    if (turnState === TurnState.SwapWithOpponent && selectedCardsLength === 0) {
      return !["draw"].includes(playerID);
    }
    if (turnState === TurnState.SwapWithOpponent && selectedCardsLength === 1) {
      if (state?.selectedCards?.[0].value === card.value) {
        return false;
      }
      if (selectedCardPlayer?.id === state?.self) {
        return !["draw"].includes(playerID);
      } else {
        return true;
      }
    }
    return true;
  }
  return true;
};

export const shouldHideCard = (state: State, playerID: string, card: string, index?: number): boolean => {
  if (["discard", "drawn", "modal"].includes(playerID)) {
    return false;
  }
  const slamState = getSlamState(state);
  if (slamState === SlamState.SlamStart) {
    return true;
  }

  const turnState = getTurnState(state);
  const myTurn = isMyTurn(state);

  if (state?.gameState?.turnState === TurnState.RoundOver) {
    return playerID === "draw";
  }
  if (!myTurn) {
    return true;
  }
  if (turnState === TurnState.LookingAtOwn && state?.gameState?.lookingAtIndex === index && playerID === state?.self) {
    return false;
  }
  if (
    turnState === TurnState.LookingAtOpponents &&
    state?.gameState?.lookingAtIndex === index &&
    playerID === state?.gameState?.lookingAtPlayer
  ) {
    return false;
  }
  return true;
};

export const showDrawModal = (state: State) => {
  const turnState = getTurnState(state);
  const myTurn = isMyTurn(state);
  return (turnState === TurnState.Drawn || turnState === TurnState.Replace) && myTurn;
};

export const getDrawAndDiscard = (state: State) => {
  const gameState = state.gameState || {};
  return {
    draw: gameState?.deck?.length ? gameState?.deck[gameState?.deck?.length - 1] : "",
    discard: gameState?.discardPile?.length ? gameState?.discardPile[gameState?.discardPile?.length - 1] : "",
  };
};

export const isOwner = (state: State) => {
  return state.owner === state.self && state.owner;
};

export const isMyTurn = (state: State) => {
  return state?.gameState?.turn >= 0 && state?.gameState?.playerIds[state.gameState.turn] === state.self;
};

export const getDraw = (state: State) => {
  return state?.gameState?.drawnCard;
};

export const isModalOpen = (state: State, modalID: ModalID) => {
  return state?.modals[modalID];
};

export const getOpenModalId = (state: State) => {
  if (showSlamModal(state)) {
    return ModalID.SlamModal;
  }
  if (showViewTwoModal(state)) {
    return ModalID.ViewTwoModal;
  }
  if (showRafikiModal(state)) {
    return ModalID.CallRafikiModal;
  }
  if (showRoundOverModal(state)) {
    return ModalID.RoundOverModal;
  }
  if (isModalOpen(state, ModalID.SlammingInfoModal)) {
    return ModalID.SlammingInfoModal;
  }
  if (isModalOpen(state, ModalID.SlammingInfoOpponentModal)) {
    return ModalID.SlammingInfoOpponentModal;
  }
  if (isModalOpen(state, ModalID.RemovePlayersModal)) {
    return ModalID.RemovePlayersModal;
  }
  if (gamePaused(state)) {
    return ModalID.PauseModal;
  }
  if (showApproveModal(state)) {
    return ModalID.ApproveModal;
  }
  if (showRoomFullModal(state)) {
    return ModalID.RoomFullModal;
  }
  if (isDenied(state)) {
    return ModalID.DeniedModal;
  }
  if (isModalOpen(state, ModalID.PassOwnershipModal)) {
    return ModalID.PassOwnershipModal;
  }
  if (isModalOpen(state, ModalID.JoinGameModal)) {
    return ModalID.JoinGameModal;
  }
  return;
};

export const getBottomTwoCards = (state: State) => {
  const self = state.self;
  const myHand = state?.gameState?.hands?.[self];
  return [myHand?.[2], myHand?.[3]];
};

export const isSelected = (state: State, card: string) => state.selectedCards?.some((c) => c.value === card);

export const getCurrentPlayer = (state: State) => {
  const players = getPlayers(state);
  const result = players[state?.gameState?.playerIds[state?.gameState?.turn]];
  return result;
};

export const getPreviousPlayer = (state: State) => {
  // @ts-ignore
  const previousPlayerId = state?.gameState?.previousTurn && state?.gameState?.previousTurn?.player;
  const players = getPlayers(state);
  return players?.[previousPlayerId];
};

export const isNewGame = (state: State) => {
  return (
    state?.gameState?.turn === 0 &&
    state?.gameState?.deck?.length > 0 &&
    state?.gameState?.discardPile?.length === 0 &&
    state?.gameState?.loop === 0 &&
    state?.gameState?.turnState === TurnState.Draw
  );
};

export const getSlamDiscard = (state: State) => {
  return state?.gameState?.slam?.slamDiscard;
};

export const getSlamPlayer = (state: State) => {
  const players = getPlayers(state);
  const result = players?.[state?.gameState?.slam?.player];
  return result;
};

export const getSlamPlayerId = (state: State) => {
  const players = getPlayers(state);
  const result = players?.[state?.gameState?.slam?.player];
  return result?.id;
};

export const isSlamInitiated = (state: State) => {
  const slamState = getSlamState(state);
  return slamState === SlamState.SlamStart;
};

export const isMySlam = (state: State) => {
  const self = getSelf(state);
  return self === state?.gameState?.slam?.player;
};

export const areRoundTurnsOver = (state: State) => {
  return areGameRoundTurnsOver(state?.gameState);
};

export const isRoundOver = (state: State) => {
  return state?.gameState?.turnState === TurnState.RoundOver;
};

export const correctSlamDoneThisTurn = (state: State) => {
  return state?.gameState?.correctSlamDone;
};

const isSlamInProgress = (state: State) => {
  return [
    SlamState.SlamStart,
    SlamState.SlamFailureOwn,
    SlamState.SlamFailureOpponent,
    SlamState.SlamSuccessOpponent,
  ].includes(state?.gameState?.slam?.slamState);
};

export const canSlam = (state: State) => {
  const hand = state?.gameState?.hands?.[state?.self];
  const slamInProgress = isSlamInProgress(state);
  const tooManySlams = state?.gameState?.failedSlamCount?.[state?.self] >= 1;
  return (
    state?.gameState?.playerRafiki !== state?.self &&
    state?.gameState?.deck?.length > 0 &&
    !tooManySlams &&
    !slamInProgress &&
    state?.self !== state?.gameState?.playerRafiki &&
    state?.gameState?.turnState !== TurnState.Replace &&
    state?.gameState?.turnState !== TurnState.Drawn &&
    state?.gameState?.turnState !== TurnState.LookingAtOwn &&
    state?.gameState?.turnState !== TurnState.LookingAtOpponents &&
    !gameNotStarted(state) &&
    !gamePaused(state) &&
    hand?.some((c) => c !== NOCARD) &&
    state?.gameState?.discardPile.length > 0 &&
    !correctSlamDoneThisTurn(state) &&
    !isRoundOver(state) &&
    !isGameOver(state)
  );
};

export const getAlreadySlammed = (state: State) => {
  return state?.gameState?.slammedCards;
};

export const canCallRafiki = (state: State) => {
  const turnState = getTurnState(state);
  const myTurn = isMyTurn(state);
  const rafikiCalled = isRafikiCalled(state);
  const hasNotStarted = gameNotStarted(state);
  const roundTurnsOver = areRoundTurnsOver(state);
  const slamInProgress = isSlamInProgress(state);

  return (
    state?.gameState?.loop >= 3 &&
    !roundTurnsOver &&
    !hasNotStarted &&
    !rafikiCalled &&
    myTurn &&
    turnState === TurnState.Draw &&
    !gamePaused(state) &&
    !slamInProgress
  );
};

export const isRafikiCalled = (state: State) => {
  return Boolean(state?.gameState?.playerRafiki);
};

export const getRafikiPlayer = (state: State) => {
  const players = getPlayers(state);
  const result = players?.[state?.gameState?.playerRafiki];
  return result?.name;
};

export const getRafikiPlayerId = (state: State) => {
  return state?.gameState?.playerRafiki;
};

export const isMyRafiki = (state: State) => {
  return state?.gameState?.playerRafiki === state?.self;
};

export const showRafikiModal = (state: State) => {
  return (
    getRafikiPlayer(state) &&
    state?.modals[ModalID.CallRafikiModal] &&
    !areRoundTurnsOver(state) &&
    state?.gameState?.playerIds?.includes(state?.self)
  );
};

export const cardScore = (card: string): number => {
  const number = card.slice(0, -1);
  if (card === NOCARD) {
    return 0;
  } else if (card === "KH" || card === "KD" || card === "XJ" || card === "YJ") {
    return -1;
  } else if (number === "A") {
    return 1;
  } else if ("JQK".includes(number)) {
    return 10;
  } else {
    return Number(number);
  }
};

export const handScore = (hand: Array<string>): number => {
  if (!hand) {
    return 0;
  }
  const values = hand.map(cardScore);
  return sum(values);
};

export const getRoundScoreBoard = (state: State) => {
  const players = getPlayers(state);
  if (!players) {
    return;
  }
  const rafikiCaller = state?.gameState?.playerRafiki;
  const scoreboard = sortBy(
    state?.gameState?.playerIds.map((playerId) => {
      const player = players[playerId];
      return {
        id: player?.id,
        name: player?.name,
        roundScore: handScore(state?.gameState?.hands[player?.id]),
      };
    }),
    "roundScore"
  );
  const minScore = scoreboard?.[0]?.roundScore;
  const tied = minScore === scoreboard?.[1]?.roundScore;
  const withPenalty = scoreboard.map((player) => {
    if (player.id === rafikiCaller && (player.roundScore !== minScore || tied)) {
      return {
        ...player,
        roundScore: (player.roundScore || 0) + RAFIKI_PENALTY,
        penaltyAdded: true,
      };
    }
    return {
      ...player,
      penaltyAdded: false,
    };
  });
  return sortBy(withPenalty, "roundScore");
};

export const getRoundWinners = (state: State) => {
  const scoreboard = getRoundScoreBoard(state);
  const topScore = scoreboard?.[0]?.roundScore;
  return scoreboard?.filter((player) => player?.roundScore === topScore);
};

export const getGameScores = (state: State) => {
  const scoreboard = getRoundScoreBoard(state);
  const scoreMap: any = {};
  // const players = getPlayers(state);
  scoreboard?.forEach((player) => {
    return (scoreMap[player?.id] = {
      ...player,
      score: 0,
    });
  });
  state?.scores.forEach(
    (player) =>
      (scoreMap[player?.id] = {
        ...scoreMap[player?.id],
        score: player?.score,
      })
  );
  return scoreMap;
};

export const getGameScoreboard = (state: State) => {
  const scoreMap = getGameScores(state);
  const players = getPlayers(state);
  const scores = sortBy(
    state?.gameState?.playerIds.map((playerId: string) => {
      const player = players[playerId];
      return {
        ...player,
        score: scoreMap[player?.id]?.score,
        roundScore: scoreMap[player?.id]?.roundScore,
      };
    }),
    "score"
  );
  return scores;
};

export const getGameWinners = (state: State) => {
  const scoreboard = getGameScoreboard(state);
  const topScore = scoreboard?.[0]?.score;
  return scoreboard?.filter((player) => player?.score === topScore);
};

export const showRoundOverModal = (state: State) => {
  return (
    state?.gameState?.turnState === TurnState.RoundOver &&
    state?.modals[ModalID.RoundOverModal] &&
    state?.gameState?.roundSaved
  );
};

export const isEveryoneReady = (state: State) => {
  return state?.gameState?.playerIds?.every((player) => state?.gameState?.ready?.[player]);
};

export const isPlayerReady = (state: State, playerId: string) => {
  return state?.gameState?.ready?.[playerId];
};

export const shouldViewCards = (state: State) => {
  return imApproved(state) && !isEveryoneReady(state) && !gamePaused(state) && state?.gameState?.turn >= 0;
};

export const showViewTwoModal = (state: State) => {
  return (
    shouldViewCards(state) &&
    state?.modals[ModalID.ViewTwoModal] &&
    !amIReady(state) &&
    state?.gameState?.playerIds?.includes(state?.self)
  );
};

export const gameNotStarted = (state: State) => {
  return state?.gameState?.turn === -1;
};

export const getPlayerFromCard = (state: State, card: string) => {
  const playerIds = Object.keys(state?.gameState?.hands);
  const players = getPlayers(state);
  for (const playerId of playerIds) {
    const hand = state?.gameState?.hands?.[playerId];
    if (hand.includes(card)) {
      return players?.[playerId];
    }
  }
};

export const getDebug = (state: State) => state?.debug;

export const getHumanPlayerCount = (state: State) => {
  return (
    state?.gameState?.playerIds?.filter((player) => state?.gameState?.playerStatus?.[player] === PlayerStatus.Approved)
      ?.length - Object.keys(state?.gameState?.bots).length
  );
};

export const getPlayerCount = (state: State) => {
  return state?.gameState?.playerIds?.length;
};

export const getActivePlayerCount = (state: State) => {
  return state?.gameState?.playerIds?.filter((p) => state?.gameState?.isPlaying?.[p])?.length;
};

export const shouldCallAutomaticRafiki = (state: State) => {
  const hand = getHandForPlayer(state, state?.self);
  return hand?.every((card) => card === NOCARD) && isMyTurn(state) && !isRafikiCalled(state);
};

export const getRoomOwner = (state: State) => {
  const players = getPlayers(state);
  const result = players?.[state?.owner];
  return result;
};

export const getOwnPlayer = (state: State) => {
  const players = getPlayers(state);
  const result = players?.[state?.self];
  return result;
};

export const getPlayerIds = (state: State) => {
  return state?.gameState?.playerIds;
};

export const isRedSnackbar = (state: State) => {
  return isRafikiCalled(state) && !areRoundTurnsOver(state);
};

export const isYellowSnackbar = (state: State) => {
  return isWaitingOnMe(state) && !areRoundTurnsOver(state);
};

export const canPauseGame = (state: State) => {
  return isOwner(state) && !gameNotStarted(state);
};

export const gamePaused = (state: State) => {
  return state?.gameState?.paused;
};

export const getCombinedPlayerIds = (state: State) => {
  return [...state?.gameState?.allPlayerIds, ...state?.gameState?.leftPlayerIds];
};

export const getPlayerStatus = (state: State) => {
  return state?.gameState?.playerStatus;
};

export const showApproveModal = (state: State) => {
  return gameNotStarted(state) && isOwner(state) && isModalOpen(state, ModalID.ApproveModal);
};

export const getAllOtherHumanPlayers = (state: State) => {
  const players = getPlayers(state);
  return state?.gameState?.allPlayerIds
    .filter(
      (p) =>
        p !== state?.self &&
        state?.gameState?.playerStatus?.[p] !== PlayerStatus.Limbo &&
        !state?.gameState?.leftPlayerIds?.includes(p) &&
        state?.gameState?.isPlaying?.[p] &&
        !(p in state?.gameState?.bots)
    )
    .map((p) => players[p]);
};

export const isSidebarOpen = (state: State) => {
  return state?.sidebarOpen;
};

export const canTakeSlamPenalty = (state: State) => {
  return state?.gameState?.hands?.[state?.self]?.some((card) => card === NOCARD);
};

export const imApproved = (state: State) => {
  return state?.gameState?.playerStatus?.[state?.self] === PlayerStatus.Approved;
};

export const getBoardPlayers = (state: State) => {
  const players = getPlayers(state);
  return state?.gameState?.playerIds.map((p) => players[p]);
};

export const getTimestamp = (state: State) => state?.gameState?.timestamp;

export const shouldSaveRound = (state: State) => {
  const gameState = state.gameState;
  const lastTurn = areRoundTurnsOver(state);
  return lastTurn && !gameState.roundSaved && isOwner(state) && gameState?.turnState === TurnState.RoundOver;
};

export const isGameOver = (state: State) => {
  const lastRound = finishedLastRound(state);
  return lastRound && isRoundOver(state);
};

export const shouldUpdateLeaderBoard = (state: State) => {
  const gameState = state.gameState;
  return isGameOver(state) && !gameState.leaderBoardUpdated && isOwner(state) && gameState?.gameSaved;
};

export const shouldRecordGameStarted = (state: State) => {
  return !state?.gameState?.initialGameSaved && !gameNotStarted(state) && isOwner(state);
};

export const allApprovedOrDenied = (state: State) => {
  const gameState = state?.gameState;
  return gameState?.allPlayerIds?.every((p) => gameState?.playerStatus?.[p] !== PlayerStatus.Pending);
};

export const canStartGame = (state: State) => {
  return allApprovedOrDenied(state) || state?.gameState?.playerIds?.length === 6;
};

export const imTimedOut = (state: State) => {
  return !state?.gameState?.isPlaying?.[state?.self] && state?.gameState?.playerIds.includes(state?.self);
};

export const hasGameLoaded = (state: State) => {
  const players = getPlayers(state);
  return state?.gameState?.playerIds.every((player) => player in players);
};

export const havePlayerNamesLoaded = (state: State) => {
  const players = getPlayers(state);
  return state?.gameState?.allPlayerIds.length > 0 && state?.gameState?.allPlayerIds?.every((p) => p in players);
};

export const isRoomFull = (state: State) => {
  return state?.gameState?.playerIds?.length === 6;
};

export const showRoomFullModal = (state: State) => {
  return isRoomFull(state) && !state?.gameState?.playerIds?.includes(state?.self);
};

export const getPreviousTurn = (state: State) => {
  return state?.gameState?.previousTurn;
};

export const finishedLastRound = (state: State) => {
  return state?.gameState?.rounds - 1 <= state?.gameState?.round;
};

export const isLeaderBoardOpen = (state: State) => {
  return state?.leaderBoardOpen;
};

export const getIsPlaying = (state: State) => {
  return state?.gameState?.isPlaying;
};

export const isWaitingOnMe = (state: State) => {
  const slamState = getSlamState(state);
  if (isRoundOver(state)) {
    return false;
  }
  if (
    [
      SlamState.SlamStart,
      SlamState.SlamFailureOwn,
      SlamState.SlamFailureOpponent,
      SlamState.SlamSuccessOpponent,
    ].includes(slamState)
  ) {
    return state?.gameState?.slam?.player === state?.self;
  }
  return isMyTurn(state);
};

export const shouldStartSlamTimeout = (state: State) => {
  const slamState = getSlamState(state);
  if ([SlamState.SlamStart].includes(slamState)) {
    return state?.gameState?.slam?.player === state?.self;
  }
};

export const getSlammedCard = (state: State) => {
  if (state?.gameState?.slam?.slamState === SlamState.SlamFinishSuccessOwn) {
    return state?.gameState?.discardPile?.[state?.gameState?.discardPile?.length - 1];
  } else if (state?.gameState?.slam?.slamState === SlamState.SlamSuccessOpponent) {
    return state?.gameState?.discardPile?.[state?.gameState?.discardPile?.length - 1];
  } else if (state?.gameState?.slam?.slamState === SlamState.SlamFailureOwn) {
    return state?.gameState?.hands?.[state?.gameState?.slam?.player]?.[state?.gameState?.slam?.slammedCardIndex];
  } else if (state?.gameState?.slam?.slamState === SlamState.SlamFailureOpponent) {
    return state?.gameState?.hands?.[state?.gameState?.slam?.opponent]?.[state?.gameState?.slam?.slammedCardIndex];
  }
};

export const isFirstLoop = (state: State) => {
  return state?.gameState?.loop === 0;
};

export const showSlamModal = (state: State) => {
  const slamState = state?.gameState?.slam?.slamState;
  return (
    (slamState === SlamState.SlamSuccessOpponent ||
      slamState === SlamState.SlamFinishSuccessOwn ||
      slamState === SlamState.SlamFailureOwn ||
      slamState === SlamState.SlamFailureOpponent) &&
    isModalOpen(state, ModalID.SlamModal)
  );
};

export const isDenied = (state: State) => {
  return [PlayerStatus.Denied, PlayerStatus.Limbo].includes(state?.gameState?.playerStatus?.[state?.self]);
};

export const canRetryEntry = (state: State) => {
  return state?.gameState?.attempts?.[state?.self] < 3;
};

export const getRound = (state: State) => state.gameState?.round + 1;
export const getTotalRounds = (state: State) => state.gameState?.rounds;

export const getBots = (state: State) =>
  state.gameState?.playerIds.filter((p) => p in state?.gameState?.bots).map((p) => state?.gameState?.bots[p]);

export const amIReady = (state: State) => state.gameState?.ready?.[state?.self];

export const shouldShowTimeoutWarning = (state: State) => state?.showTimeoutWarning;

export const shouldShowSlamTimeoutWarning = (state: State) => state?.showSlamTimeoutWarning;

export const getSlamModalTitle = (state: State) => {
  const slamState = state?.gameState?.slam?.slamState;
  let title;
  if (slamState === SlamState.SlamFailureOwn || slamState === SlamState.SlamFailureOpponent) {
    title = "SLAM FAIL!";
  } else if (slamState === SlamState.SlamSuccessOpponent || slamState === SlamState.SlamFinishSuccessOwn) {
    title = "CORRECT SLAM!";
  }
  return title;
};

export const getSlamModalStrapLine = (state: State) => {
  const slamState = state?.gameState?.slam?.slamState;
  const playerSlam = getSlamPlayer(state);
  const mySlam = isMySlam(state);
  let strapLine;
  if (slamState === SlamState.SlamFailureOwn || slamState === SlamState.SlamFailureOpponent) {
    if (mySlam) {
      strapLine = "Your slam card does not match the rank of the discarded card. You will suffer a penalty!";
    } else {
      strapLine = `${playerSlam?.name}'s slam card does not match the rank of the discarded card. They will suffer a penalty!`;
    }
  } else if (slamState === SlamState.SlamFinishSuccessOwn) {
    if (mySlam) {
      strapLine = "Your slam card matches the rank of the discarded card.";
    } else {
      strapLine = `${playerSlam?.name}'s slam card matches the rank of the discarded card.`;
    }
  } else if (slamState === SlamState.SlamSuccessOpponent) {
    if (mySlam) {
      strapLine =
        "Your slam card matches the rank of the discarded card. Pick a card to discard to transfer to your opponent.";
    } else {
      strapLine = `${playerSlam.name}'s slam card matches the rank of the discarded card.`;
    }
  }
  return strapLine;
};

export const getRoundOverTitleAndStrapLine = (state: State) => {
  // @ts-ignore
  const roundWinners: Score[] = getRoundWinners(state);
  const self = getSelf(state);
  const rounds = getTotalRounds(state);
  const lastRound = finishedLastRound(state);
  const gameWinners: Score[] = getGameWinners(state);
  const tied = gameWinners && gameWinners?.length > 1;

  const gameOver = lastRound && !tied;
  const roundOrGame = gameOver ? "game" : "round";
  const winners = gameOver ? gameWinners : roundWinners;
  const iWon = winners?.some((p: any) => p?.id === self);
  const score = winners?.[0]?.roundScore;

  const titles = {
    "one-winner": {
      me: {
        round: "Congratulations!",
        game: `End of ${rounds} round game!`,
      },
      other: {
        round: "Round Over!",
        game: `End of ${rounds} round game!`,
      },
    },
    tied: {
      me: {
        round: "Round Over!",
        game: `End of ${rounds} round game!`,
      },
      other: {
        round: "Round Over!",
        game: `End of ${rounds} round game!`,
      },
    },
  };
  const otherWinners = winners.filter((p) => p?.id !== self);
  const strapLines = {
    "one-winner": {
      me: {
        round: `You have won this round with a score of ${score} points!`,
        game: `Congratulations! You have won the game!`,
      },
      other: {
        round: `${winners?.[0]?.name} won the round with a score of ${score}!`,
        game: `${winners?.[0]?.name} has won the game!`,
      },
    },
    tied: {
      me: {
        round: `You are tied with ${combineWithAnd(otherWinners.map((p) => p.name))} with a score of ${score} points!`,
        game: `${combineWithAnd(winners.map((p) => p.name))} have tied with a score of ${score} points!`, // This should never happen
      },
      other: {
        round: `${combineWithAnd(otherWinners.map((p) => p.name))} have tied with a score of ${score} points!`,
        game: `${combineWithAnd(winners.map((p) => p.name))} have tied with a score of ${score} points!`, // This should never happen
      },
    },
  };
  const noOfWinners = winners?.length === 1 ? "one-winner" : "tied";
  const who = iWon ? "me" : "other";

  const title = titles[noOfWinners][who][roundOrGame];
  const strapLine = strapLines[noOfWinners][who][roundOrGame];
  return {
    title,
    strapLine,
  };
};

export const isSlamPending = (state: State) => {
  return [
    SlamState.SlamStart,
    SlamState.SlamSuccessOpponent,
    SlamState.SlamFailureOwn,
    SlamState.SlamFailureOpponent,
  ].includes(state?.gameState?.slam?.slamState);
};

export const getPenaltyLine = (state: State) => {
  const scoreBoard = getRoundScoreBoard(state);
  const rafikiCallerScore = scoreBoard?.find((x) => x?.id === state?.gameState?.playerRafiki);
  if (!rafikiCallerScore?.penaltyAdded) {
    return "";
  }
  if (state?.self === state?.gameState?.playerRafiki) {
    return `You get a 10 point penalty for calling Rafiki too early.`;
  } else {
    return `${rafikiCallerScore?.name} gets a 10 point penalty for calling Rafiki too early.`;
  }
};

export const canSignOut = (state: State) => {
  if (isSlamPending(state) && state?.gameState?.slam?.player === state?.self) return false;
  if (isMyTurn(state)) return false;
};

export const getBoardCoordinates = (state: State) => {
  return state?.coordinates;
};

export const leaderboardIntersectingBoard = (state: State, lbCoords: DOMRect) => {
  const coordinates = getBoardCoordinates(state);
  if (!coordinates) return false;
  if (!lbCoords) return false;
  const x = (coordinates?.left + coordinates?.right) / 2;
  const y = (coordinates?.top + coordinates?.bottom) / 2;
  const radius = coordinates?.width / 2;

  const dist = Math.sqrt(Math.pow(lbCoords.right - x, 2) + Math.pow(lbCoords.bottom - y, 2));
  return dist < radius;
};

export const getBotSpeed = (state: State) => {
  return state?.gameState?.botSpeed || BotSpeed.Fast;
};

export const getLookingAtIndex = (state: State) => {
  return state?.gameState?.lookingAtIndex;
};

export const getLookingAtPlayer = (state: State) => {
  return state?.gameState?.lookingAtPlayer;
};

export const shouldResetDeck = (state: State) => {
  return (
    state?.gameState?.turnState === TurnState.Draw &&
    state?.gameState?.deck?.length === 0 &&
    !gameNotStarted(state) &&
    !gamePaused(state) &&
    !isRoundOver(state) &&
    !isGameOver(state)
  );
};

export const isUpdatingGameState = (state: State) => {
  return state?.isUpdatingGameState;
};

export const getModalOffset = (state: State) => {
  return state?.modalOffsets?.[ModalID.RoundOverModal];
};

export const getShouldShowRadar = (state: State, playerID: string) => {
  const currentPlayer = getCurrentPlayer(state);
  const turnState = getTurnState(state);
  if (turnState === TurnState.RoundOver) {
    return false;
  }
  return currentPlayer?.id === playerID;
};

export const isCardBright = (state: State, playerID: string): boolean => {
  const currentPlayer = getCurrentPlayer(state);
  if (["draw", "discard", "drawn", "modal"].includes(playerID)) {
    return true;
  }
  const slamState = getSlamState(state);
  const mySlam = isMySlam(state);
  if (mySlam && slamState === SlamState.SlamStart) {
    return true;
  }
  const turnState = getTurnState(state);
  const myTurn = isMyTurn(state);
  if (myTurn && [TurnState.LookAtOpponents, TurnState.LookingAtOpponents].includes(turnState)) {
    return currentPlayer?.id !== playerID;
  }
  if (myTurn && [TurnState.SwapWithOpponent].includes(turnState)) {
    return true;
  }
  if (myTurn && [TurnState.SwapBetweenOpponents].includes(turnState)) {
    return currentPlayer?.id !== playerID;
  }
  if (turnState === TurnState.RoundOver) {
    return true;
  }
  return currentPlayer?.id === playerID;
};

