import React, { useEffect, useRef, useState } from "react";
import sortBy from "lodash/sortBy";
import styled from "styled-components";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import {desktopAdHeight, leaderboardZIndex } from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { getGameState, isLeaderBoardOpen } from "../redux/selectors";
import { toggleLeaderBoard } from "../redux/actions";
import { useScroll } from "../hooks";

interface TableProps {
  ref: any;
}

interface ContainerProps {
  open: boolean;
}
const Container = styled.div<ContainerProps>`
  position: absolute;
  bottom: ${desktopAdHeight}px;
  width: 100%;
  z-index: ${leaderboardZIndex};
  transform: translate(0, -38px) translate(0, 100%);
  transition: 0.2s transform ease;

  ${({ open }) => open && `transform: none;`}
`;

const Trapezoid = styled.div`
  text-transform: uppercase;
  border-bottom: 38px solid #fecf6f;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 100%;
  max-width: 100vw;

  color: #2c2f3e;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 38px;
  padding: 0 5px;
  cursor: pointer;
`;

const TableContainer = styled.div<TableProps>`
  background: #2c2f3e;
`;

const TableScroll = styled.div`
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar-thumb {
    background: #fecf6f;
    border: 6px solid #2c2f3e;
  }
`;

const Table = styled.table`
  padding: 6px 0;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  
  thead td:nth-child(1) {
    width: 14%;
  } 
  thead td:nth-child(2) {
  } 
  thead td:nth-child(3) {
    width: 17%;
  } 
  thead td:nth-child(4) {
    width: 14%;
  } 
  thead td:nth-child(5) {
    width: 14%;
  } 
  tbody td:nth-child(1) {
  } 
  tbody td:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
  } 
  tbody td:nth-child(3) {
  } 
  tbody td:nth-child(4) {
  } 
  tbody td:nth-child(5) {
  } 
`;

const Cell = styled.td`
  padding: 3px 12px 3px 12px;
  border-right: solid 1px #fecf6f;
`;

const Header = styled(Cell)`
  color: #e2e3ea;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
`;

interface TdProps {
  inverted?: boolean;
}

const Td = styled(Cell)<TdProps>`
  color: #fecf6f;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  width: 0px;
  white-space: nowrap;
  ${({ inverted }) =>
    inverted &&
    `
    color: #2c2f3e;
    background: #fecf6f;
  `}
`;

const Plus = styled.div`
  float: right;
`;

const Text = styled.div`
  color: #fecf6f;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  margin: 12px 0;
`;

const SearchContainer = styled.div`
  padding: 0 18.5px 18.5px 18.5px;
`;

const Search = styled.input`
  background-color: #393c4b;
  width: 100%;
  height: 36px;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "Work Sans";
  letter-spacing: 0;
  line-height: 14px;
  border: none;
  padding: 0 15px;
`;

const ExpandColumn = styled.span`
  cursor: pointer;
`;

interface GlobalLeaderBoardProps {
  ref: any;
  showShareText: boolean;
}

const PAGE = 20;

const uniqueEntries = (leaderBoard: any[]) => {
  const seen: any = {};
  const result: any = [];
  leaderBoard.forEach((player: any) => {
    if (!(player.id in seen)) {
      result.push(player);
      seen[player?.id] = true;
    }
  });
  return result;
};

const GlobalLeaderBoard = React.forwardRef((props: GlobalLeaderBoardProps, ref) => {
  const db = firebase.firestore();
  const [leaderBoard, setLeaderBoard] = useState<{
    board: any[];
    plays: string;
    wins: string;
    for: string;
    updated: boolean;
    initialGameSaved: boolean;
  }>({
    board: [],
    plays: "",
    wins: "",
    for: "-",
    updated: false,
    initialGameSaved: false,
  });
  const gameState = useSelector(getGameState);
  const isOpen = useSelector(isLeaderBoardOpen);
  const dispatch = useDispatch();
  const updated = gameState?.leaderBoardUpdated;
  const initialGameSaved = gameState?.initialGameSaved;
  const tableRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [cursor, setCursor] = useState<any>();
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  useScroll(tableRef, async (percent: number) => {
    if (percent >= 1 && searchText.length <= 3) {
      const temp: any = [];
      const snapshot = await db.collection("leaderboard").orderBy("rank", "asc").limit(PAGE).startAfter(cursor).get();
      let last;
      snapshot.forEach((doc) => {
        temp.push({
          ...doc.data(),
          id: doc.id,
        });
        last = doc;
      });

      if (last) {
        setCursor(last);
      }

      // @ts-ignore
      setLeaderBoard({
        board: [...leaderBoard.board, ...temp],
        plays: "",
        wins: "",
        for: leaderBoard.for,
        updated: leaderBoard.updated,
        initialGameSaved: leaderBoard.initialGameSaved,
      });
    }
  });

  useEffect(() => {
    const getLeaderBoard = async () => {
      if (loading) return;
      if (leaderBoard.for === "" && leaderBoard.updated === updated && leaderBoard.initialGameSaved === initialGameSaved) return;
      setLoading(true);
      const temp: any = [];
      const snapshot = await db.collection("leaderboard").orderBy("rank", "asc").limit(PAGE).get();
      let last;
      snapshot.forEach((doc) => {
        temp.push({
          ...doc.data(),
          id: doc.id,
        });
        last = doc;
      });

      if (last) {
        setCursor(last);
      }

      setLoading(false);
      setLeaderBoard({
        board: temp,
        plays: "",
        wins: "",
        for: "",
        updated: updated,
        initialGameSaved: initialGameSaved,
      });
    };

    const getSearchData = async () => {
      if (loading) return;
      if (leaderBoard.for === searchText && leaderBoard.updated === updated && leaderBoard.initialGameSaved === initialGameSaved) return;
      setLoading(true);
      const temp: any = [];
      let result: firebase.firestore.QueryDocumentSnapshot;
      let snapshot = await db.collection("leaderboard").where("name", "==", searchText).limit(1).get();

      snapshot.forEach((doc) => {
        result = doc;
      });

      // @ts-ignore
      if (!result) {
        setLoading(false);
        setLeaderBoard({
          board: leaderBoard.board,
          plays: "",
          wins: "",
          for: searchText,
          updated: updated,
          initialGameSaved: initialGameSaved,
        });
        return;
      }

      snapshot = await db.collection("leaderboard").orderBy("rank", "asc").limit(5).startAfter(result).get();
      snapshot.forEach((doc) => {
        temp.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      const myDoc = {
        ...result.data(),
        id: result.id,
      };
      temp.push(myDoc);
      snapshot = await db.collection("leaderboard").orderBy("rank", "desc").limit(4).startAfter(result).get();
      snapshot.forEach((doc) => {
        temp.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setLoading(false);
      setLeaderBoard({
        board: temp,
        // @ts-ignore
        plays: myDoc.plays.toString(),
        // @ts-ignore
        wins: myDoc.wins.toString(),
        for: searchText,
        updated: updated,
        initialGameSaved: initialGameSaved,
      });
    };

    if (searchText.length >= 3) {
      getSearchData();
    } else {
      getLeaderBoard();
    }
  }, [searchText, db, updated, initialGameSaved, loading, leaderBoard.for, leaderBoard.board, leaderBoard.updated, leaderBoard.initialGameSaved]);

  if (!leaderBoard.board.length) {
    return null;
  }
  return (
    <Container open={isOpen}>
      <Trapezoid onClick={() => dispatch(toggleLeaderBoard())}>
        Global LeaderBoard
        <Plus>{isOpen ? "-" : "+"}</Plus>
      </Trapezoid>

      <TableContainer ref={ref}>
        <TableScroll ref={tableRef}>
          <Table>
            <thead>
              <tr>
                <Header>Rank</Header>
                <Header>Name</Header>
                <Header>Score <ExpandColumn onClick={() => setOpenDetails(!openDetails)}>{openDetails ? '-' : '+'}</ExpandColumn></Header>
                {openDetails && <Header>Plays</Header>}
                {openDetails && <Header>Wins</Header>}
              </tr>
            </thead>
            <tbody>
              {leaderBoard.board &&
                sortBy(uniqueEntries(leaderBoard.board), "rank")?.map((player: any) => {
                  const inverted = Boolean(searchText && player.name === searchText);
                  return (
                    <tr key={player.id}>
                      <Td inverted={inverted}>{player?.rank}</Td>
                      <Td inverted={inverted}>{player.name}</Td>
                      <Td inverted={inverted}>{player.score}</Td>
                      {openDetails && <Td inverted={inverted}>{player.plays}</Td>}
                      {openDetails && <Td inverted={inverted}>{player.wins}</Td>}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TableScroll>
        <SearchContainer>
          <Text>Enter your username to find your global rank</Text>
          <Search
            ref={searchRef}
            type={"text"}
            onChange={(e: React.BaseSyntheticEvent) => {
                e.persist();
                setTimeout(() => {
                  // @ts-ignore
                  if (e.target.value === searchRef?.current?.value) {
                    setSearchText(e.target.value);
                  }
                }, 1000);
            }}
          />
          {leaderBoard.plays && leaderBoard.wins && (
            <Text>
              Plays: {leaderBoard.plays} Wins: {leaderBoard.wins}
            </Text>
          )}
        </SearchContainer>
      </TableContainer>
    </Container>
  );
});

export default GlobalLeaderBoard;
