import React from "react";
import styled from "styled-components";

import { Action, EndGame, EndRound, GameObjective, Scoring, Setup, Slamming, Tutorial, Forum } from "./SectionsContent";
import { HorizontalDivider } from "./Dividers";

interface ISection {
  id: number;
  title: string;
  descriptionContent: () => JSX.Element;
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1rem;
  color: #e2e3ea;
  font-family: "Work Sans";
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: -0.62px;
  line-height: 18px;
`;

const SectionDesc = styled.div`
  color: #ffffff;
  font-family: "Work Sans";
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18.01px;
  margin-bottom: 20px;
`;

const sections: ISection[] = [
  {
    id: 1,
    title: "Intro Video",
    descriptionContent: () => <Tutorial />,
  },
  {
    id: 2,
    title: "Aim of the Game",
    descriptionContent: () => <GameObjective />,
  },
  {
    id: 3,
    title: "Setting Up",
    descriptionContent: () => <Setup />,
  },
  {
    id: 4,
    title: "Actions",
    descriptionContent: () => <Action />,
  },
  {
    id: 5,
    title: "Slamming",
    descriptionContent: () => <Slamming />,
  },
  {
    id: 6,
    title: "Calling Rafiki!",
    descriptionContent: () => <EndRound />,
  },
  {
    id: 7,
    title: "Scoring",
    descriptionContent: () => <Scoring />,
  },
  {
    id: 8,
    title: "Who's the Winner?",
    descriptionContent: () => <EndGame />,
  },
  {
    id: 10,
    title: "Forum",
    descriptionContent: () => <Forum />,
  },
];

interface SectionProps {
  activeSectionId?: number;
  openSection: Function;
}

export const Sections = (props: SectionProps) => {
  const activeSectionId = props.activeSectionId;
  const openSection = props.openSection;
  const activeSection = sections.find((section) => section.id === activeSectionId);
  return (
    <div>
      {sections.map((section) => (
        <div key={section.id}>
          <div onClick={() => (activeSectionId === section.id ? openSection(undefined) : openSection(section.id))}>
            <TitleContainer>
              <SectionTitle>{section.title}</SectionTitle>
              {activeSection && activeSection.id === section.id ? (
                <SectionTitle>-</SectionTitle>
              ) : (
                <SectionTitle>+</SectionTitle>
              )}
            </TitleContainer>
          </div>
          {activeSection && activeSection.id === section.id && (
            <SectionDesc>{activeSection.descriptionContent()}</SectionDesc>
          )}
          <HorizontalDivider />
        </div>
      ))}
    </div>
  );
};
