import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import Hand from "./Hand";
import DrawAndDiscard from "./DrawAndDiscard";
import { ENABLE_DEBUGGING, Player } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  canSlam,
  getBoardPlayers,
  getCurrentPlayer,
  getRoomCode,
  getSelf,
  hasGameLoaded,
  isSidebarOpen,
} from "../redux/selectors";
import DrawModal from "../modals/DrawModal";
import {
  sidebarOpenOffset,
  sidebarWidth,
  snackbarHeight,
  mobileWidth,
  tabletWidth, boardMargin, adHeight
} from "../layout";
import BotPlayer from "./BotPlayer";
import Sounds from "./Sounds";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import {toggleDebug, updateCircleCoordinatees} from "../redux/actions";
import useWindowSize from "react-use/lib/useWindowSize";
import Radar from "./Radar";

interface MinimiseProps {
  minimise: boolean;
}

const Wrapper = styled.div<MinimiseProps>`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: calc(100vw - ${sidebarWidth}px);
  transition: transform 0.2s ease;
  transform: ${({ minimise }) => minimise && `translateX(${sidebarOpenOffset}px)`};
  min-height: 100vh;
  @media (max-width: ${tabletWidth}px) {
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Circle = styled.div`
  background: #4c4e5c;
  border-radius: 50%;
  height: calc( 100vmin - ${snackbarHeight}px - ${boardMargin}px);
  width: calc( 100vmin - ${snackbarHeight}px - ${boardMargin}px);
  height: calc( min( calc(100vw - ${sidebarWidth}px), 100vh) - ${snackbarHeight}px - ${boardMargin}px);
  width: calc( min( calc(100vw - ${sidebarWidth}px), 100vh) - ${snackbarHeight}px - ${boardMargin}px);
  border: solid 2px #f5f5f5;
  margin: auto;
  position: relative;
  @media (max-width: ${tabletWidth}px) {
    height: calc(100vmin - ${boardMargin}px - ${snackbarHeight}px);
    width: calc(100vmin - ${boardMargin}px - ${snackbarHeight}px);
  }
  @media (max-width: ${mobileWidth}px) {
    height: calc(100vmin - ${boardMargin}px);
    width: calc(100vmin - ${boardMargin}px);
    height: calc( min( 100vw, calc(100vh - ${snackbarHeight}px - ${adHeight}px)) - ${boardMargin}px);
    width: calc( min( 100vw, calc(100vh - ${snackbarHeight}px - ${adHeight}px)) - ${boardMargin}px);
  }
  @media (min-width: ${mobileWidth}px) {
    transform: translateY(-${snackbarHeight / 2}px);
  }
`;

interface Props {}

const Board: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const roomCode = useSelector(getRoomCode);
  const self = useSelector(getSelf);
  const currentPlayer = useSelector(getCurrentPlayer);
  // useSelector(isSidebarOpen); // rerender on sidebar state for coordinate update

  const ref = useRef(null);
  const canPlayerSlam = useSelector(canSlam);
  useWindowSize(); // rerender on resize

  useEffect(() => {
    const keyboardShortcuts = (e: any) => {

      if (!ENABLE_DEBUGGING) {
        return;
      }
      if (e.code === "KeyH") {
        dispatch(toggleDebug());
      }
    };

    window.addEventListener("keydown", keyboardShortcuts);

    return () => {
      window.removeEventListener("keydown", keyboardShortcuts);
    };
  }, [dispatch, roomCode, currentPlayer, canPlayerSlam, self]);

  const players = useSelector(getBoardPlayers);
  const selfIndex = players.findIndex((player) => player?.id === self);
  const last = players.length;

  const rotated = [...players.slice(selfIndex, last), ...players.slice(0, selfIndex)];

  const minimise = !useSelector(isSidebarOpen);

  const gameLoaded = useSelector(hasGameLoaded);
  // @ts-ignore
  const rects = ref?.current?.getBoundingClientRect();

  useEffect(() => {
      if (rects?.top) {
        dispatch(updateCircleCoordinatees(rects));
      }
  }, [rects?.top, rects?.left, rects?.right, rects?.bottom, rects?.height, rects?.width]);

  if (!gameLoaded) {
    return null;
  }
  return (
    <Wrapper minimise={minimise}>
      <BotPlayer />
      <Sounds />
      <Circle ref={ref}>
        {rotated.map((player: Player, i: number) => {
          return <Radar key={player?.id} radarIndex={i} playerID={player?.id}/>;
        })}
        {rotated.map((player: Player, i: number) => {
          return <Hand player={player} key={player?.id} index={i} />;
        })}
        <DrawAndDiscard />
        <DrawModal />
      </Circle>
    </Wrapper>
  );
};

export default Board;
