export const SET_QUICK_PLAY_NAME = "SET_QUICK_PLAY_NAME";
export const SET_GAME_STATE = "SET_GAME_STATE";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_OWNER = "SET_OWNERS";
export const SET_SELF = "SET_SELF";
export const SET_ROOM_CODE = "SET_ROOM_CODE";
export const SET_TURN_STATE = "SET_TURN_STATE";
export const SET_MODAL_IS_OPEN = "SET_MODAL_IS_OPEN";
export const SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE";
export const ADD_SELECTED_CARD = "ADD_SELECTED_CARD";
export const WIPE_SELECTED_CARDS = "WIPE_SELECTED_CARDS";

export const TOGGLE_DEBUG = 'TOGGLE_DEBUG';
export const START_GAME_STATE_UPDATE = 'START_GAME_STATE_UPDATE';
export const END_GAME_STATE_UPDATE = 'END_GAME_STATE_UPDATE';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_SCORES = "SET_SCORES";

export const TOGGLE_LEADER_BOARD = 'TOGGLE_LEADER_BOARD';
export const ENABLE_TIMEOUT_WARNING = 'ENABLE_TIMEOUT_WARNING';
export const DISABLE_TIMEOUT_WARNING = 'DISABLE_TIMEOUT_WARNING';
export const ENABLE_SLAM_TIMEOUT_WARNING = 'ENABLE_SLAM_TIMEOUT_WARNING';
export const DISABLE_SLAM_TIMEOUT_WARNING = 'DISABLE_SLAM_TIMEOUT_WARNING';

export const UPDATE_CIRCLE_COORDINATES = 'UPDATE_CIRCLE_COORDINATES';

export const SET_MODAL_OFFSETS = "SET_MODAL_OFFSETS";
export const SET_ROUND_SAVED = "SET_ROUND_SAVED";
