import React from "react";
import { TinyOutlineButton } from "../StyledComponents";
import { setModalOpen } from "../../redux/actions";
import { ModalID } from "../../constants";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import YoutubeVideo from "../YoutubeVideo";
import { tabletWidth } from "../../layout";

const Ul = styled.ul`
  margin: 5px;
  padding-inline-start: 10px;
`;

export const GameObjective: React.FunctionComponent = () => (
  <div>
    {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
    {/*<br />*/}
    {/*<br />*/}
    To have the least number of points in your hand at the end of each round.
    <br />
    <br />
    Each player begins the game with a score of 0. At the end of each round, your points are totalled and added to your
    score.
    <br />
    <br />
    The player with the lowest score after a set number of rounds (5 or 10) is the winner!
  </div>
);

export const Setup: React.FunctionComponent = () => (
  <div>
    {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
    {/*<br />*/}
    {/*<br />*/}
    Each player starts with 4 cards face down in front of them (their hand) with the rest of the cards face down in the
    deck.
    <br />
    <br />
    Each player then looks at, memorises and puts back the bottom two cards in their hand – without their opponent(s)
    seeing.
    <br />
    <br />
    Player one then draws a card from the deck. Each card denotes an action...
  </div>
);

export const Action: React.FunctionComponent = () => (
  <div>
    {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
    {/*<br />*/}
    {/*<br />*/}
    <b>Ace, 2, 3, 4, 5, 6, Joker, Red King = “Replace”.</b> <br />
    Replace one of the cards in your hand with the drawn card. The replaced card goes onto the discard pile.
    <br />
    <br />
    <b>7 or 8 = “Look At Own Card”.</b> <br />
    Place directly onto the discard pile and then look at a card in your hand.
    <br />
    <br />
    <b>9 or 10 = “Look At Opponent’s Card”.</b> <br />
    Place directly onto the discard pile and then look at a card in an opponent’s hand.
    <br />
    <br />
    <b>Jack, Queen, Black King = “Blind Swap”.</b> <br />
    Place directly onto the discard pile and then swap a card in your hand with a card in your opponent’s hand.
  </div>
);

const SlammingInfoButton = styled(TinyOutlineButton)`
  @media (max-width: ${tabletWidth}px) {
    display: none;
  }
`;

export const Slamming: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  return (
    <div>
      {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
      {/*<br />*/}
      {/*<br />*/}
      As soon as a card is placed on the discard pile, if you believe there is a card in your hand or an opponent’s hand
      that matches that card in rank (i.e. the number / royalty), you can “Slam”.
      <br />
      <br />
      Press the Slam button and select the card you believe matches in rank. If the card matches, the Slam is correct.
      <br />
      <br />
      Slamming from your hand:
      <Ul>
        <li>A correct Slam removes the Slam card from your hand. Fantastic! (-1 card in your hand)</li>
        <li>
          An incorrect Slam means you must place the incorrect Slam card back into your hand and also take an extra card
          from the deck for your hand as a penalty. Better luck next time! (+1 card in your hand)
        </li>
      </Ul>
      <div style={{ textAlign: "center" }}>
        <SlammingInfoButton
          onClick={() => {
            dispatch(setModalOpen(ModalID.SlammingInfoModal, true));
          }}
        >
          See more
        </SlammingInfoButton>
      </div>
      Slamming from an opponent’s hand:
      <Ul>
        <li>
          A correct Slam means you transfer one of the cards in your hand to the opponent’s hand. Fantastic! (-1 card in
          your hand)
        </li>
        <li>
          An incorrect Slam means you return the card you attempted to Slam back to the opponent’s hand, and then take
          an extra card from the deck to put into your hand as a penalty. Better luck next time! (+1 card in your hand)
        </li>
      </Ul>
      <div style={{ textAlign: "center" }}>
        <SlammingInfoButton
          onClick={() => {
            dispatch(setModalOpen(ModalID.SlammingInfoOpponentModal, true));
          }}
        >
          See more
        </SlammingInfoButton>
      </div>
      After one Slam, it is not possible to Slam again that turn. Once a player has drawn their card from the deck;
      carried out the required actions; and either no one attempts a Slam or a correct Slam has been played; it is the
      turn of the next player.
    </div>
  );
};

export const EndRound: React.FunctionComponent = () => (
  <div>
    {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
    {/*<br />*/}
    {/*<br />*/}
    If a player thinks they have the least points in their hand, at the start of their next turn they call “Rafiki”
    (calling Rafiki is a turn in itself).
    <br />
    <br />
    This can be done at any time during the game after each player has had three turns. If a player has no more cards in
    their hand, this is an automatic Rafiki at the beginning of their next turn.
    <br />
    <br />
    Following a Rafiki call, each opponent has one more turn, but the hand of the Rafiki caller is protected (i.e. no
    cards can be added to, removed from, looked at or swapped with their hand).
    <br />
    <br />
    Each player then reveals their hand and each player’s points are tallied…
  </div>
);

export const Scoring: React.FunctionComponent = () => (
  <div>
    {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
    {/*<br />*/}
    {/*<br />*/}
    Each card represents the following points:
    <Ul>
      <li>Jokers and Red Kings = -1</li>
      <li>Ace = +1</li>
      <li>2 – 10 = +face value</li>
      <li>Royal cards (except Red Kings) = +10</li>
      <li>Premature Rafiki* = +10</li>
    </Ul>
    *If it transpires the Rafiki caller does not have the least points in their hand at the end of a round, they deserve
    a penalty! This penalty applies if the Rafiki caller’s score is the same as an opponent’s.
    <br />
    <br />
    Each player begins the game with a score of 0. At the end of each round the points are calculated and added to the
    cumulative score for each player.
    <ul>
      <li>
        For example, a points total of 7 on an existing score of 0 gives a new score total of 7; a points total of -2 on
        an existing score of 50 gives a new score total of 48.
      </li>
    </ul>
  </div>
);

export const EndGame: React.FunctionComponent = () => (
  <div>
    {/*<YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />*/}
    {/*<br />*/}
    {/*<br />*/}
    After a set number of rounds (5 or 10), the player with the least points wins!
    <br />
    <br />
    If it is a draw (players finish on the same score), the drawing players enter a winner-takes-all round of sudden
    death.
  </div>
);

export const Tutorial: React.FunctionComponent = () => (
  <div>
    <YoutubeVideo src={"https://www.youtube.com/embed/sJ6kyZtVeG4"} width={"322"} height={"181"} />
  </div>
);

export const Forum: React.FunctionComponent = () => (
  <div>
    Join us on our{" "}
    <a href={"https://discord.gg/64g2kZtnxH"} target={"_blank"} rel={"noopener noreferrer"}>
      Discord Server
    </a>
  </div>
);
