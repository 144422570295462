// Most of these arent used
export const colorPalette = {
  lightLavender: "#e2e3ea",
  lavender: "#b8bbd5",
  grey: "#4c4e5c",
  mediumGrey: "#383b49",
  darkGrey: "#2c2f3d",
  yellow: "#fdcc68",
  yellowHover: "#fcd88a",
};
