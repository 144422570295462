import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import { devToolsEnhancer } from "redux-devtools-extension";
import thunkMiddleware from 'redux-thunk';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer, /* preloadedState */
    composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
        )
    )
);

export default store;
