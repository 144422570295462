import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  gameNotStarted,
  getGameScoreboard,
  isSidebarOpen,
  leaderboardIntersectingBoard
} from "../redux/selectors";
import styled from "styled-components";
// @ts-ignore
import PlusCircle from "react-feather/dist/icons/plus-circle";
// @ts-ignore
import MinusCircle from "react-feather/dist/icons/minus-circle";
import { MinimiseProps } from "./StyledComponents";
import {State} from "../redux/types";
import useWindowSize from "react-use/lib/useWindowSize";

interface MyProps {}

interface WrapperProps extends MinimiseProps {
  open: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  color: white;
  z-index: 10;
  transition: transform 0.2s ease;
  padding: 0 12px;
  margin-left: -12px;
`;

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;

const Thead = styled.thead`
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: -0.61px;
  line-height: 1.2;
  font-weight: bold;
`;

interface TBodyProps {
  open: boolean;
}

const Tbody = styled.tbody<TBodyProps>`
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: -0.61px;
  line-height: 1.6;
  margin-top: 10px;
  ${({ open }) => !open && `
    visibility: hidden;
    pointer-events: none;
  `}
`;

const TdLeft = styled.td`
  padding-left: 15px;
`;

const TdRight = styled.td`
  text-align: right;
`;

const Title = styled.div`
  display: flex;
`;

const GameLeaderBoard: React.FC<MyProps> = () => {
  const ref = useRef(null);
  const scoreboard = useSelector(getGameScoreboard);
  const minimise = !useSelector(isSidebarOpen);
  const hasNotStarted = useSelector(gameNotStarted);
  useWindowSize(); // rerender on resize
  // @ts-ignore
  const rects = ref?.current?.getBoundingClientRect();

  const intersecting = useSelector((state: State) => leaderboardIntersectingBoard(state, rects));
  const [isOpen, setOpen] = useState(true);
  const open = isOpen && !intersecting;

  // useClickAway(ref, () => setOpen(false));

  if (hasNotStarted) {
    return null;
  }
  return (
    <Wrapper
      minimise={minimise}
      ref={ref}
      onClick={() => {
        isOpen ? setOpen(false) : setOpen(true);
      }}
      open={open}
    >
      <Table>
        <Thead>
          <tr>
            <td>
              <Title>
                {open ? (
                  <MinusCircle stroke={"#e2e3ea"} height={'0.9375rem'} width={'0.9375rem'} />
                ) : (
                  <PlusCircle stroke={"#e2e3ea"} height={'0.9375rem'} width={'0.9375rem'} />
                )}
                &nbsp;Leaderboard
              </Title>
            </td>
            {open && <TdRight>Score</TdRight>}
          </tr>
        </Thead>
        {(
          <Tbody open={open}>
            {scoreboard?.map((player) => {
              return (
                <tr key={player?.id}>
                  <TdLeft>&nbsp;{player?.name}</TdLeft>
                  <TdRight>{player?.score}</TdRight>
                </tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </Wrapper>
  );
};

export default GameLeaderBoard;
