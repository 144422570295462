// import from "./Flashbang-Rafiki-cards-56.svg";
import back from "./Flashbang-Rafiki-cards_back.svg";
import redBack from "./Flashbang-Rafiki-cards_red_back.svg";
import joker from "./Flashbang-Rafiki-cards_black-joker.svg";
import clubs10 from "./Flashbang-Rafiki-cards_clubs-10.svg";
import clubs2 from "./Flashbang-Rafiki-cards_clubs-2.svg";
import clubs3 from "./Flashbang-Rafiki-cards_clubs-3.svg";
import clubs4 from "./Flashbang-Rafiki-cards_clubs-4.svg";
import clubs5 from "./Flashbang-Rafiki-cards_clubs-5.svg";
import clubs6 from "./Flashbang-Rafiki-cards_clubs-6.svg";
import clubs7 from "./Flashbang-Rafiki-cards_clubs-7.svg";
import clubs8 from "./Flashbang-Rafiki-cards_clubs-8.svg";
import clubs9 from "./Flashbang-Rafiki-cards_clubs-9.svg";
import clubsA from "./Flashbang-Rafiki-cards_clubs-ace.svg";
import clubsJ from "./Flashbang-Rafiki-cards_clubs-jack.svg";
import clubsK from "./Flashbang-Rafiki-cards_clubs-king.svg";
import clubsQ from "./Flashbang-Rafiki-cards_clubs-queen.svg";
import diamonds10 from "./Flashbang-Rafiki-cards_diamonds-10.svg";
import diamonds2 from "./Flashbang-Rafiki-cards_diamonds-2.svg";
import diamonds3 from "./Flashbang-Rafiki-cards_diamonds-3.svg";
import diamonds4 from "./Flashbang-Rafiki-cards_diamonds-4.svg";
import diamonds5 from "./Flashbang-Rafiki-cards_diamonds-5.svg";
import diamonds6 from "./Flashbang-Rafiki-cards_diamonds-6.svg";
import diamonds7 from "./Flashbang-Rafiki-cards_diamonds-7.svg";
import diamonds8 from "./Flashbang-Rafiki-cards_diamonds-8.svg";
import diamonds9 from "./Flashbang-Rafiki-cards_diamonds-9.svg";
import diamondsA from "./Flashbang-Rafiki-cards_diamonds-ace.svg";
import diamondsJ from "./Flashbang-Rafiki-cards_diamonds-jack.svg";
import diamondsK from "./Flashbang-Rafiki-cards_diamonds-king.svg";
import diamondsQ from "./Flashbang-Rafiki-cards_diamonds-queen.svg";
import hearts10 from "./Flashbang-Rafiki-cards_hearts-10.svg";
import hearts2 from "./Flashbang-Rafiki-cards_hearts-2.svg";
import hearts3 from "./Flashbang-Rafiki-cards_hearts-3.svg";
import hearts4 from "./Flashbang-Rafiki-cards_hearts-4.svg";
import hearts5 from "./Flashbang-Rafiki-cards_hearts-5.svg";
import hearts6 from "./Flashbang-Rafiki-cards_hearts-6.svg";
import hearts7 from "./Flashbang-Rafiki-cards_hearts-7.svg";
import hearts8 from "./Flashbang-Rafiki-cards_hearts-8.svg";
import hearts9 from "./Flashbang-Rafiki-cards_hearts-9.svg";
import heartsA from "./Flashbang-Rafiki-cards_hearts-ace.svg";
import heartsJ from "./Flashbang-Rafiki-cards_hearts-jack.svg";
import heartsK from "./Flashbang-Rafiki-cards_hearts-king.svg";
import heartsQ from "./Flashbang-Rafiki-cards_hearts-queen.svg";
import redJoker from "./Flashbang-Rafiki-cards_red-joker.svg";
import spades10 from "./Flashbang-Rafiki-cards_spades-10.svg";
import spades2 from "./Flashbang-Rafiki-cards_spades-2.svg";
import spades3 from "./Flashbang-Rafiki-cards_spades-3.svg";
import spades4 from "./Flashbang-Rafiki-cards_spades-4.svg";
import spades5 from "./Flashbang-Rafiki-cards_spades-5.svg";
import spades6 from "./Flashbang-Rafiki-cards_spades-6.svg";
import spades7 from "./Flashbang-Rafiki-cards_spades-7.svg";
import spades8 from "./Flashbang-Rafiki-cards_spades-8.svg";
import spades9 from "./Flashbang-Rafiki-cards_spades-9.svg";
import spadesA from "./Flashbang-Rafiki-cards_spades-ace.svg";
import spadesJ from "./Flashbang-Rafiki-cards_spades-jack.svg";
import spadesK from "./Flashbang-Rafiki-cards_spades-king.svg";
import spadesQ from "./Flashbang-Rafiki-cards_spades-queen.svg";
import transparent from "./transparent.svg";

export {
  back,
  clubs2,
  clubs3,
  clubs4,
  clubs5,
  clubs6,
  clubs7,
  clubs8,
  clubs9,
  clubs10,
  clubsA,
  clubsJ,
  clubsQ,
  clubsK,
  diamonds2,
  diamonds3,
  diamonds4,
  diamonds5,
  diamonds6,
  diamonds7,
  diamonds8,
  diamonds9,
  diamonds10,
  diamondsA,
  diamondsJ,
  diamondsQ,
  diamondsK,
  hearts2,
  hearts3,
  hearts4,
  hearts5,
  hearts6,
  hearts7,
  hearts8,
  hearts9,
  hearts10,
  heartsA,
  heartsJ,
  heartsQ,
  heartsK,
  spades2,
  spades3,
  spades4,
  spades5,
  spades6,
  spades7,
  spades8,
  spades9,
  spades10,
  spadesA,
  spadesJ,
  spadesQ,
  spadesK,
  joker,
  redJoker,
  transparent,
  redBack
};
