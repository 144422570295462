import React from "react";

import { Card } from "../components/Card";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {getDraw, getRoomCode, getSelf, isMyTurn, isUpdatingGameState, showDrawModal} from "../redux/selectors";
import { discardCard } from "../redux/actions";
import { getCardAction } from "../utils";
import { TurnState } from "../constants";

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
`;

const DrawModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(showDrawModal);
  const cardValue = useSelector(getDraw);
  const roomCode = useSelector(getRoomCode);
  const self = useSelector(getSelf);
  const updatingGameState = useSelector(isUpdatingGameState);
  const myTurn = useSelector(isMyTurn);

  const onClick = async () => {
    if (updatingGameState) return;
    const action = getCardAction(cardValue);
    if (myTurn && action !== TurnState.Replace) {
      dispatch(discardCard(roomCode, self));
    }
  };
  return isOpen ? (
    <Wrapper onClick={onClick}>
      <Card playerID={"drawn"} value={cardValue} size={"large"} />
    </Wrapper>
  ) : (
    <div />
  );
};

export default DrawModal;
