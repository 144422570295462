import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {initiateSlam} from "../redux/actions";
import {canSlam, getRoomCode, getSelf, isUpdatingGameState} from "../redux/selectors";
import {StyledButton} from "./StyledComponents";
import styled from "styled-components";

// import { ModalID } from "../constants";

interface MyProps {
}

const CustomButton = styled(StyledButton)`
    grid-row: 3;
    -ms-grid-row: 3;
`;

const SlamButton: React.FC<MyProps> = (props) => {
    const dispatch = useDispatch();
    const self = useSelector(getSelf);
    const roomCode = useSelector(getRoomCode);
    const updatingGameState = useSelector(isUpdatingGameState);

    const canPlayerSlam = useSelector(canSlam);

    const onClick = () => {
        if (updatingGameState) return;
        if (!canPlayerSlam) {
            return;
        }
        dispatch(initiateSlam(roomCode, self));
    };

    return <CustomButton onClick={onClick} disabled={!canPlayerSlam} className={'block'}>
        Slam
    </CustomButton>;
};

export default SlamButton;
