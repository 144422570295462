import React, { useEffect, useState } from "react";
import { Container, FormButton, Main, TopLeftContainer, TopLeftTitle } from "./components/StyledComponents";
import logo from "./assets/rafiki-logo_red.svg";
import styled from "styled-components";
import { createRoom, setModalOpen } from "./redux/actions";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import { useHistory } from "react-router";
import { isSidebarOpen } from "./redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import Page from "./components/Page";
import JoinGameModal from "./modals/JoinGameModal";
import { ModalID } from "./constants";
import { Helmet } from "react-helmet";
import { mobileWidth, tabletWidth } from "./layout";
import { ReactComponent as PlayIcon } from "./assets/play.svg";
import diamond from "./assets/diamond.svg";
import spade from "./assets/spade.svg";
import heart from "./assets/heart.svg";
import club from "./assets/club.svg";
import { colorPalette } from "./styleguide";
import YoutubeVideoJsApi from "./components/YoutubeVideoJsApi";
import {isAnonymous} from "./utils";

const CTA = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CreateGameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  max-width: min(100%, 1030px);
  width: 65vw;
  margin: 0 auto;
  @media (max-width: ${tabletWidth}px) {
    width: 80vw;
  }
`;

const Margin = styled.div`
  margin-right: 15px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const AnotherAccount = styled(TopLeftTitle)`
  font-weight: 300;
  line-height: 1.6;
  cursor: pointer;
  font-style: italic;
`;

const FixedWidthButton = styled(FormButton)`
  white-space: nowrap;
  width: 220px;
  @media (max-width: ${tabletWidth}px) {
    width: 150px;
  }
  @media (max-width: ${mobileWidth}px) {
    width: 120px;
  }
`;

const OutlinedFixedWidthButton = styled(FixedWidthButton)`
  background-color: transparent;
  border: solid 1px #fecf6f;
  color: #fecf6f;
  &:hover {
    background-color: transparent;
    color: ${colorPalette.yellowHover};
  }
  ${({ active }) =>
    active &&
    `
      background-color: #fecf6f;
      border: solid 1px #fecf6f;
      color: #2c2f3e;
  `}
  &:disabled:hover {
    background-color: transparent;
    cursor: not-allowed;
  }
`;

const VideoContainerWithControls = styled.div`
  margin-top: 90px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${mobileWidth}px) {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px 2px rgba(0,0,0,0.12);
`;

const YoutubeVideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.2%; // 16:9
  width: 100%;
  height: 0;
`;

const stripHeight = '3rem';
const stripHeightMobile = '2rem';
const LogoContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  height: calc(63% - ${stripHeight});
  padding-top: 9%;
  @media (max-width: ${mobileWidth}px) {
    height: calc(63% - ${stripHeightMobile});
  }
`;

const Logo= styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const SuitsContainer = styled.div`
  height: 18%;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 4%;
`;

const Suits = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Suit = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
  @media (max-width: ${mobileWidth}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  color: #fecf6f;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  gap: 5px;
  padding: 15px;
  box-shadow: 0 2px 4px 2px rgba(0,0,0,0.12);
  &:hover {
    background: #434553;
    cursor: pointer;
    user-select: none;
  }
  @media (max-width: ${mobileWidth}px) {
    padding: 10px;
  }
`;
const VideoCardTitle = styled.div`
  display: block;
  font-weight: 600;
`;

const StyledPlayIcon = styled(PlayIcon)`
  width: 1.5em;
  height: 1.5em;
`;

const VideoCardPlayIcon = styled(PlayIcon)`
  width: 30px;
  height: 30px;
  @media (max-width: ${mobileWidth}px) {
    width: 20px;
    height: 20px;
  }
`;

const Strip = styled.div`
  padding: 0 22px;
  display: flex;
  height: ${stripHeight};
  color: #2c2f3e;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  background-color: #fecf6f;
  align-items: center;
  gap: 8px;
  width: 100%;
  white-space: nowrap;
  &:hover {
    color: #434553;
    cursor: pointer;
    user-select: none;
    background-color: ${colorPalette.yellowHover};
  }
  @media (max-width: ${mobileWidth}px) {
    height: ${stripHeightMobile};
    font-size: 1rem;
  }
`;

const CreateGameMain = styled(Main)`
  @media (max-width: ${mobileWidth}px) {
    width: 100vw;
    padding-left: 30px;
    padding-right: 30px;
  }
`

let videos = [
  { title: "Aim of the game", duration: 20, startTime: 0 },
  { title: "How to set up", duration: 15, startTime: 0 },
  { title: "Actions", duration: 74, startTime: 0 },
  { title: "Slamming", duration: 41, startTime: 0 },
  { title: "Calling RAFIKI", duration: 43, startTime: 0 },
  { title: "How to win", duration: 48, startTime: 0 },
];

let startTime = 0;
videos = videos.map((video) => {
  video.startTime = startTime;
  startTime += video.duration;
  return video;
});

function getDurationString(seconds: number) {
  return `${Math.floor(seconds / 60)}:${seconds % 60}`;
}

function CreateGame() {
  const [me, setMe] = useState("");
  const history = useHistory();
  const minimise = !useSelector(isSidebarOpen);
  const dispatch = useDispatch();
  const [showVideo, setShowVideo] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) return;
      const db = firebase.firestore();
      const ref = db.collection("players").doc(user?.uid);
      ref.get().then((player) => {
        setMe(player.data()?.name);
      });
    });
  }, []);

  return (
    <Page>
      <CreateGameMain>
        <Helmet>
          <title>Rafiki - Create Game</title>
          <meta name={"description"} content={"Play with friends, win with masters"} />
        </Helmet>
        <TopLeftContainer>
          <TopLeftTitle>{me && `Welcome, ${me}!`}</TopLeftTitle>
          <AnotherAccount
            onClick={() => {
              firebase
                .auth()
                .signOut()
                .then(() => {
                  firebase.analytics().logEvent("logout");
                  window.location.href = "/";
                });
            }}
          >
            {me && `Sign in with a different account`}
          </AnotherAccount>
        </TopLeftContainer>
        <Container
          minimise={minimise}
          style={{
            justifyContent: "space-between",
          }}
        >
          <CreateGameContainer>
            <VideoContainerWithControls>
              <YoutubeVideoContainer>
                <VideoContainer>
                  <LogoContainer>
                      <Logo src={logo} alt="rakifi-logo" />
                  </LogoContainer>
                    <SuitsContainer>
                      <Suits>
                        <Suit src={diamond} alt="diamond" />
                        <Suit src={spade} alt="spade" />
                        <Suit src={heart} alt="heart" />
                        <Suit src={club} alt="club" />
                      </Suits>
                    </SuitsContainer>
                  <Strip onClick={() => setShowVideo(true)}>
                    <StyledPlayIcon />
                    WATCH: How to play (2:15)
                  </Strip>
                </VideoContainer>
                {showVideo && <YoutubeVideoJsApi videoID={`BdYJWT94Xx4`} seekTo={startTime} onPlayerReady={() => setLoading(false)}/>}
              </YoutubeVideoContainer>
              <CardContainer>
                {videos.map((video) => {
                  return (
                    <VideoCard
                      key={video.title}
                      onClick={() => {
                        if (loading) return;
                        if (!showVideo) {
                          setLoading(true)
                        }
                        setShowVideo(true)
                        setStartTime(video.startTime);
                      }}
                    >
                      <VideoCardPlayIcon />
                      <div>
                        <VideoCardTitle>{video.title}</VideoCardTitle>
                        <div>({getDurationString(video.duration)})</div>
                      </div>
                    </VideoCard>
                  );
                })}
              </CardContainer>
            </VideoContainerWithControls>
            <CTA>
              <Margin>
                <OutlinedFixedWidthButton
                  disabled={isAnonymous()}
                  onClick={async () => {
                    dispatch(setModalOpen(ModalID.JoinGameModal, true));
                  }}
                >
                  Join room
                </OutlinedFixedWidthButton>
              </Margin>
              <Margin>
                <FixedWidthButton
                  onClick={async () => {
                    const roomCode = await dispatch(createRoom());
                    if (roomCode) {
                      history.push(`/game/${roomCode}`);
                    }
                  }}
                >
                  Create game
                </FixedWidthButton>
              </Margin>
            </CTA>
          </CreateGameContainer>
        </Container>
        <JoinGameModal />
      </CreateGameMain>
    </Page>
  );
}

export default CreateGame;
