import styled from "styled-components";
import { colorPalette } from "../styleguide";
import {
  cardAspectRatio,
  cardGap,
  sidebarOpenOffset,
  sidebarWidth,
  desktopWidth,
  snackbarHeight,
  tabletWidth,
  mobileWidth,
  adHeight,
} from "../layout";
import { cardSizes } from "../constants";
import { Link as RouterLink } from "react-router-dom";

export const Title = styled.div`
  color: #e2e3ea;
  font-family: "Work Sans";
  font-size: 1.4375rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
`;

export const CapitalizedTitle = styled(Title)`
  text-transform: uppercase;
`;

export const Strapline = styled.div`
  color: #e2e3ea;
  font-family: "Work Sans";
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 35px;
`;

export const Slam = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background-color: #fecf6f;
  color: #2c2f3e;
  font-size: 33px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
    @media (max-width: ${tabletWidth}px) {
    font-size: 25px;
  }
  @media (max-width: ${mobileWidth}px) {
    font-size: 18px;
  }
`;

interface StyledButtonProps {
  active?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  color: #2c2f3e;
  background-color: #fecf6f;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.1;
  text-align: center;
  box-shadow: none;
  border: none;
  min-width: 195px;
  padding: 18px 18px;
  cursor: pointer;

  &.block {
    display: block;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:hover {
    background-color: ${colorPalette.yellowHover};
  }
  @media (max-width: ${mobileWidth}px) {
    font-size: 0.6875rem;
    padding: 12px;
    min-width: 130px;
  }
`;

export interface MinimiseProps {
  minimise: boolean;
}

interface ModalProps {
  minimise: boolean;
  red?: boolean;
  yellow?: boolean;
  translateX?: number;
  translateY?: number;
}

export const StyledModal = styled.div<ModalProps>`
  padding: 40px;
  position: absolute;
  background-color: ${colorPalette.darkGrey};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: ${colorPalette.lightLavender};
  border-radius: 26px;
  z-index: 500;
  top: calc(50% - ${snackbarHeight / 2}px);
  left: calc((100vw) / 4);
  width: calc((100vw) / 2);
  ${({ translateX, translateY, minimise }) => `
  
  transform: translateY(-50%) translateX(0px) translateX(${translateX}px) translateY(${translateY}px);
  @media (min-width: ${tabletWidth}px) {
    left: calc(50% - ${sidebarWidth / 2}px);
    max-width: 700px;
    width: calc(100vw - ${sidebarWidth});
    transform: translateY(-50%) translateX(-50%) translateX(${translateX}px) translateY(${translateY}px) ${minimise ? `translateX(${sidebarOpenOffset}px)` : `translateX(0px)`};
  }
  `}
  
  ${({ red }) =>
    red &&
    `
  box-sizing: border-box;
  border: 4px solid #a93646;
  border-radius: 26px;
  background-color: #2c2f3e;
  `}
  ${({ yellow }) =>
    yellow &&
    `
  box-sizing: border-box;
  border: 4px solid #fecf6f;
  border-radius: 26px;
  background-color: #2c2f3e;
  `}
  
  @media (max-width: ${tabletWidth}px) {
    left: 15vw;
    width: 70vw;
  }

  @media (max-width: ${mobileWidth}px) {
    left: 40px;
    width: calc(100vw - 80px);
    top: calc(50% - ${(snackbarHeight - adHeight)/ 2}px);
  }
`;

export const LargeStyledModal = styled(StyledModal)`
  left: calc((100vw) / 4);
  width: calc((100vw) / 2);
  top: calc(50% - ${snackbarHeight / 2}px);
  transform: translateY(-50%) translateX(0px);
  
  @media (min-width: ${tabletWidth}px) {
    left: calc((100vw - ${sidebarWidth}px) / 6);
    width: calc((100vw - ${sidebarWidth}px) / 1.5);
    transform: translateY(-50%) ${({ minimise }) => (minimise ? `translateX(${sidebarOpenOffset}px)` : `translateX(0px)`)};
  }
`;

export const PrimaryStyledButton = styled(StyledButton)`
  color: #f5f5f5;
  background-color: #d45062;

  &:focus {
    outline: dotted 2px #d45062;
  }
  &:hover {
    background-color: #e06475;
  }
  &:disabled:hover {
    background-color: #d45062;
  }
`;

export const OutlineButton = styled(StyledButton)`
  background-color: transparent;
  border: solid 2px #fecf6f;
  color: #fecf6f;
  &:hover {
    opacity: 0.3;
    background-color: transparent;
    color: #f5f5f5;
  }

  ${({ active }) =>
    active &&
    `
      background-color: #fecf6f;
      border: solid 2px #fecf6f;
      color: #2c2f3e;
  `}
`;

export const BoardLabel = styled.div`
  user-select: none;
  color: #e2e3ea;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.61px;
  line-height: 1.4;
  max-width: calc((100vmin - ${snackbarHeight}px) * ${cardSizes['medium'] * cardAspectRatio} / 100);
  max-width: calc((min(calc(100vw - ${sidebarWidth}px), 100vh) - ${snackbarHeight}px) * ${cardSizes['medium'] * cardAspectRatio} / 100);
  text-align: left;
  @media (max-width: ${desktopWidth}px) {
    font-size: 14px;
  }
  @media (max-width: ${tabletWidth}px) {
    font-size: 12px;
    line-height: 1;
  }
  @media (max-width: ${mobileWidth}px) {
    font-size: 9px;
  }

`;

export const ModalCards = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr ${cardGap}vmin 1fr;
  grid-gap: ${cardGap}vmin;
  -ms-grid-columns: 1fr ${cardGap}vmin 1fr;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
`;

export const CardPlaceholder = styled.div`
  height: calc(100vmin - ${snackbarHeight}px) * ${cardSizes.medium} / 100);
  height: calc((min(100vw - ${sidebarWidth}px, 100vh) - ${snackbarHeight}px) * ${cardSizes.medium} / 100);
  width: calc(
    (min(100vw - ${sidebarWidth}px, 100vh) - ${snackbarHeight}px) * ${cardSizes.medium} / 100 * ${cardAspectRatio}
  );
  width: calc( 100vmin - ${snackbarHeight}px) * ${cardSizes.medium} / 100 * ${cardAspectRatio} );
  @media (max-width: ${tabletWidth}px) {
    height: calc(100vmin - ${snackbarHeight}px) * ${cardSizes.medium} / 100);
    width: calc(100vmin - ${snackbarHeight}px) * ${cardSizes.medium} / 100 * ${cardAspectRatio});
  }
`;
export const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - ${sidebarWidth}px);
  font-weight: bold;
  color: ${colorPalette.lightLavender};
  @media (max-width: ${tabletWidth}px) {
    width: 100vw;
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (max-width: ${mobileWidth}px) {
    width: 100vw;
    padding-left: 60px;
    padding-right: 60px;
  }
`;
export const Subtitle = styled.div`
  color: #e2e3ea;
  font-family: "Work Sans";
  font-size: 1.4375rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: ${mobileWidth}px) {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
`;

export const LargeLogo = styled.img`
  margin-top: 129px;
  margin-bottom: 40px;
  width: 380px;
  max-width: 100%;
  @media (max-width: ${tabletWidth}px) {
    margin-top: 60px;
    margin-bottom: 20px;
    width: 300px;
  }
  @media (max-width: ${mobileWidth}px) {
    margin-top: 100px;
    margin-bottom: 20px;
    width: 220px;
    height: 120px;
  }
`;
export const FormLabel = styled.label`
  display: block;
  margin-bottom: 18px;
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
`;
export const TextField = styled.input`
  background-color: #2c2f3e;
  height: 40px;
  line-height: 40px;
  color: #e2e3ea;
  box-shadow: none;
  border: none;
  display: block;
  width: 488px;
  margin-bottom: 18px;
  color: #fecf6f;
  font-family: "Work Sans";
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding: 12px;
  max-width: 100%;
`;

export const LightTextField = styled(TextField)`
  background-color: #393c4b;
`

export const Checkbox = styled.input`
  height: 23px;
  width: 23px;
  background-color: #2c2f3e;
  margin: 0 10px 0 0;
`;
export const CheckboxLabel = styled.label`
  color: #e2e3ea;
  font-family: "Work Sans";
  font-size: 0.625rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
  flex: 1;
`;
export const FormButton = styled(PrimaryStyledButton)`
  display: block;
  background-color: #fecf6f;
  color: #2c2f3e;
  font-size: 1.15rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 18px 28px;
  min-width: 212px;
  @media (max-width: ${tabletWidth}px) {
    padding: 10px 17px;
    min-width: 150px;
  }
  @media (max-width: ${mobileWidth}px) {
    padding: 8px 14px;
    min-width: 100px;
  }
  &:focus {
    outline: dotted 2px #fecf6f;
  }
  &:hover {
    background-color: ${colorPalette.yellowHover};
  }
  &:disabled:hover {
    background-color: #fecf6f;
  }
`;

export const YellowText = styled.span`
  color: #fecf6f;
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: normal;
`;

export const Link = styled(RouterLink)`
  color: #fecf6f;
  font-size: inherit;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
export const CTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  line-height: 1.6;
`;

export const FormError = styled.div`
  margin: 20px;
  text-align: center;
  display: block;
  font-weight: bold;
  color: #fecf6f;
`;
export const TopLeftContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  z-index: 10;
`;
export const TopLeftTitle = styled.div`
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  word-break: none;
  display: flex;
`;
export const ListItem = styled.li`
  list-style: none;
  border-bottom: 1px solid #4c4e5c;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  height: 50px;
  @media (max-width: ${desktopWidth}px) {
    line-height: 45px;
    height: 45px;
  }
  @media (max-width: ${tabletWidth}px) {
    line-height: 40px;
    height: 40px;
  }
  @media (max-width: ${mobileWidth}px) {
    line-height: 35px;
    height: 35px;
  }
`;
export const UnorderedList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;
export const ListPlayerName = styled.div`
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  display: inline-block;
`;
export const ListText = styled.div`
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: right;
  display: inline-block;
  padding: 0 10px;
`;

export const TinyStyledButton = styled(StyledButton)`
  border-radius: 2px;
  font-size: 0.6875rem;
  padding: 8px 16px;
  min-width: 85px;
`;

export const TinyOutlineButton = styled(TinyStyledButton)`
  background-color: transparent;
  border: solid 1px #fecf6f;
  color: #fecf6f;

  ${({ active }) =>
    active &&
    `
      background-color: #fecf6f;
      border: solid 1px #fecf6f;
      color: #2c2f3e;
  `}
`;

export const Container = styled.div<MinimiseProps>`
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;
  transform: ${({ minimise }) => minimise && `translateX(${sidebarOpenOffset}px)`};
  max-width: 100%;
  text-align: left;
  min-height: 100vh;
  @media (max-width: ${tabletWidth}px) {
    transform: translateX(0);
  }
`;

export const AuthPageContainer = styled.div`
  width: 380px;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
`;

export const ButtonGroup = styled.div`
  & > button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  & > button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 470px;
  margin-bottom: 40px;
`;

export const Back = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  text-decoration: underline;
`;

export const FormSuccess = styled.div`
  height: 99px;
  width: 480px;
  color: #fecf6f;
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 30px;
`;

