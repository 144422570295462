import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card } from "../components/Card";
import {
  amIReady,
  getBottomTwoCards,
  getRoomCode,
  getSelf,
  isSidebarOpen,
  isUpdatingGameState,
  showViewTwoModal,
} from "../redux/selectors";
import { setReady } from "../redux/actions";
import { getPrettyCardDesc, initializeAudio } from "../utils";
import Modal from "./Modal";

import { FormButton, ModalCards, Strapline, Title } from "../components/StyledComponents";

const ViewTwoModal: React.FC = () => {
  const isOpen = useSelector(showViewTwoModal);
  const bottomTwoCards = useSelector(getBottomTwoCards);
  const minimise = !useSelector(isSidebarOpen);
  const dispatch = useDispatch();
  const roomCode = useSelector(getRoomCode);
  const self = useSelector(getSelf);
  const ready = useSelector(amIReady);
  const updatingGameState = useSelector(isUpdatingGameState);

  return isOpen && bottomTwoCards?.length === 2 ? (
    <Modal minimise={minimise}>
      <Title>Remember your bottom two cards</Title>
      <Strapline>
        Your bottom two cards are the {getPrettyCardDesc(bottomTwoCards[0])} and the{" "}
        {getPrettyCardDesc(bottomTwoCards[1])}
      </Strapline>
      <ModalCards>
        <Card playerID={"modal"} value={bottomTwoCards[0]} size="large" ieColumn={1} />
        <Card playerID={"modal"} value={bottomTwoCards[1]} size="large" ieColumn={3} />
      </ModalCards>

      <FormButton
        disabled={ready}
        onClick={() => {
          if (updatingGameState) return;
          dispatch(setReady(roomCode, self));
          initializeAudio();
        }}
      >
        Ready?
      </FormButton>
    </Modal>
  ) : (
    <div />
  );
};

export default ViewTwoModal;
