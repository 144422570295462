import {
  ADD_SELECTED_CARD,
  DISABLE_SLAM_TIMEOUT_WARNING,
  DISABLE_TIMEOUT_WARNING,
  ENABLE_SLAM_TIMEOUT_WARNING,
  ENABLE_TIMEOUT_WARNING,
  END_GAME_STATE_UPDATE,
  SET_GAME_STATE,
  SET_MODAL_IS_OPEN, SET_MODAL_OFFSETS,
  SET_OWNER,
  SET_PLAYERS, SET_QUICK_PLAY_NAME,
  SET_ROOM_CODE,
  SET_SCORES,
  SET_SELF,
  SET_SNACKBAR_MESSAGE,
  SET_TURN_STATE,
  START_GAME_STATE_UPDATE,
  TOGGLE_DEBUG,
  TOGGLE_LEADER_BOARD,
  TOGGLE_SIDEBAR,
  UPDATE_CIRCLE_COORDINATES,
  WIPE_SELECTED_CARDS,
} from "./actionTypes";
import {
  ACTIVITY_TIMEOUT,
  ANONYMOUS_USER_NAME,
  Card,
  ENABLE_DEBUGGING,
  GameState,
  IsPlaying,
  MAX_PLAYERS,
  ModalID,
  Move,
  MoveType,
  NOCARD,
  NoSlam,
  RAFIKI_PENALTY,
  Round,
  ROUNDS,
  Score,
  SlamState,
  TurnState,
} from "../constants";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import { deck } from "../svgExports";

import {cycleFirstTurn, generateRoomCode, InvalidMoveError, randomFirstTurn, reportError} from "../utils";
import { PlayerStatus, sum } from "../common";
import { getSlamPlayer, handScore, isSlamPending } from "./selectors";
import { BotDifficulty, BotOption, BotSpeed, PlayerMap } from "./types";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { advanceTurnAfterTimeout, gameReducer, incrementTurn, moveDiscardsToDeck, slamTimeoutState } from "./rafiki";
import { getMove, UNKNOWN, updateAllMemoriesForBots } from "../bot";

const updateActivity = (gameState: GameState, player: string) => {
  const activity = {
    ...gameState?.lastActivity,
    [player]: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  };

  return {
    ...gameState,
    timestamp: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    lastActivity: activity,
  };
};

// Owner
export const transferRoomOwnership = (roomCode: string, playerId: string) => {
  return function (dispatch: any, getState: any) {
    const db = firebase.firestore();
    return db
      .runTransaction(async (transaction: firebase.firestore.Transaction) => {
        const roomRef = db.collection("rooms").doc(roomCode);
        const room = await transaction.get(roomRef);
        const roomData = room.data();
        if (!roomData?.gameState?.playerIds.includes(playerId)) {
          return;
        }
        if (playerId === roomData.owner) {
          return;
        }
        transaction.update(roomRef, {
          owner: playerId,
        });
      })
      .then(() => {
        firebase.analytics().logEvent("transfer-ownership");
        dispatch(setModalOpen(ModalID.PassOwnershipModal, false));
      });
  };
};

export const createRoom = () => {
  return function (dispatch: any, getState: any) {
    const user = firebase.auth().currentUser;
    const userUid = user?.uid || ''

    const cardNames = Object.keys(deck);
    const gameState: GameState = {
      deck: cardNames,
      discardPile: [],
      drawnCard: "",
      playerRafiki: "",
      round: -1,
      finishedRounds: [],
      rounds: ROUNDS,
      turn: -1,
      loop: -1,
      firstTurn: 0,
      lastTurn: -1,
      lastLoop: -1,
      hands: {},
      bots: {},
      botMemory: {},
      slam: {
        slamState: SlamState.NoSlam,
        player: "",
      },
      turnState: TurnState.Draw,
      slammedCards: {},
      allPlayerIds: [userUid],
      playerIds: [userUid],
      leftPlayerIds: [],
      playerStatus: {
        [userUid]: PlayerStatus.Approved,
      },
      attempts: {},
      roundSaved: false,
      gameSaved: false,
      initialGameSaved: false,
      leaderBoardUpdated: false,
      failedSlamCount: {
        [userUid]: 0,
      },
      isPlaying: {
        [userUid]: true,
      },
      ready: {
        [userUid]: false,
      },
      paused: false,
      botSpeed: BotSpeed.Fast,
    };

    const db = firebase.firestore();
    dispatch(setModalOpen(ModalID.ApproveModal, true));
    return db
      .runTransaction(async (transaction: firebase.firestore.Transaction) => {
        let roomCode = generateRoomCode();
        let room = await transaction.get(db.collection("rooms").doc(roomCode));
        while (room.exists) {
          roomCode = generateRoomCode();
          room = await transaction.get(db.collection("rooms").doc(roomCode));
        }

        transaction.set(db.collection("rooms").doc(roomCode), {
          id: roomCode,
          gameState: gameState,
          owner: userUid,
        });
        return roomCode;
      })
      .then((roomCode) => {
        firebase.analytics().logEvent("create_room");
        return roomCode;
      });
  };
};

export const slamTimeoutMove = (gameState: GameState): GameState => {
  if (gameState?.paused) {
    return gameState;
  }
  const slamState = gameState?.slam?.slamState;
  if (
    [
      SlamState.SlamStart,
      SlamState.SlamFailureOwn,
      SlamState.SlamFailureOpponent,
      SlamState.SlamSuccessOpponent,
    ].includes(slamState)
  ) {
    const last = gameState?.discardPile?.length || 0;
    const topCard = gameState.deck?.[gameState?.deck?.length - 1] as string;
    const hand = [...gameState.hands?.[gameState?.slam?.player]];
    const index = gameState?.hands?.[gameState?.slam?.player].indexOf(NOCARD);
    const botMemory = { ...gameState?.botMemory };
    const botIds = Object.keys(gameState?.bots);
    let deck = [...gameState?.deck];
    if (index >= 0) {
      hand[index] = topCard;
      deck = deck.slice(0, deck.length - 1);

      botIds.forEach((botId: string) => {
        botMemory[botId][gameState?.slam?.player][index] = UNKNOWN;
      });
    } else {
      console.error("Incorrect slam penalty failed");
    }

    return moveDiscardsToDeck(
      slamTimeoutState({
        ...gameState,
        deck,
        botMemory: botMemory,
        hands: {
          ...gameState?.hands,
          [gameState?.slam?.player]: hand,
        },
      })
    );
  }
  return gameState;
};

export const clearSlamTimeout = (gameState: GameState): GameState => {
  if (gameState?.slam?.slamState === SlamState.SlamTimeout) {
    return {
      ...gameState,
      slam: {
        slamState: SlamState.NoSlam,
        player: "",
        slamDiscard: "",
      } as NoSlam,
    };
  }
  return gameState;
};

export const slamTimeout = (roomCode: string, lastMove: number, slamPlayer: string) => {
  return function (dispatch: any, getState: any) {
    const state = getState();
    const currentMove = state?.gameState?.timestamp?.seconds;
    if (currentMove !== lastMove || state?.gameState?.paused) {
      return;
    }
    dispatch(disableSlamTimeoutWarning());
    updateGameState(dispatch, getState, roomCode, slamTimeoutMove).then(() => {
      firebase.analytics().logEvent("timeout");
      dispatch(wipeSelectedCards());
    });
    setTimeout(() => {
      updateGameState(dispatch, getState, roomCode, clearSlamTimeout);
    }, 3000);
  };
};

export const nextTurnIfTimedOut = (roomCode: string, lastMove: number) => {
  return function (dispatch: any, getState: any) {
    const state = getState();
    const currentMove = state?.gameState?.timestamp?.seconds;
    if (currentMove !== lastMove || state?.gameState?.paused) {
      return;
    }
    dispatch(disableTimeoutWarning());
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        // Checking again with latest available value to make sure its atomic
        const currentMove = gameState?.timestamp?.seconds;
        if (currentMove !== lastMove || gameState?.paused) {
          return gameState;
        }
        return {
          ...advanceTurnAfterTimeout(gameState),
          timestamp: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        };
      }
    ).then(() => {
      firebase.analytics().logEvent("timeout");
      dispatch(wipeSelectedCards());
    });
  };
};

const getCurrentTime = () => {
  return Date.now() / 1000;
};

export const getPlayersToRemove = (gameState: GameState, owner: string, getCurrentTime: Function) => {
  const now = getCurrentTime();
  if (gameState.turn == -1) {
    return [];
  }
  if (gameState.paused) {
    return [];
  }
  if (!gameState?.playerIds?.every((player) => gameState?.ready?.[player])) {
    return [];
  }
  return gameState?.playerIds.filter((playerId: string) => {
    const lastMove = gameState?.lastActivity?.[playerId]?.seconds;
    return (
      lastMove &&
      lastMove < now - ACTIVITY_TIMEOUT &&
      playerId !== owner &&
      gameState?.isPlaying?.[playerId] &&
      !(playerId in gameState?.bots)
    );
  });
};

export const removeInactivePlayers = (roomCode: string, owner: string) => {
  let count = 0;
  return function (dispatch: any, getState: any) {
    const gameState = getState()?.gameState;

    const playersToRemove = getPlayersToRemove(gameState, owner, getCurrentTime);
    if (playersToRemove.length === 0) {
      return gameState;
    }

    updateGameState(dispatch, getState, roomCode, (gameState: GameState) => {
      const isPlaying = gameState?.isPlaying;

      const playersToRemove = getPlayersToRemove(gameState, owner, getCurrentTime);

      let containsCurrentPlayer = false;
      playersToRemove.forEach((p) => {
        isPlaying[p] = false;
        if (p === gameState?.playerIds?.[gameState?.turn]) {
          containsCurrentPlayer = true;
        }
      });
      count = playersToRemove.length;

      const newGameState = {
        ...gameState,
        isPlaying: isPlaying,
        previousTurn: {
          turnState: TurnState.RemoveInactive,
          playerIds: playersToRemove,
        },
      };
      if (containsCurrentPlayer) {
        // @ts-ignore
        return incrementTurn(newGameState);
      } else {
        return newGameState;
      }
    }).then(() => {
      if (count > 0) {
        firebase.analytics().logEvent("remove_inactive_player");
      }
    });
  };
};

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function testDeck() {
  return [
    "QD",
    "JD",
    "9D",
    "8D",
    "7D",
    "6D",
    "5D",
    "4D",
    "3D",
    "2D",
    "AD",
    "10D",
    "KS",
    "QS",
    "JS",
    "9S",
    "8S",
    "7S",
    "6S",
    "5S",
    "4S",
    "3S",
    "2S",
    "AS",
    "10S",
    "QH",
    "JH",
    "9H",
    "8H",
    "7H",
    "6H",
    "5H",
    "4H",
    "3H",
    "2H",
    "AH",
    "10H",
    "KC",
    "QC",
    "JC",
    "9C",
    "8C",
    "7C",
    "6C",
    "5C",
    "4C",

    "YJ",
    "XJ",
    "KH",
    "KD",

    "3C",
    "2C",
    "AC",
    "10C",
  ];
}

const newDeck = () => {
  const result = ["XJ", "YJ"];
  for (let suit of "CHSD".split("")) {
    for (let value of ["10", ..."A23456789JQK".split("")]) {
      result.push(value + suit);
    }
  }
  return result;
};

export const pauseGame = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        return {
          ...gameState,
          paused: true,
        };
      }
    ).then(() => {
      firebase.analytics().logEvent("pause");
    });
  };
};

export const resumeGame = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        const lastActivity: any = {};
        gameState?.playerIds.forEach((player) => {
          lastActivity[player] = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
        });
        return {
          ...gameState,
          lastActivity: lastActivity,
          timestamp: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
          paused: false,
        };
      }
    ).then(() => {
      firebase.analytics().logEvent("resume");
    });
  };
};

export const approvePlayer = (roomCode: string, playerId: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        gameState.playerStatus[playerId] = PlayerStatus.Approved;
        return {
          ...gameState,
          playerIds: uniq([...gameState?.playerIds, playerId]),
        };
      }
    ).then(() => {});
  };
};

export const denyPlayer = (roomCode: string, playerId: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        const attempts = gameState?.attempts?.[playerId] || 0;
        if (gameState?.playerStatus?.[playerId] === PlayerStatus.Denied) {
          return gameState;
        }
        gameState.playerStatus[playerId] = PlayerStatus.Denied;
        return {
          ...gameState,
          attempts: {
            [playerId]: attempts + 1,
          },
        };
      }
    ).then(() => {
      setTimeout(() => dispatch(sendToLimbo(roomCode, playerId)), 5000);
    });
  };
};

export const sendToLimbo = (roomCode: string, playerId: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        const attempts = gameState?.attempts?.[playerId];
        if (attempts >= 3) {
          return gameState;
        }
        gameState.playerStatus[playerId] = PlayerStatus.Limbo;
        return gameState;
      }
    ).then(() => {});
  };
};

export const addBot = (roomCode: string, bot: BotOption) => {
  return function (dispatch: any, getState: any) {
    return updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        if (gameState.playerIds.length >= MAX_PLAYERS) {
          return gameState;
        }
        const existingBotNames = Object.keys(gameState?.bots)?.map((botId) => gameState?.bots?.[botId]?.name);
        if (existingBotNames.includes(bot?.name)) {
          return gameState;
        }
        return {
          ...gameState,
          allPlayerIds: uniq([...gameState?.allPlayerIds, bot.id]),
          playerIds: uniq([...gameState?.playerIds, bot.id]),
          bots: {
            ...gameState.bots,
            [bot.id]: {
              id: bot.id,
              difficulty: bot.difficulty,
              name: bot.name,
            },
          },
          isPlaying: {
            ...gameState.isPlaying,
            [bot.id]: true,
          },
          playerStatus: {
            ...gameState.playerStatus,
            [bot.id]: PlayerStatus.Approved,
          },
        };
      }
    );
  };
};

export const renameBot = (roomCode: string, botId: string, name: string) => {
  return function (dispatch: any, getState: any) {
    return updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        const newBots = {
          ...gameState.bots,
          [botId]: {
            ...gameState?.bots?.[botId],
            name: name,
          },
        };
        return {
          ...gameState,
          bots: newBots,
        };
      }
    );
  };
};

export const setBotDifficulty = (roomCode: string, botId: string, difficulty: BotDifficulty) => {
  return function (dispatch: any, getState: any) {
    return updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        const newBots = {
          ...gameState.bots,
          [botId]: {
            ...gameState?.bots?.[botId],
            difficulty: difficulty,
          },
        };
        return {
          ...gameState,
          bots: newBots,
        };
      }
    );
  };
};
export const removeBot = (roomCode: string, botId: string) => {
  return function (dispatch: any, getState: any) {
    return updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        const newBots = {
          ...gameState.bots,
        };
        delete newBots[botId];
        return {
          ...gameState,
          playerIds: gameState.playerIds.filter((p) => p !== botId),
          allPlayerIds: gameState.allPlayerIds.filter((p) => p !== botId),
          bots: newBots,
        };
      }
    );
  };
};

export const retryEntry = (roomCode: string, playerId: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        gameState.playerStatus[playerId] = PlayerStatus.Pending;
        return gameState;
      }
    ).then(() => {});
  };
};

export const addPlayer = (roomCode: string, playerId: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        if (gameState?.allPlayerIds?.includes(playerId)) {
          return gameState;
        }
        const playerStatus = PlayerStatus.Pending
        return {
          ...gameState,
          allPlayerIds: uniq([...gameState?.allPlayerIds, playerId]),
          playerIds: gameState?.playerIds,
          playerStatus: {
            ...gameState.playerStatus,
            [playerId]: playerStatus
          },
          isPlaying: {
            ...gameState.isPlaying,
            [playerId]: true,
          },
        };
      }
    ).then(() => {});
  };
};

export const initializeBotMemory = (gameState: GameState) => {
  const botMemory: {
    [key: string]: {
      [key: string]: string[];
    };
  } = {};
  gameState?.playerIds?.forEach((player: string) => {
    if (player in gameState?.bots) {
      botMemory[player] = {};

      gameState?.playerIds.forEach((p: string) => {
        if (p === player) {
          botMemory[player][p] = [
            UNKNOWN,
            UNKNOWN,
            gameState?.hands?.[player]?.[2],
            gameState?.hands?.[player]?.[3],
            NOCARD,
            NOCARD,
          ];
        } else {
          botMemory[player][p] = [UNKNOWN, UNKNOWN, UNKNOWN, UNKNOWN, NOCARD, NOCARD];
        }
      });
    }
  });
  return {
    ...gameState,
    botMemory,
  };
};

export const getInitialRoundState = (gameState: GameState, rounds?: number, winners?: string[]) => {
  if (gameState?.round > gameState.finishedRounds.length) {
    console.error('gameState.round > gameState.finishedRounds.length');
    return gameState;
  }

  const db = firebase.firestore();
  const deck = shuffle(newDeck());

  const hands: any = {};
  const ready: any = {};
  const roomPlayerIds = gameState?.allPlayerIds.filter(
    (p) => gameState?.playerStatus?.[p] === PlayerStatus.Approved && gameState?.isPlaying?.[p]
  );

  const isPlaying = { ...gameState?.isPlaying };
  roomPlayerIds?.forEach((p) => (isPlaying[p] = true));

  const failedSlamCount: { [key: string]: number } = {};
  let playerIds = winners || roomPlayerIds;

  // For round of death
  playerIds?.forEach((p) => (failedSlamCount[p] = 0));

  playerIds = cycleFirstTurn(playerIds, gameState?.playerIds?.[0]);

  playerIds?.forEach((player: string) => {
    hands[player] = [deck.pop(), deck.pop(), deck.pop(), deck.pop(), NOCARD, NOCARD];
    ready[player] = player in gameState?.bots;
  });

  const roundRef = db.collection("rounds").doc();
  let newGameState: GameState = {
    ...gameState,
    playerIds: playerIds,
    isPlaying: isPlaying,
    ready: ready,
    roundId: roundRef.id,
    round: gameState?.finishedRounds?.length,
    roundSaved: false,
    gameSaved: false,
    leaderBoardUpdated: false,
    deck,
    hands,
    botMemory: {},
    discardPile: [],
    drawnCard: "",
    playerRafiki: "",
    turn: 0,
    loop: 0,
    lastTurn: -1,
    lastLoop: -1,
    turnState: TurnState.Draw,
    slammedCards: {},
    failedSlamCount: failedSlamCount,
    slam: {
      slamState: SlamState.NoSlam,
      player: "",
    } as NoSlam,
    paused: false,
    lastActivity: {},
    timestamp: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  };
  playerIds.forEach((player) => {
    if (newGameState?.lastActivity) {
      newGameState.lastActivity[player] = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
    }
  });

  newGameState = initializeBotMemory(newGameState);

  if (rounds) {
    newGameState.rounds = rounds;
  }

  delete newGameState.previousTurn;

  return newGameState;
};

export const getInitialGameState = (gameState: GameState, rounds: number) => {
  const db = firebase.firestore();
  const gameRef = db.collection("games").doc();

  let newGameState = {
    ...gameState,
    finishedRounds: [],
    round: 0,
  };
  // @ts-ignore
  newGameState = {
    ...getInitialRoundState(newGameState, rounds),
    gameId: gameRef.id,
    leftPlayerIds: [],
    firstTurn: 0,
    round: 0,
    initialGameSaved: false,
  };

  const playerIds = randomFirstTurn(newGameState.playerIds);
  newGameState.playerIds = playerIds;
  return newGameState;
};

export const startRound = ({
  roomCode,
  rounds,
  winners, // for round of death
}: {
  roomCode: string;
  rounds?: number;
  winners?: string[];
}) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
        dispatch,
        getState,
        roomCode,
        (gameState: GameState) => {
          return getInitialRoundState(gameState, rounds, winners);
        }
    )
      .then(() => {
        firebase.analytics().logEvent("start_round");
        dispatch(setModalOpen(ModalID.ViewTwoModal, true));
        dispatch(setModalOpen(ModalID.CallRafikiModal, true));
        dispatch(setModalOpen(ModalID.RoundOverModal, false));
      });
  };
};

export const newGame = (roomCode: string, rounds: number) => {
  let botCount = 0;
  let humanCount = 0;
  return function (dispatch: any, getState: any) {
    updateGameState(
        dispatch,
        getState,
        roomCode,
        (gameState: GameState) : GameState => {

          let newGameState = { ...gameState };
          newGameState = getInitialGameState(newGameState, rounds);

          return newGameState
        })
        .then(() => {
          firebase.analytics().logEvent("new_game", {
            bots: botCount,
            humans: humanCount,
          });
          dispatch(setModalOpen(ModalID.ViewTwoModal, true));
          dispatch(setModalOpen(ModalID.CallRafikiModal, true));
          dispatch(setModalOpen(ModalID.RoundOverModal, true));
        });
  };
};

export const backToLobbyHelper = (gameState: GameState, owner: string) => {
  let newGameState = {
    ...gameState,
    finishedRounds: [],
    round: 0
  };
  newGameState = getInitialGameState(newGameState, gameState.rounds);

  const playerIds = [owner];
  const playerStatus: { [key: string]: PlayerStatus } = {};
  newGameState?.playerIds?.forEach((playerId) => {
    playerStatus[playerId] = PlayerStatus.Pending;
  });
  Object.keys(newGameState?.bots)?.forEach((botId) => {
    playerStatus[botId] = PlayerStatus.Approved;
    playerIds.push(botId);
  });
  playerStatus[owner] = PlayerStatus.Approved;
  return {
    ...gameState,
    ...newGameState,
    playerIds: playerIds,
    playerStatus: playerStatus,
    leftPlayerIds: [],
    turn: -1,
  };
};

export const backToLobby = (roomCode: string, owner: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
        dispatch,
        getState,
        roomCode,
        (gameState: GameState): GameState => {
          return backToLobbyHelper(gameState, owner);
        }
    ).then(() => {});
  };
};

export const finishRound = (roomCode: string, owner: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.FinishRound,
      self: owner,
    });
  };
};

const getCurrentPlayer = (gameState: GameState) => {
  return gameState?.playerIds?.[gameState?.turn];
};

const getCardCount = (gameState: GameState, player: string) => {
  return gameState?.hands?.[player]?.filter((x) => x !== NOCARD).length;
};

const getPlayerIds = (gameState: GameState) => gameState?.playerIds;

export const canPlayerMove = (gameState: GameState) => {
  const currentPlayer = getCurrentPlayer(gameState);
  const turnState = gameState?.turnState;

  const currentPlayerCardCount = getCardCount(gameState, currentPlayer);
  const currentPlayerHasCards = currentPlayerCardCount > 0;

  const opponents = getPlayerIds(gameState).filter((x) => x !== currentPlayer);

  const someOpponentHasCards = opponents.some((opponent) => {
    if (gameState?.playerRafiki === opponent) {
      return false;
    }
    return getCardCount(gameState, opponent) > 0;
  });

  const canSwapWithOpponent = someOpponentHasCards && currentPlayerHasCards;
  const canSwapBetweenOpponents =
      sum(
          opponents.map((opponent) => {
            if (gameState?.playerRafiki === opponent) {
              return 0;
            }
            return getCardCount(gameState, opponent);
          })
      ) >= 2;

  if (turnState === TurnState.Replace) {
    return currentPlayerHasCards;
  } else if (turnState === TurnState.LookAtOwn) {
    return currentPlayerHasCards;
  } else if (turnState === TurnState.LookAtOpponents) {
    return someOpponentHasCards;
  } else if (turnState === TurnState.SwapWithOpponent) {
    return canSwapWithOpponent;
  } else if (turnState === TurnState.SwapBetweenOpponents) {
    return canSwapBetweenOpponents;
  } else if (turnState === TurnState.BlindSwap) {
    return canSwapWithOpponent || canSwapBetweenOpponents;
  }
};

export const getScores = (round: Round) => {
  const playerIds = Object.keys(round?.hands);
  let scoreboard = sortBy(
      playerIds.map((playerId) => {
        return {
          id: playerId,
          roundScore: handScore(round?.hands[playerId]),
        };
      }),
      "roundScore"
  );
  const minScore = scoreboard?.[0].roundScore;
  const tied = minScore === scoreboard?.[1]?.roundScore;
  scoreboard = scoreboard.map((player) => {
    if (player?.id === round.playerRafiki && (player.roundScore !== minScore || tied)) {
      return {
        ...player,
        roundScore: player.roundScore + RAFIKI_PENALTY,
      };
    }
    return player;
  });
  return scoreboard;
};

export const sumRoundScores = (roundScores: Score[]): Score[] => {
  const scoreMap: any = {};
  roundScores.forEach((scores: any) => {
    for (const player of scores) {
      if (!(player.id in scoreMap)) {
        scoreMap[player.id] = {
          id: player.id,
          score: 0,
        };
      }
      scoreMap[player.id].score += player.roundScore;
    }
  });

  const result = Object.keys(scoreMap).map((playerId) => {
    return {
      ...scoreMap[playerId],
    };
  });
  return result;
};

const updateGameState = (dispatch: Function, getState: Function, roomCode: string, fn: Function) => {
  const db = firebase.firestore();
  return (
      db
          .runTransaction((transaction: firebase.firestore.Transaction) => {
            const roomRef = db.collection("rooms").doc(roomCode);
            return transaction.get(roomRef).then(async (room: any) => {
              const gameState = room.data()?.gameState;
              let newGameState = fn(gameState);
              if (!newGameState) {
                // newGameState can be null if bots try to play out of turn
                return;
              }

              const result = {
                gameState: newGameState,
              }
              transaction.update(roomRef, result);
              return result
            });
          })
          // TODO catch errors and revert
          .then(async () => {})
  );
};

const updateGameStateWithBotMove = (dispatch: Function, getState: Function, roomCode: string, botId: string) => {
  return updateGameState(dispatch, getState, roomCode, (gameState: GameState) => {
    const move = getBotMoveForId(botId, gameState);
    if (!move) return;
    if (!(move.self in gameState?.bots)) return;

    let newGameState = gameReducer(gameState, move);
    newGameState = updateAllMemoriesForBots(newGameState, move);

    newGameState = updateActivity(newGameState, move.self);

    return newGameState;
  })
      .catch((e: any) => {
        if (e instanceof InvalidMoveError) {
          console.error(e);
        } else {
          throw e;
        }
      })
      .then(async () => {
        dispatch(disableTimeoutWarning());
        dispatch(disableSlamTimeoutWarning());
      });
};

const updateGameStateWithMove = (
    dispatch: Function,
    getState: Function,
    roomCode: string,
    move: Move,
    optimistic?: boolean
) => {
  const state = getState();
  try {
    const newGameState = gameReducer(state?.gameState, move);
    if (optimistic) {
      dispatch(setGameState(newGameState));
    }
    dispatch(startGameStateUpdate());
  } catch (e) {
    reportError(e);
  }

  return updateGameState(
      dispatch,
      getState,
      roomCode,
      (gameState: GameState): GameState => {
        let newGameState = gameReducer(gameState, move);
        newGameState = updateAllMemoriesForBots(newGameState, move);

        newGameState = updateActivity(newGameState, move.self);
        return newGameState;
      }
  ).then(async () => {
    dispatch(endGameStateUpdate());
    dispatch(disableTimeoutWarning());
    dispatch(disableSlamTimeoutWarning());
  });
};

export const updatePlayers = (playerIds: string[]) => {
  return function (dispatch: Function, getState: Function) {
    const players = playerIds?.map(async (playerID: string) => {
      const db = firebase.firestore();
      const playerObj: any = await db.collection("players").doc(playerID).get();
      return {
        id: playerObj?.id,
        name: playerObj.data()?.name || ANONYMOUS_USER_NAME,
      };
    });
    Promise.all(players).then((ps: any[]) => {
      const playerMap: PlayerMap = {};
      ps.forEach((p) => {
        if (p) {
          playerMap[p.id] = p;
        }
      });
      return dispatch(setPlayers(playerMap));
    });
  }
}

// Meta
export const startGameStateUpdate = () => ({
  type: START_GAME_STATE_UPDATE,
  payload: {},
});

export const endGameStateUpdate = () => ({
  type: END_GAME_STATE_UPDATE,
  payload: {},
});

export const enableTimeoutWarning = () => {
  return function (dispatch: any, getState: any) {
    dispatch({
      type: ENABLE_TIMEOUT_WARNING,
      payload: {},
    });
  };
};

export const disableTimeoutWarning = () => ({
  type: DISABLE_TIMEOUT_WARNING,
  payload: {},
});

export const enableSlamTimeoutWarning = () => {
  return function (dispatch: any, getState: any) {
    dispatch({
      type: ENABLE_SLAM_TIMEOUT_WARNING,
      payload: {},
    });
  };
};

export const disableSlamTimeoutWarning = () => ({
  type: DISABLE_SLAM_TIMEOUT_WARNING,
  payload: {},
});

// Moves

export const leaveGame = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    return updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.LeaveGame,
      self: self,
    })
  };
};

export const leaveGameAndSignOut = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    return updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.LeaveGame,
      self: self,
    })
        .then(() => {
          return firebase.auth().signOut();
        })
        .then(() => {
          firebase.analytics().logEvent("logout");
          dispatch(wipeSelectedCards());
          window.location.href = "/";
        });
  };
};

const getBotMoveForId = (botId: string, gameState: GameState) => {
  if (!gameState?.playerIds.includes(botId)) return;
  if (!gameState?.isPlaying?.[botId]) return;
  const bots = gameState.bots;
  const difficulty = bots?.[botId]?.difficulty;
  return getMove(botId, difficulty, gameState);
};

export const playBotMove = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    const state = getState();
    const gameState = state?.gameState;
    const bots = state?.gameState?.bots;
    const slamPlayer = getSlamPlayer(state);
    const currentPlayer = getCurrentPlayer(gameState);
    const slamPending = isSlamPending(state);

    if (state.self !== state.owner) {
      return;
    }
    if (state.gameState.paused) return;
    if (
        state?.gameState?.loop >= state?.game?.lastLoop &&
        state.gameState.turn >= state.gameState.lastTurn &&
        state?.gameState?.lastTurn >= 0
    )
      return;

    const getBotMove = () => {
      const botIds = Object.keys(bots);
      // Make a slam if possible
      for (let i = 0; i < botIds.length; i++) {
        const botId = botIds[i];
        const move = getBotMoveForId(botId, gameState);
        if (move && move.type === MoveType.Slam && !gameState.drawnCard) return move;
      }

      if (slamPending && slamPlayer?.id in bots) {
        return getBotMoveForId(slamPlayer?.id, gameState);
      } else if (!slamPending && currentPlayer in bots) {
        return getBotMoveForId(currentPlayer, gameState);
      }
    };

    const move = getBotMove();
    if (!move) return;
    if (!(move.self in state?.gameState?.bots)) return;

    updateGameStateWithBotMove(dispatch, getState, roomCode, move.self);
  };
};

export const setReady = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.Ready,
      self: self,
    }).then(() => {
      dispatch(setModalOpen(ModalID.ViewTwoModal, false));
    });
  };
};

export const drawCard = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.Draw,
      self: self,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const callRafiki = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.CallRafiki,
      self: self,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const initiateSlam = (roomCode: string, player: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.Slam,
      self: player,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const slam = (roomCode: string, self: string, player: string, playerIndex: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.PickSlamCard,
      self: self,
      player: player,
      playerIndex: playerIndex,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const finishIncorrectSlam = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.FinishIncorrectSlam,
      self: self,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const finishCorrectSlamOpponent = (roomCode: string, self: string, selfIndex: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.FinishCorrectSlamOpponent,
      self: self,
      selfIndex: selfIndex,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const startPeekingOwn = (roomCode: string, self: string, index: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(
        dispatch,
        getState,
        roomCode,
        {
          type: MoveType.StartLookAtOwn,
          self: self,
          selfIndex: index,
        },
        false
    ).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const finishPeekingOwn = (roomCode: string, self: string, index: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(
        dispatch,
        getState,
        roomCode,
        {
          type: MoveType.FinishLookAtOwn,
          self: self,
          selfIndex: index,
        },
        false
    ).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const startPeekingOpponent = (roomCode: string, self: string, playerID: string, index: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(
        dispatch,
        getState,
        roomCode,
        {
          type: MoveType.StartLookAtOpponents,
          self: self,
          opponent: playerID,
          opponentIndex: index,
        },
        false
    ).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const finishPeekingOpponent = (roomCode: string, self: string, playerID: string, index: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(
        dispatch,
        getState,
        roomCode,
        {
          type: MoveType.FinishLookAtOpponents,
          self: self,
          opponent: playerID,
          opponentIndex: index,
        },
        false
    ).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const replaceWithCardInHand = (roomCode: string, self: string, index: number) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(
        dispatch,
        getState,
        roomCode,
        {
          type: MoveType.Replace,
          self: self,
          selfIndex: index,
        },
        false
    ).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const swapCards = (roomCode: string, selectedCards: any, secondCard: Card, self: string) => {
  return function (dispatch: any, getState: any) {
    const firstCard = selectedCards[0];
    let selfIndex, opponentIndex, opponent;
    if (self === firstCard.playerID) {
      selfIndex = firstCard.index;
      opponentIndex = secondCard.index;
      opponent = secondCard.playerID;
    } else if (self === secondCard.playerID) {
      selfIndex = secondCard.index;
      opponentIndex = firstCard.index;
      opponent = firstCard.playerID;
    } else {
      // One of the players needs to be self
      return;
    }
    const move = {
      type: MoveType.SwapWithOpponent,
      self: self,
      selfIndex: selfIndex,
      opponent: opponent,
      opponentIndex: opponentIndex,
    } as Move;
    updateGameStateWithMove(dispatch, getState, roomCode, move, true).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

export const discardCard = (roomCode: string, self: string) => {
  return function (dispatch: any, getState: any) {
    updateGameStateWithMove(dispatch, getState, roomCode, {
      type: MoveType.Discard,
      self: self,
    }).then(() => {
      dispatch(wipeSelectedCards());
    });
  };
};

// export const replace = (roomCode: string, self: string) => {
//   return function (dispatch: any, getState: any) {
//     updateGameState(
//       dispatch,
//       getState,
//       roomCode,
//       (gameState: GameState): GameState => {
//         const player = gameState?.playerIds[gameState.turn];
//         if (self !== player) {
//           return gameState;
//         }
//         return {
//           ...gameState,
//           turnState: TurnState.Replace,
//         };
//       },
//       true
//     ).then(() => {
//       dispatch(wipeSelectedCards());
//     });
//   };
// };

// UI related
export const setGameState = (gameState: GameState) => ({
  type: SET_GAME_STATE,
  payload: {
    gameState,
  },
});

export const setOwner = (owner: string) => ({
  type: SET_OWNER,
  payload: {
    owner,
  },
});

export const setPlayers = (players: PlayerMap) => ({
  type: SET_PLAYERS,
  payload: {
    players,
  },
});

export const setSelf = (self: string) => ({
  type: SET_SELF,
  payload: {
    self,
  },
});

export const setRoomCode = (roomCode: string) => ({
  type: SET_ROOM_CODE,
  payload: {
    roomCode,
  },
});

export const setQuickPlayName = (name: string) => ({
  type: SET_QUICK_PLAY_NAME,
  payload: {
    name,
  },
});

export const setTurnState = (turnState: TurnState) => ({
  type: SET_TURN_STATE,
  payload: {
    turnState,
  },
});

export const setModalOpen = (modalID: ModalID, isOpen: boolean) => ({
  type: SET_MODAL_IS_OPEN,
  payload: {
    modalID,
    isOpen,
  },
});

export const setSnackbarMessage = (message: string) => ({
  type: SET_SNACKBAR_MESSAGE,
  payload: {
    snackbarMessage: message,
  },
});

export const addSelectedCard = (card: Card) => ({
  type: ADD_SELECTED_CARD,
  payload: {
    card,
  },
});

export const wipeSelectedCards = () => ({
  type: WIPE_SELECTED_CARDS,
});

export const toggleDebug = () => ({
  type: TOGGLE_DEBUG,
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
  payload: {},
});

export const toggleLeaderBoard = () => ({
  type: TOGGLE_LEADER_BOARD,
  payload: {},
});

export const setScoreBoard = (roomCode: string, scores: Score[]) => ({
  type: SET_SCORES,
  payload: {
    roomCode,
    scores,
  },
});

export const updateCircleCoordinatees = (coordinates: any) => ({
  type: UPDATE_CIRCLE_COORDINATES,
  payload: {
    coordinates,
  },
});

export const setModalOffsets = ( x: number, y: number ) => ({
  type: SET_MODAL_OFFSETS,
  payload: {
    x,
    y
  },
});

export const setRoundSaved = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
        dispatch,
        getState,
        roomCode,
        (gameState: GameState): GameState => {
          return {
            ...gameState,
            roundSaved: true,
          };
        }
    );
  };
};

export const fasterBot = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
        dispatch,
        getState,
        roomCode,
        (gameState: GameState): GameState => {
          return {
            ...gameState,
            botSpeed: BotSpeed.Fast,
          };
        }
    );
  };
};

export const slowerBot = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(
        dispatch,
        getState,
        roomCode,
        (gameState: GameState): GameState => {
          return {
            ...gameState,
            botSpeed: BotSpeed.Normal,
          };
        }
    );
  };
};

export const resetDeck = (roomCode: string) => {
  return function (dispatch: any, getState: any) {
    updateGameState(dispatch, getState, roomCode, moveDiscardsToDeck);
  };
};
