import React, { useState } from "react";
import { useDragLayer } from "react-dnd";
import styled from "styled-components";
import RoundOverModal from "./modals/RoundOverModal";
import {ModalID} from "./constants";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
`;

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

const Box = styled.div`
  width: 100px;
  height: 100px;
  background: red;
`;

export const CustomDragLayer = (props: any) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => {
    const result = {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      difference: monitor.getDifferenceFromInitialOffset(),
      isDragging: monitor.isDragging(),
    };
    if (result.isDragging && itemType === ModalID.RoundOverModal) {
      if (result.difference) {
        setOffset(result.difference);
      }
    } else {
      setOffset({ x: 0, y: 0 });
    }

    return result;
  });
  function renderItem() {
    if (itemType === ModalID.RoundOverModal) {
        return <RoundOverModal dragPreview={true}/>;
    } else {
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <Container
      style={{
        transform: `translateX(${offset.x}px) translateY(${offset.y}px)`,
      }}
    >
      <div>{renderItem()}</div>
    </Container>
  );
};
