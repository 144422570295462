import {
  suits,
  faceCards,
  TurnState,
  redSuits,
  blackSuits,
  NOCARD,
  COOKIES_ENABLED,
  ENABLE_DEBUGGING,
} from "./constants";
import { UNKNOWN } from "./bot";
import { getLeaderBoardEntry, getTotalPlayerCount, sum, sumRoundScores, getScores } from "./common";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import cookies from "browser-cookies";
import { Howl, Howler } from "howler";

export { getLeaderBoardEntry, getTotalPlayerCount, sum, sumRoundScores, getScores, NOCARD };

export const getPrettyCardDesc = (value: string) => {
  if (!value) return "";
  if (value === NOCARD) return "";
  if (value === UNKNOWN) return "";

  const suit = value.slice(-1);
  let number = value.slice(0, -1);
  if (value === "XJ" || value === "YJ") {
    return "Joker";
  }
  if (number === "K" || number === "Q" || number === "J" || number === "A") {
    number = faceCards[number];
  }
  if (suit === "H" || suit === "C" || suit === "D" || suit === "S") {
    return `${number} of ${suits[suit]}`;
  }
  throw new Error(`Invalid card value ${value}`);
};
export const getCardAction = (value: string) => {
  /**
   * Joker, Red King, A-6 = replace
   * 7-8 = look at own
   * 9-10 = look at opponent
   * Jack = blind swap w opp
   * Queen = blind switch between opps
   * Black King = blind swap or blind switch
   */
  const suit = value.slice(-1);
  const number = value.slice(0, -1);
  if (
    ["A", "2", "3", "4", "5", "6"].includes(number) ||
    (number === "K" && redSuits.includes(suit)) ||
    value === "XJ" ||
    value === "YJ"
  ) {
    return TurnState.Replace;
  }
  if (["7", "8"].includes(number)) {
    return TurnState.LookAtOwn;
  }
  if (["9", "10"].includes(number)) {
    return TurnState.LookAtOpponents;
  }
  if (number === "J") {
    return TurnState.SwapWithOpponent;
  }
  if (number === "Q") {
    return TurnState.SwapWithOpponent;
  }
  if (number === "K" && blackSuits.includes(suit)) {
    return TurnState.SwapWithOpponent;
  }
  // else { return TurnState.NotMyTurn }
  throw new Error("No turnState for this value");
};

const getColor = (card: string) => {
  const suit = card.slice(-1);
  return ["C", "S"].includes(suit) ? "red" : "black";
};

export const isCorrectSlam = (card: string, topDiscard: string): boolean => {
  const result = card.slice(0, -1) === topDiscard.slice(0, -1) || (card[1] === "J" && topDiscard[1] === "J");
  return result;
};

export const generateRoomCode = () => {
  return Math.random().toString(36).substring(2, 6);
};

export const roomCodeFromLocation = () => {
  if (window?.location?.search?.startsWith("?room=")) {
    return window?.location?.search?.substring(6);
  }
  return "";
};

export const urlWithRoomCode = (url: string, roomCode?: string) => {
  roomCode = roomCode || roomCodeFromLocation();
  if (roomCode) {
    return `${url}?room=${roomCode}`;
  }
  return url;
};

export const combineWithAnd = (names: (string | undefined)[]): string => {
  const butLast = names.slice(0, names.length - 1);
  if (names.length === 1) {
    return names[0] || "";
  } else if (names.length > 1) {
    return `${butLast.join(", ")} and ${names[names.length - 1]}`;
  }
  return "";
};

export const randomFirstTurn = (playerIds: string[]) => {
  const count = playerIds.length;
  const random = Math.floor(Math.random() * count);
  return [...playerIds.slice(random), ...playerIds.slice(0, random)];
};

export const cycleFirstTurn = (playerIds: string[], previousFirstPlayer: string) => {
  if (playerIds.length < 2) {
    return playerIds;
  }
  let index = playerIds.indexOf(previousFirstPlayer) + 1;
  if (index === playerIds.length) {
    index = 0;
  }
  if (index === -1) {
    index = 0;
  }
  return [...playerIds.slice(index, playerIds.length), ...playerIds.slice(0, index)];
};

export class InvalidMoveError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = "InvalidMoveError";
  }
}

const sound_files: string[] = [
  "blind_swap",
  "calling_rafiki",
  "chat",
  "error",
  "pickup_card",
  "putdown_card",
  "shuffle",
  "slamming",
  "soundsprite",
  "tick",
  "turn",
  "win",
];

const sounds: any = {};

sound_files.forEach((sound) => {
  const s = new Howl({
    // In order of preference
    src: [`${process.env.PUBLIC_URL}/sounds/${sound}.webm`, `${process.env.PUBLIC_URL}/sounds/${sound}.mp3`],
  });
  sounds[sound] = s;
});

export const initializeAudio = () => {};

export const playAudio = (sound: string) => {
  sounds[sound].play();
};

export const generateBotId = () => {
  return Math.random().toString(36).substring(2, 9);
};

export const initializeErrorReporting = () => {
  if (typeof window === "undefined") {
    return;
  }
  window.onerror = (message, source, lineno, colno, error) => {
    firebase.analytics().logEvent("exception", {
      message,
      source,
      lineno,
      colno,
      error,
    });
  };
};

export const verifyCookieSupport = () => {
  return Promise.resolve(cookies.get(COOKIES_ENABLED));
};

export const reportError = (e: Error) => {
  if (ENABLE_DEBUGGING) {
    throw e;
  } else {
    window?._errs?.push(e);
  }
};

export const isAnonymous = () => {
  return Boolean(firebase.auth().currentUser?.isAnonymous);
}

export const isInsideIframe = () => {
  return window.self !== window.top
}
