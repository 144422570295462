import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalID, SlamState } from "../constants";
import {
  getRoomCode,
  getSelf,
  getSlamDiscard,
  getSlammedCard,
  getSlamModalStrapLine,
  getSlamModalTitle,
  getSlamPlayer,
  getSlamState,
  isSidebarOpen,
  isUpdatingGameState,
  showSlamModal,
} from "../redux/selectors";
import { finishIncorrectSlam, setModalOpen } from "../redux/actions";
import { ModalCards, Strapline, Title } from "../components/StyledComponents";
import Modal from "./Modal";
import { Card } from "../components/Card";

const SlamModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(showSlamModal);
  const slamDiscard = useSelector(getSlamDiscard) as string;
  const minimise = !useSelector(isSidebarOpen);
  const slamState = useSelector(getSlamState);
  const slammedCard = useSelector(getSlammedCard) as string;
  const self = useSelector(getSelf);
  const roomCode = useSelector(getRoomCode);
  const playerSlam = useSelector(getSlamPlayer);
  const updatingGameState = useSelector(isUpdatingGameState);

  const title = useSelector(getSlamModalTitle);
  const strapLine = useSelector(getSlamModalStrapLine);

  const mySlam = playerSlam?.id === self;
  const shouldClose =
    !mySlam || slamState === SlamState.SlamSuccessOpponent || slamState === SlamState.SlamFinishSuccessOwn;
  return isOpen ? (
    <Modal
      minimise={minimise}
      onClick={() => {
        if (shouldClose) {
          dispatch(setModalOpen(ModalID.SlamModal, false));
        }
        if (updatingGameState) return;
        if ((slamState === SlamState.SlamFailureOwn || slamState === SlamState.SlamFailureOpponent) && mySlam) {
          dispatch(finishIncorrectSlam(roomCode, self));
        }
      }}
      modalID={ModalID.SlamModal}
      clickOutsideToClose={shouldClose}
    >
      <Title>{title}</Title>
      <Strapline>{strapLine}</Strapline>
      <ModalCards>
        <Card playerID="modal" value={slammedCard} size={"large"} ieColumn={1} />
        <Card playerID="modal" value={slamDiscard} size={"large"} ieColumn={3} />
      </ModalCards>
    </Modal>
  ) : (
    <div />
  );
};

export default SlamModal;
