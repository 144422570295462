import {useDispatch, useSelector} from "react-redux";
import {
  canPauseGame,
  gamePaused,
  getOpenModalId,
  getRoomCode,
  getRoomOwner,
  getRound,
  getSelf,
  getTotalRounds,
  isOwner,
} from "../redux/selectors";
import {Link, TopLeftContainer, TopLeftTitle} from "./StyledComponents";
// @ts-ignore
import Settings from "react-feather/dist/icons/settings";
import React, {useRef, useState} from "react";
import styled from "styled-components";
import {backToLobby, leaveGameAndSignOut, pauseGame, resumeGame, setModalOpen,} from "../redux/actions";
import {ModalID} from "../constants";
import useClickAway from "react-use/lib/useClickAway";
import {adHeight, mobileWidth} from "../layout";
import GameLeaderBoard from "./GameLeaderboard";

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import {isAnonymous} from "../utils";

const PoliciesLink = styled(Link)`
  text-align: left;
  font-style: italic;
`;

const RoundStatus = styled.div`
  color: #e2e3eb;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.2;
`;

const Box = styled.div`
  color: #e2e3eb;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  list-style: none;
  padding: 12px;
  margin: 0;
  cursor: pointer;
  user-select: none;
`;

interface MenuHeaderProps {
  open: boolean;
}

const MenuHeader = styled(Box)<MenuHeaderProps>`
  margin-top: -12px;
  margin-left: -12px;
  &:hover {
    background-color: #2c2f3e;
  }
  ${({ open }) =>
    open &&
    `
    background-color: #2c2f3e;
  `}
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 100;
  width: 100%;
  margin-top: 1px;
`;

interface LiProps {
  disabled?: boolean;
}

const LI = styled(Box)<LiProps>`
  background-color: #2c2f3e;
  padding-left: 30px;
  margin-left: -12px;
  padding-right: 12px;
  white-space: nowrap;
  ${({ disabled }) =>
    disabled &&
    `
    color: grey;
  `}
  &:hover {
    color: #fecf6f;
    background-color: #1f2231;
    ${({ disabled }) =>
      disabled &&
      `
      color: grey;
      background-color: #2c2f3e;
    `}
  }
`;

export const Container = styled(TopLeftContainer)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  z-index: 10;
  @media (max-width: ${mobileWidth}px) {
    top: ${adHeight}px;
  }
`;

export function GameMenu() {
  const ref = useRef(null);
  const roomOwner = useSelector(getRoomOwner);
  const round = useSelector(getRound);
  const rounds = useSelector(getTotalRounds);
  const [open, setOpen] = useState(false);
  const canPause = useSelector(canPauseGame);
  const paused = useSelector(gamePaused);
  const dispatch = useDispatch();
  const roomCode = useSelector(getRoomCode);
  const imOwner = useSelector(isOwner);
  const self = useSelector(getSelf);
  const modalOpen = useSelector(getOpenModalId);

  const closeMenu = () => {
    setOpen(false);
  };

  useClickAway(ref, closeMenu);

  const onPress = () => {
    if (paused) {
      dispatch(resumeGame(roomCode));
    } else {
      dispatch(pauseGame(roomCode));
    }
  };

  let roundStatus = "";
  if (round > rounds) {
    roundStatus = "Round of Death";
  } else if (round > 0) {
    roundStatus = `Round ${round}/${rounds}`;
  }

  return (
    <Container ref={ref}>
      {roomOwner?.name && (
        <div onClick={() => setOpen(!open)}>
          <MenuHeader open={open}>
            <TopLeftTitle>
              <Settings stroke={"#e2e3ea"} height={"0.9375rem"} width={"0.9375rem"} />
              &nbsp;
              {`${roomOwner?.name}'s room`}&nbsp;
              <RoundStatus>{roundStatus}</RoundStatus>
            </TopLeftTitle>
          </MenuHeader>
          {open && (
            <UL>
              {imOwner && canPause && <LI onClick={onPress}>{paused ? "Resume game" : "Pause game"}</LI>}
              {imOwner && (
                <LI
                  onClick={() => {
                    dispatch(backToLobby(roomCode, self));
                  }}
                >
                  Back to lobby
                </LI>
              )}
              {imOwner && !isAnonymous() && (
                  <LI
                      onClick={() => {
                        dispatch(setModalOpen(ModalID.RemovePlayersModal, true));
                      }}
                  >
                    Remove players
                  </LI>
              )}
              {imOwner && !isAnonymous() && (
                <LI
                  disabled={Boolean(modalOpen)}
                  onClick={() => {
                    dispatch(setModalOpen(ModalID.PassOwnershipModal, true));
                  }}
                >
                  Pass room
                </LI>
              )}
              <LI
                onClick={() => {
                  dispatch(leaveGameAndSignOut(roomCode, self));
                }}
              >
                Sign out
              </LI>
              <PoliciesLink to="/policies">
                <LI>Policies</LI>
              </PoliciesLink>
            </UL>
          )}
        </div>
      )}
      <GameLeaderBoard />
    </Container>
  );
}
