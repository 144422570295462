import React, { SyntheticEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalID, REENABLE_BUTTON_TIMEOUT } from "../constants";
import {
  canStartGame,
  getAllOtherHumanPlayers,
  getBots, getCurrentPlayer,
  getHumanPlayerCount,
  getPlayerStatus,
  getRoomCode, getSlamPlayer,
  isModalOpen,
  isOwner,
  isSidebarOpen,
} from "../redux/selectors";
import {
  FormButton,
  ListItem,
  ListText,
  ModalContainer,
  TinyStyledButton,
  Title,
  UnorderedList,
} from "../components/StyledComponents";
import Modal from "./Modal";
import {
  addBot,
  approvePlayer,
  denyPlayer, leaveGame,
  leaveGameAndSignOut,
  newGame,
  removeBot,
  renameBot,
  setBotDifficulty
} from "../redux/actions";
import styled from "styled-components";
// @ts-ignore
import XCircle from "react-feather/dist/icons/x-circle";
import SelectBot from "../components/SelectBot";
import { BotDifficulty, BotOption, State } from "../redux/types";
import BotName from "../components/BotName";
import range from "lodash/range";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import "firebase/functions";
import useMountedState from "react-use/lib/useMountedState";
import { generateBotId } from "../utils";
import { desktopWidth, mobileWidth, tabletWidth } from "../layout";
import { PlayerStatus } from "../common";

const Message = styled.div`
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 11px;
  text-align: center;
  margin-top: 5px;
`;

const Text = styled.div`
  color: #e2e3ea;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  @media (max-width: ${desktopWidth}px) {
    font-size: 15px;
  }
  @media (max-width: ${tabletWidth}px) {
    font-size: 14px;
  }
  @media (max-width: ${mobileWidth}px) {
    font-size: 12px;
  }
`;
const Subtitle = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  margin-top: 30px;
  border-bottom: 1px solid #4c4e5c;
  line-height: 43px;
`;

const OptionText = styled.span`
  color: #e2e3ea;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 28px;
`;

const RemoveBot = styled(XCircle)`
  vertical-align: middle;
  stroke: #fecf6f;
  height: 17.5px;
  width: 17.5px;
`;

export const AddAnother = styled.button`
  color: #fecf6f;
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: right;
  display: inline-block;
  padding: 0 5px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ListTextWithoutPadding = styled(ListText)`
  padding: 0;
`;

const RemovePlayersModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: State) => isModalOpen(state, ModalID.RemovePlayersModal));
  const imOwner = useSelector(isOwner);
  const roomCode = useSelector(getRoomCode);
  const playerStatus = useSelector(getPlayerStatus);
  const players = useSelector(getAllOtherHumanPlayers);
  const humanPlayerCount = useSelector(getHumanPlayerCount);

  const minimise = !useSelector(isSidebarOpen);
  const canStart = useSelector(canStartGame);
  const [hidden, setHidden] = useState(false);
  const [rounds, setRounds] = useState(5);
  const bots = useSelector(getBots);
  const mounted = useMountedState();

  const count = humanPlayerCount + bots.length;
  return isOpen ? (
    <Modal yellow={false} minimise={minimise} modalID={ModalID.RemovePlayersModal} clickOutsideToClose={true}>
      <Title>Remove Players</Title>
      <ModalContainer>
        <Subtitle>Player list</Subtitle>
        <UnorderedList>
          {players.map((player) => {
            if (!player?.name) return null;
            return (
              <ListItem key={player?.id}>
                <OptionText>{player?.name}</OptionText>
                  <span>
                    <TinyStyledButton
                      onClick={() => {
                        dispatch(leaveGame(roomCode, player?.id));
                      }}
                    >
                      Remove
                    </TinyStyledButton>
                  </span>
                {false && (
                  <ListTextWithoutPadding>Removed!</ListTextWithoutPadding>
                )}
              </ListItem>
            );
          })}
        </UnorderedList>
      </ModalContainer>
    </Modal>
  ) : (
    <div />
  );
};

export default RemovePlayersModal;
