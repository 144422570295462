import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card } from "./Card";
import {
  Card as CardType,
  MOVE_TIMEOUT_WARNING,
  NOCARD,
  Player,
  SLAM_TIMEOUT_WARNING,
  SlamState,
  TurnState,
} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlreadySlammed,
  getCurrentPlayer,
  getHandForPlayer,
  getIsPlaying,
  getLookingAtIndex,
  getLookingAtPlayer,
  getPlayerCount,
  getRafikiPlayerId,
  getRoomCode,
  getSelectedCards,
  getSelf,
  getSlamPlayerId,
  getSlamState,
  getTurnState,
  isMySlam,
  isMyTurn, isPlayerReady, isUpdatingGameState,
  shouldShowSlamTimeoutWarning,
  shouldShowTimeoutWarning,
  isSlamPending
} from "../redux/selectors";
import { State } from "../redux/types";
import { cardGap } from "../layout";
import { CardPlaceholder } from "./StyledComponents";
import isNumber from "lodash/isNumber";
import HandHeader from "./HandHeader";
import {
  addSelectedCard,
  finishCorrectSlamOpponent,
  finishPeekingOpponent,
  finishPeekingOwn,
  replaceWithCardInHand,
  slam,
  startPeekingOpponent,
  startPeekingOwn,
  swapCards,
  wipeSelectedCards,
} from "../redux/actions";
import Timer from "./Timer";
import { deck } from "../svgExports";
import { playAudio } from "../utils";

const CardsWrapper = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr ${cardGap}vmin 1fr ${cardGap}vmin 1fr ${cardGap}vmin 1fr;
  grid-template-columns: 1fr ${cardGap}vmin 1fr ${cardGap}vmin 1fr ${cardGap}vmin 1fr;
  -ms-grid-rows: 1fr ${cardGap}vmin 1fr;
  grid-template-rows: 1fr ${cardGap}vmin 1fr;
  position: relative;
  pointer-events: all;
`;

interface HandProps {
  angle: number;
  adjustment: boolean;
}

const Wrapper = styled.div<HandProps>`
  color: white;
  padding: 5px 0;
  position: absolute;
  transform-origin: 50% 00%;
  transform: translateX(-50%) rotate(${({ angle }) => angle}deg)
    translateY(${({ adjustment }) => (adjustment ? 0 : -10)}%);
  bottom: 00%;
  left: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
`;

interface Props {
  index: number;
  player: Player;
}

const indexMap = [undefined, 0, 1, undefined, 4, 2, 3, 5];

const Hand: React.FC<Props> = (props) => {
  const hand = useSelector((state: State) => getHandForPlayer(state, props.player.id)) || [
    "AS",
    "2S",
    "3S",
    "4S",
    NOCARD,
    NOCARD,
  ];
  const rafikiPlayer = useSelector(getRafikiPlayerId);
  const slamPlayer = useSelector(getSlamPlayerId);
  const currentPlayer = useSelector(getCurrentPlayer);
  const isPlayersTurn = props?.player?.id === currentPlayer?.id;
  const isPlayersRafiki = props?.player?.id === rafikiPlayer;
  const showTimeoutWarning = useSelector(shouldShowTimeoutWarning);
  const showSlamTimeoutWarning = useSelector(shouldShowSlamTimeoutWarning);
  const lookingAtIndex = useSelector(getLookingAtIndex);
  const lookingAtPlayer = useSelector(getLookingAtPlayer);
  const isReady = useSelector((state: State) => {
    return isPlayerReady(state, props?.player?.id)
  });

  const count = useSelector(getPlayerCount);
  const rotate = (props.index * 360) / count;

  const showInverted = rotate > 90 && rotate < 270;

  const handWithFiller = [0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
    const newIndex = indexMap[i];
    if (!isNumber(newIndex)) return NOCARD;
    if (hand?.[newIndex]) {
      return hand?.[newIndex];
    } else {
      return NOCARD;
    }
  });

  const turnState = useSelector(getTurnState);
  const slamState = useSelector(getSlamState);
  const isPlayersSlam =
    props?.player?.id === slamPlayer &&
    (slamState === SlamState.SlamStart ||
      slamState === SlamState.SlamSuccessOpponent ||
      slamState === SlamState.SlamFailureOwn ||
      slamState === SlamState.SlamFailureOpponent);

  const self = useSelector(getSelf);
  const roomCode = useSelector(getRoomCode);
  const myTurn = useSelector(isMyTurn);

  const mySlam = useSelector(isMySlam);
  const alreadySlammed = useSelector(getAlreadySlammed);
  const dispatch = useDispatch();
  const selectedCards = useSelector(getSelectedCards);
  const isPlaying = useSelector(getIsPlaying);
  const updatingGameState = useSelector(isUpdatingGameState);
  const hasLeft = !isPlaying?.[props.player?.id];
  const slamInProgress = useSelector(isSlamPending);

  return (
    <Wrapper angle={rotate} adjustment={showInverted}>
      {handWithFiller.map((cardValue, i) => {
        if (!deck[cardValue]) {
          return null;
        }
        return <link key={i} rel="preload" href={deck[cardValue]} as="image" />;
      })}
      {!showInverted && (
        <HandHeader
          name={props?.player?.name}
          yellow={isPlayersSlam}
          red={isPlayersRafiki}
          bold={isPlayersTurn}
          inverted={showInverted}
          hasLeft={hasLeft}
          ready={isReady}
        />
      )}
      {!hasLeft && (
        <CardsWrapper>
          {showTimeoutWarning && currentPlayer?.id === props.player?.id && currentPlayer?.id === self && (
            <Timer inverted={showInverted} seconds={MOVE_TIMEOUT_WARNING} />
          )}
          {showSlamTimeoutWarning && slamPlayer === props.player?.id && slamPlayer === self && (
            <Timer inverted={showInverted} seconds={SLAM_TIMEOUT_WARNING} />
          )}
          {handWithFiller.map((cardValue, i) => {
            if (cardValue === NOCARD) {
              return <CardPlaceholder key={i} />;
            }
            const index = indexMap[i];
            const ieColumn = [1, 3, 5, 7, 1, 3, 5, 7];
            const ieRow = [1, 1, 1, 1, 3, 3, 3, 3];
            return (
              <Card
                value={cardValue}
                key={i}
                index={index}
                playerID={props?.player?.id}
                inverted={showInverted}
                ieColumn={ieColumn[i]} // IE does not autoplace grid items
                ieRow={ieRow[i]}
                onClick={() => {
                  if (updatingGameState) return;
                  const card = { value: cardValue, index: index, playerID: props?.player?.id } as CardType;

                  const playerID = props?.player?.id;
                  if (slamState === SlamState.SlamStart && mySlam && !(card.value in alreadySlammed)) {
                    return dispatch(slam(roomCode, self, card.playerID, card.index));
                  } else if (slamState === SlamState.SlamSuccessOpponent && mySlam && playerID === self) {
                    return dispatch(finishCorrectSlamOpponent(roomCode, self, card.index));
                  }

                  if (!myTurn) {
                    playAudio("error");
                    return;
                  }

                  if (slamInProgress) {
                    playAudio("error");
                    return;
                  }
                  if (turnState === TurnState.Replace && (playerID === self || playerID === "discard")) {
                    if (isNumber(index)) {
                      playAudio("putdown_card");
                      return dispatch(replaceWithCardInHand(roomCode, playerID, index));
                    }
                  } else if (turnState === TurnState.LookAtOwn && playerID === self) {
                    playAudio("pickup_card");
                    return dispatch(startPeekingOwn(roomCode, self, card.index));
                  } else if (
                    turnState === TurnState.LookingAtOwn &&
                    playerID === self &&
                    lookingAtIndex === card.index
                  ) {
                    dispatch(addSelectedCard(card));
                    playAudio("putdown_card");
                    return dispatch(finishPeekingOwn(roomCode, self, card.index));
                  } else if (turnState === TurnState.LookAtOpponents && playerID !== self && card.playerID !== rafikiPlayer) {
                    playAudio("pickup_card");
                    dispatch(addSelectedCard(card));
                    return dispatch(startPeekingOpponent(roomCode, self, playerID, card.index));
                  } else if (
                    turnState === TurnState.LookingAtOpponents &&
                    playerID === lookingAtPlayer &&
                    lookingAtIndex === card.index
                  ) {
                    playAudio("putdown_card");
                    return dispatch(finishPeekingOpponent(roomCode, self, playerID, card.index));
                  } else if (
                    turnState === TurnState.SwapBetweenOpponents ||
                    turnState === TurnState.SwapWithOpponent ||
                    turnState === TurnState.BlindSwap
                  ) {
                    if (selectedCards?.length === 1 && card.playerID !== rafikiPlayer) {
                      if (selectedCards?.[0]?.value === card.value) {
                        return dispatch(wipeSelectedCards());
                      }
                      if (
                        (selectedCards?.[0]?.playerID === self && card.playerID !== self) ||
                        (selectedCards?.[0]?.playerID !== self && card.playerID === self)
                      ) {
                        return dispatch(swapCards(roomCode, selectedCards, card, self));
                      } else {
                        playAudio("error");
                      }
                    } else if (selectedCards?.length === 0 && card.playerID !== rafikiPlayer) {
                      return dispatch(addSelectedCard(card));
                    } else {
                      playAudio("error");
                    }
                  } else {
                    playAudio("error");
                  }
                }}
              />
            );
          })}
        </CardsWrapper>
      )}
      {showInverted ? (
        <HandHeader
          name={props?.player?.name}
          yellow={isPlayersSlam}
          red={isPlayersRafiki}
          bold={isPlayersTurn}
          inverted={showInverted}
          hasLeft={hasLeft}
          ready={isReady}
        />
      ) : (
        " "
      )}
    </Wrapper>
  );
};

export default Hand;
