import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRoomCode, isSidebarOpen } from "../redux/selectors";
import { FormButton, Strapline, Title } from "../components/StyledComponents";
import Modal from "./Modal";
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import { useHistory } from "react-router";
import { urlWithRoomCode } from "../utils";
import useMountedState from "react-use/lib/useMountedState";

const LoginModal: React.FC = () => {
  const minimise = !useSelector(isSidebarOpen);
  const [loggedin, setLogin] = useState(true);
  const history = useHistory();
  const roomCode = useSelector(getRoomCode);

  let mounted = useMountedState();

  useEffect(() => {
    if (!mounted()) return;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user?.uid) {
        mounted() && setLogin(true);
      } else {
        mounted() && setLogin(false);
      }
    });
  }, [mounted]);

  return !loggedin ? (
    <Modal minimise={minimise}>
      <Title>Login required</Title>
      <Strapline>You need to Log in to join the room</Strapline>
      <FormButton
        onClick={() => {
          history.push(urlWithRoomCode("/", roomCode));
        }}
      >
        Log in
      </FormButton>
    </Modal>
  ) : (
    <div />
  );
};

export default LoginModal;
