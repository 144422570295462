import React from "react";
import CopyUrlButton from "./CopyUrlButton";
import CallRafikiButton from "./CallRafiki";
import SlamButton from "./SlamButton";
import { useSelector } from "react-redux";
import {gameNotStarted, isSidebarOpen} from "../redux/selectors";
import styled from "styled-components";
import {adHeight, mobileWidth, sidebarOpenOffset, sidebarWidth, tabletWidth} from "../layout";
import {MinimiseProps} from "./StyledComponents";

interface ContainerProps extends MinimiseProps {
    showCopy: boolean;
};

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-rows: 1fr 5px 1fr;
  ${({showCopy}) => showCopy && `
      grid-template-rows: 1fr 5px 1fr 5px 1fr;
  `}
  position: absolute;
  right: ${sidebarWidth}px;
  top: ${adHeight}px;
  color: white;
  z-index: 10;
  padding: 20px;
  transition: transform 0.2s ease;
  transform: translateX(0%);
  ${({ minimise }) => minimise && `transform: translateX(${2 * sidebarOpenOffset}px);`};
  @media (max-width: ${tabletWidth}px) {
    transform: translateX(0%);
    right: 0;
  }
  @media (min-width: ${mobileWidth}px) {
    top: 0;
  }
  @media (min-width: ${tabletWidth}px) {
    display: none;
    top: 0;
  }
`;

function TopButtonContainer(props: any) {
    const hasNotStarted = useSelector(gameNotStarted);
    const minimise = !useSelector(isSidebarOpen);
    return (
        <Container minimise={minimise} showCopy={hasNotStarted}>
            {hasNotStarted && <CopyUrlButton />}
            <CallRafikiButton />
            <SlamButton />
        </Container>
    );
}

export default TopButtonContainer;
