import React, {useEffect} from "react";
import styled from "styled-components";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import {useSelector} from "react-redux";
import {gamePaused} from "../redux/selectors";
import {initializeAudio, playAudio} from "../utils";

const size = 80;
const strokeWidth = 10;
const strokeDash = Math.PI * (size - 2 * strokeWidth);

interface CountdownProps {
  inverted: boolean;
}

const Countdown = styled.div<CountdownProps>`
  pointer-events: none;
  margin: auto;
  height: 50%;
  width: 50%;
  
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  ${({ inverted }) =>
    inverted &&
    `
      transform: translate(-50%, -50%) rotate(180deg);
  `}
`;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg) rotateZ(-90deg);
`;

interface CircleProps {
  seconds: number;
}
const Circle = styled.circle<CircleProps>`
  stroke-dasharray: ${strokeDash}px;
  stroke-dashoffset: 0px;
  ${({ seconds }) => seconds && `animation: countdown ${seconds}s linear 1 forwards;`}
  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: ${strokeDash}px;
    }
  }
`;

const CountdownNumber = styled.div`
  color: #fdcc68;
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
`;

interface TimerProps {
  seconds: any;
  inverted: boolean;
}

function Timer(props: TimerProps) {
  const paused = useSelector(gamePaused);
  const [count, setCount] = React.useState(props.seconds);
  const [delay, _setDelay] = React.useState(1000);
  const [isRunning, _toggleIsRunning] = useBoolean(true);

  useEffect(() => {
    initializeAudio();
  },['once']);

  useInterval(
    () => {
      setCount(count - 1);
    },
    isRunning ? delay : null
  );

  useEffect(() => {
    if (count > 0 && !paused) {
      playAudio('tick');
    }
  }, [count, paused]);

  const radius = size / 2;
  if (paused) return null;

  if (count < 0) {
    return null;
  }
  return (
    <Countdown inverted={props.inverted}>
      <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          r={radius - strokeWidth}
          cx={radius}
          cy={radius}
          stroke={"#2C2F3E"}
          strokeWidth={strokeWidth}
          fill={"transparent"}
          strokeLinecap={"butt"}
        />
        <Circle
          r={radius - strokeWidth}
          cx={radius}
          cy={radius}
          stroke={"#FECF6F"}
          strokeWidth={strokeWidth}
          fill={"transparent"}
          strokeLinecap={"butt"}
          seconds={props.seconds}
        />
      </Svg>
      <CountdownNumber>{count}</CountdownNumber>
    </Countdown>
  );
}

export default Timer;
