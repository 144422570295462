import React from "react";
import { Link } from "./StyledComponents";
import styled from "styled-components";
import { isSidebarOpen } from "../redux/selectors";
import { useSelector } from "react-redux";

interface MinimiseProps {
  minimise: boolean;
}
const Container = styled.div<MinimiseProps>`
  display: block;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  color: red;
  justify-content: center;
  margin-left: 0;
  padding-left: 0;
`;
const Li = styled.li`
  display: inline;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const FooterLink = styled(Link)`
  color: #e2e3ea;
`;

function Footer() {
  const minimise = !useSelector(isSidebarOpen);
  return (
    <Container minimise={minimise}>
      <Ul>
        <Li>
          <FooterLink to={"/policies"}>Policies</FooterLink>
        </Li>
      </Ul>
    </Container>
  );
}

export default Footer;
