// @ts-nocheck
// Youtube JS Api https://developers.google.com/youtube/iframe_api_reference#Playback_controls
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface YoutubeVideoProps {
  videoID: string;
  seekTo: number;
  width?: string;
  height?: string;
  onPlayerReady?: Function;
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

function YoutubeVideo(props: YoutubeVideoProps) {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (!player) return;

    player.seekTo(props.seekTo, true);
    player.playVideo();
  }, [player, props.seekTo]);

  useEffect(() => {
    const tag = document.createElement("script");

    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    let player: any;

    // @ts-ignore
    window.onYouTubeIframeAPIReady = function () {
      // @ts-ignore
      player = new YT.Player("player", {
        height: "390",
        width: "640",
        videoId: props.videoID,
        playerVars: {
          playsinline: 1,
          modestbranding: 1,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };

    function onPlayerReady(event: any) {
      setPlayer(player);
      event.target.playVideo();
      props.onPlayerReady?.();
    }

    function onPlayerStateChange(event: any) {
    }
    function stopVideo() {
      player.stopVideo();
    }
  }, []);
  return <Container id={"player"}/>;
}

export default YoutubeVideo;
