import React from "react";
import styled from "styled-components";

interface YoutubeVideoProps {
  src: string;
  width?: string;
  height?: string;
}

const Iframe = styled.iframe`
  max-width: 100%;
  background: #393c4b;
`;

function YoutubeVideo(props: YoutubeVideoProps) {
  return (
    <Iframe
    width={props.width || "560"}
    height={props.height || "315"}
    src={props.src}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    />
  );
}

export default YoutubeVideo;
