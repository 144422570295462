import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalID } from "../constants";
import {
  getActivePlayerCount,
  getRafikiPlayer,
  isMyRafiki,
  isSidebarOpen,
  showRafikiModal
} from "../redux/selectors";
import { setModalOpen } from "../redux/actions";
import { Strapline, Title } from "../components/StyledComponents";
import logo from "../assets/rafiki-logo_red.svg";
import styled from "styled-components";
import Modal from "./Modal";

const Logo = styled.img`
  margin-top: 80px;
  height: 113px;
`;

const CallRafikiModal: React.FC = () => {
  const dispatch = useDispatch();
  let playerRafiki = useSelector(getRafikiPlayer);
  let isOpen = useSelector(showRafikiModal);
  const minimise = !useSelector(isSidebarOpen);
  const playerCount = useSelector(getActivePlayerCount);

  let title;
  let strapline;
  let description;

  if (useSelector(isMyRafiki)) {
    title = "You have called RAFIKI";
    strapline = <strong>Your hand is protected.</strong>;
    if (playerCount > 2) {
      description = "Your opponents have one more turn before all cards are revealed…";
    } else {
      description = "Your opponent has one more turn before all cards are revealed…";
    }
  } else {
    title = `${playerRafiki} has called RAFIKI`;
    if (playerCount > 2) {
      strapline = <strong>{`${playerRafiki}’s hand is protected! One more turn for you and ${playerRafiki}’s other opponents.`}</strong>;
    } else {
      strapline = <strong>{`${playerRafiki}’s hand is protected! One more turn for you.`}</strong>;
    }
    description = "All cards will then be revealed…";
  }
  return isOpen ? (
    <Modal
      red={true}
      minimise={minimise}
      modalID={ModalID.CallRafikiModal}
      onClick={() => {
        dispatch(setModalOpen(ModalID.CallRafikiModal, false));
      }}
    >
      <Logo src={logo} alt="rakifi-logo" />
      <Title>{title}</Title>
      <Strapline>{strapline}</Strapline>
      <Strapline>{description}</Strapline>
      <div></div>
    </Modal>
  ) : (
    <div />
  );
};

export default CallRafikiModal;
