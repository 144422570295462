import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalID } from "../constants";
import { canRetryEntry, getRoomCode, getSelf, isDenied, isSidebarOpen } from "../redux/selectors";
import { FormButton, Strapline, Title } from "../components/StyledComponents";
import Modal from "./Modal";
import { retryEntry } from "../redux/actions";

const DeniedModal: React.FC = () => {
  const dispatch = useDispatch();
  const minimise = !useSelector(isSidebarOpen);
  const denied = useSelector(isDenied);
  const canRetry = useSelector(canRetryEntry);
  const roomCode = useSelector(getRoomCode);
  const self = useSelector(getSelf);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (denied) {
      setTimeout(() => {
        setDisabled(false);
      }, 5000);
    } else {
      setDisabled(true);
    }
  }, [denied]);

  return denied ? (
    <Modal minimise={minimise} modalID={ModalID.DeniedModal}>
      <Title>Denied</Title>
      <Strapline>You were denied entry into the room.</Strapline>
      {canRetry && (
        <FormButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            dispatch(retryEntry(roomCode, self));
          }}
        >
          Try Again
        </FormButton>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default DeniedModal;
